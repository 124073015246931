import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';
import { JobOrderApi } from 'shared/sdk';
import { CommonService } from 'shared/services/common.service';

@Component({
  selector: 'app-job-order-lookup',
  templateUrl: './job-order-lookup.component.html',
  styleUrls: ['./job-order-lookup.component.css']
})
export class JobOrderLookupComponent implements OnInit {
  @Input() fromPage = '';
  @Input() inCloneFeature = false;
  @Input() setGlobalFilterValue = true;
  @Input() boxedLayout = false;
  @Input() set resetData(isClear) {
    if (isClear) {
      this.jobOrderSelectedList = [];
      this.jobOrderOptionList = [];
    }
  }
  @Input() placeholder = '';
  @Input() isdisabled;

  @Input()
  set isMultipleSelected(e) {
    this._multipleSelect = e;
    this.maxSelectedItems = e ? 20 : 1;
  }

  @Input() set setValueInEditMode(sfdcId) {
    if(this.inCloneFeature){
      this.jobOrderId = sfdcId; // no need to show that job order because we have to clone application in different JO
    }else{
      if(sfdcId) {
        let filter = {
          where: {sfdcId: sfdcId},
          fields: ['sfdcId', 'Name', 'Job_Number__c']
        }
        if(this.fromPage === 'ai-candidate'){
          filter['fields'] = ['sfdcId', 'Name', 'Job_Number__c', 'Date_Posted__c', 'Account__c', 'Project_Name__c',
          'Hiring_Manager__c']
          filter['include'] = [{
            relation: 'account',
            scope: {
              fields: ['sfdcId', 'Name']
            },
          },
          {
            relation: 'jobOrderProject',
            scope: {
              fields: ['sfdcId', 'Name']
            }
          },
          {
            relation: 'hiringManager',
            scope: {
              fields: ['sfdcId', 'Name', 'FirstName', 'LastName'],
            }
          }]
        }
       
        this._jobOrderApi.find(filter).subscribe(res => {
          this.prepareData(res);
          this.jobOrderSelectedList = sfdcId;
        });
      }else{
        this.jobOrderSelectedList = [];
        this.jobOrderOptionList = [];
      }
    }
    
}
  @Input() set partnerAccountID(e){
    if(e && e.length){
      this.partnerJobOrder = e;
      this.showMyJobOrder();
    }else{
      this.partnerJobOrder = [];
      this.myJobOrderList = false;
      this.jobOrderSelectedList = [];
      this.jobOrderOptionList = [];
    }
  }

  @Output() getJobOrderObj: EventEmitter<any> = new EventEmitter;
  public searchInput = new EventEmitter<string>();
  selectedJobOrder: any;
  whereObj = {};
  _multipleSelect = true;
  maxSelectedItems = 1;

  jobOrderOptionList = [];
  jobOrderSelectedList = [];
  isRmc = false;
  jobOrderId = '';
  @Input() set myJobOrder(e){
    if(e){
      this.myJobOrderList = true;
      this.showMyJobOrder();
    }else{
      this.myJobOrderList = false;
      this.jobOrderSelectedList = [];
      this.jobOrderOptionList = [];
    }
  };
  myJobOrderList = false;
  partnerJobOrder = [];
  /**
   * @constructor
   * Represents a TaskLookupComponent.
   */
  constructor(
    private _jobOrderApi: JobOrderApi,
    private router : Router,
    private _commonService: CommonService
  ) {
  }

  ngOnInit() {
    this.placeholder = (this.fromPage === 'ai-candidate') ? 'Search Job Order' : this.placeholder;
    if(this.router.url.indexOf('/app/marketplace-recruitment-jobs') !== -1){
      this.isRmc = true;
    }
    if (this.setGlobalFilterValue) {
      const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
      if (preselected && preselected['jobOrders'] && preselected['jobOrders'].length) {
        this.jobOrderSelectedList = preselected['jobOrders'];
        this.onJobOrderChange();
        const whereObj = this.whereObj;
        whereObj['sfdcId'] = { inq: this.jobOrderSelectedList };
        const filterObj = {
          where: whereObj,
          fields: ['id', 'sfdcId', 'Name', 'Job_Number__c'],
          order: 'createdAt desc',
          limit: 200
        }
        this._jobOrderApi.find(filterObj).subscribe(data => {
          if (data && data.length) {
            data.forEach((item: any) => {
              item.label = item.Job_Number__c ? item.Job_Number__c + ' - ' : '';
              item.label += item.Name;
            })
            this.jobOrderOptionList = data;
          } else {
            this.jobOrderOptionList = [];
          }
        }, error => {
          this.jobOrderOptionList = [];
          console.log(error);
        })
      }

      this.getJobOrderObj.emit(this.jobOrderSelectedList);
      
      if (this.selectedJobOrder) {
        this.jobOrderSelectedList = this.selectedJobOrder && this.selectedJobOrder.sfdcId;
        this.formatSelectedJobOrders();
      }

    }   
    
    this.getJobOrderList();
    // if (preselected && preselected['milestones'] && preselected['milestones'].length) {
    //   this.whereObj['PgMO_Milestones__c'] = { inq: preselected['milestones'] };
    // }
    // if (preselected && preselected['projects'] && preselected['projects'].length) {
    //   this.whereObj['PgMO_Projects__c'] = { inq: preselected['projects'] };
    // }
    // this.getJobOrders(this.whereObj);    
  }

  getJobOrderList() {
    this.searchInput
      .pipe(
        filter(search => search && search.trim().length > 2),
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(search =>
          this.getJobOrders(search)
        )
      ).subscribe(
        data => {
          this.prepareData(data);
        },
        err => {
          console.log(err);
          this.jobOrderOptionList = [];
        }
      );
  }


  getJobOrders(search) {
    let wherecond = (search) ? { Job_Number__c: { ilike: '%' + search.trim() + '%', options: 'i' } } : {};
    if(this.isRmc){
      wherecond['and'] = [{
        'or': [{ 'Employment_Type__c': 'EOR' }, { 'Employment_Type__c': 'Vendor' }]
      }];
      //,{ 'Employment_Type__c': '1099' }, { 'Employment_Type__c': 'W2' }  
      wherecond['Post_Job__c'] = true;
      wherecond['and'] = [{
        'or': [{ 'Passive_Job__c': false }, { 'Passive_Job__c': null }]
      }];
    }
    if (this.inCloneFeature) {
      wherecond['sfdcId'] = {neq:this.jobOrderId} // no need to show the current job order
      wherecond['Status__c'] = 'Open';
    }
    if(this.fromPage === 'ai-candidate'){
      delete wherecond['Job_Number__c'];
      if(this.myJobOrderList){
        const appData = JSON.parse(localStorage.getItem('appData'));
        const loggedInUser = appData.user && appData.user.sfdcId ? appData.user.sfdcId : '';
        wherecond['or'] = [
                { Primary_Recruiter__c: loggedInUser }, 
                { Secondary_Recruiter__c: loggedInUser }, 
                { Hiring_Manager__c: loggedInUser }
        ];
      }else if(this.partnerJobOrder && this.partnerJobOrder.length){
        wherecond['Account__c'] = {inq:this.partnerJobOrder};
      }
      wherecond['and'] = [{
        'or': [{'Name': { ilike: '%' + search.trim() + '%', options: 'i' } }, 
          { 'Job_Number__c': { ilike: '%' + search.trim() + '%', options: 'i' } }]
      }];
      wherecond['Status__c'] = 'Open';
    }
    let filterObj = {
      where: wherecond,
      fields: ['id', 'sfdcId', 'Name', 'Job_Number__c'],
      order: 'createdAt desc',
      limit: 200
    }
    
    if(this.fromPage === 'ai-candidate'){
      filterObj['fields'] =['id', 'sfdcId', 'Name', 'Job_Number__c', 'Date_Posted__c', 'Account__c', 'Project_Name__c',
      'Hiring_Manager__c']
      filterObj['include'] = [{
        relation: 'account',
        scope: {
          fields: ['sfdcId', 'Name']
        },
      },
      {
        relation: 'jobOrderProject',
        scope: {
          fields: ['sfdcId', 'Name']
        }
      },
      {
        relation: 'hiringManager',
        scope: {
          fields: ['sfdcId', 'Name', 'FirstName', 'LastName'],
        }
      }]
    }
    return this._jobOrderApi.find(filterObj);
  }

  formatSelectedJobOrders() {
    this.selectedJobOrder.label = this.selectedJobOrder.Job_Number__c ? this.selectedJobOrder.Job_Number__c + ' - ' : '';
    this.selectedJobOrder.label += this.selectedJobOrder.Name;
    this.jobOrderOptionList = [];
    this.jobOrderOptionList.push(this.selectedJobOrder);
    this.getJobOrderObj.emit(this.jobOrderSelectedList);
  }

  onJobOrderChange() {
    this.getJobOrderObj.emit(this.jobOrderSelectedList);
  }

  clearJobOrder() {
    this.jobOrderSelectedList = [];
    this.getJobOrderObj.emit([]);
  }
  showMyJobOrder(){
    this.getJobOrders('').subscribe(data=>{
      this.prepareData(data);
    })
  } 

  prepareData(data){
    if (data && data.length) {
      data.forEach((item: any) => {
        item.label = item.Job_Number__c ? item.Job_Number__c + ' - ' : '';
        item.label += item.Name;
        if(this.fromPage === 'ai-candidate'){
          //program
          if(item.jobOrderProject && item.jobOrderProject.Name){
            item.label += ' - '+item.jobOrderProject.Name;
          }
          //partner
          if(item.account && item.account.Name){
            item.label += ' - '+item.account.Name;
          }
          //hiring
          if(item.hiringManager && item.hiringManager.Name){
            item.label += ' - '+item.hiringManager.Name;
          }
          //date posted
          if(item.Date_Posted__c){
            item.label += (item.Date_Posted__c
              && ' ('+ this._commonService.dateFormate(item.Date_Posted__c, '', 'MM/DD/YYYY') +')');
          }
        }
      })
      this.jobOrderOptionList = data;

    } else {
      this.jobOrderOptionList = [];
      this.jobOrderSelectedList = [];
    }
  }
}