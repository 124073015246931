import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { ProductApi } from 'shared/sdk';

@Component({
  selector: 'app-product-lookup',
  templateUrl: './product-lookup.component.html',
  styleUrls: ['./product-lookup.component.css']
})
export class ProductLookupComponent implements OnInit {

  public searchInput = new EventEmitter<string>();

  @Input() set resetData(isClear) {
    if(isClear) {
      this.selectedProduct = null;
    }
  }
  @Input() set preSelectedProduct(val) {
    if(val && !this.isMultiSelect) {
      this.selectedProduct = val;
      this.getSelectedProductList();
    }
  }
  @Input() boxedLayout = false;
  @Input() isMultiSelect = false;
  @Input() placeholder = 'Product Name';
  @Output() getProductObj: EventEmitter<any> = new EventEmitter();
  productList: any;  
  itemsPerBatch = 50;
  notFoundText: string;
  orderBy = 'id DESC';
  whereFilter: any = {};
  selectedProduct = null;

  constructor(private _productApi: ProductApi) { }

  ngOnInit() {
    this.getProductList();
    this.getSelectedProductList();
  }

  getSelectedProductList() {
    if (this.selectedProduct) {
      this.loadProductList().subscribe(
        x => {
          this.productList = x;
          this.notFoundText = (this.productList.length === 0) ? 'No product found.' : '';
        },
        err => {
          this.productList = [];
        }
      )
    }
  }
  getProductList() {
    this.searchInput
      .pipe(
        filter(search => search && search.length > 3),
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(search => this.loadProductList(search))
      ).subscribe(
        x => {
          this.productList = x;
          this.notFoundText = (this.productList.length === 0) ? 'No product found.' : '';
        },
        err => {
          console.log(err);
          this.productList = [];
        }

      );
  }

  loadProductList(search?) {
    if (search) {
      const searchPattern = { ilike: '%' + search.trim() + '%', options: 'i' };
      this.whereFilter = { Name: searchPattern };
    }
    if(!search && this.selectedProduct) {
      this.whereFilter['sfdcId'] = this.selectedProduct;
    }

    const reqObj = {
      'fields': ['Name', 'sfdcId'],
      'where': this.whereFilter,
      limit: this.itemsPerBatch,
      order: this.orderBy
    };
    return this._productApi.find(reqObj);
  }

  clearProduct() {
    this.selectedProduct = null;
    this.notFoundText = 'Type to search purchase order.';
    this.getProductObj.emit(null);
  }

  onProductChange() {
    this.getProductObj.emit(this.selectedProduct);
  }
}
