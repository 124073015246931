import { Project2Api } from './../../../sdk/services/custom/Project2';
import { PreloaderService } from './../../../services/preloader.service';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, filter, tap, catchError } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DeliveryManagerService } from 'shared/views/pms/delivery-manager/delivery-manager.service';

@Component({
  selector: 'app-project2-lookup',
  templateUrl: './project2-lookup.component.html',
  styleUrls: ['./project2-lookup.component.css']
})
export class Project2LookupComponent implements OnInit {
  @Input() boxedLayout;
  @Input() selectedDefaultProject;
  @Input() isProjectByUser = false;
  @Input() from;
  @Input()
  set resetData(isClear) {
    this.clearFromParent();
    this.projects = [];
  }
  @Input()
  set source(e) {
    this._source = e;
  }

  @Input()
  set isMultipleSelected(e) {
    this._multipleSelect = e;
    this.maxSelectedItems = e ? 20 : 1;
  }
  @Output() selectedProject: EventEmitter<any> = new EventEmitter;
  @Output() selectedInitialProject: EventEmitter<any> = new EventEmitter;

  _source = '';
  whereObj: any = {};
  _multipleSelect = true;
  projects: any = [];
  selectedValue: any;
  maxSelectedItems = 20;
  noPgmoProject = 'No Project Available';
  isLoading = false;
  projectsByLoggedInUser: Array<string>;

  @Input()
  set filterProjectObj(params) {
    
    this.projects = [];
    this.clearFromParent();
    // IF ALL THE PARENT FILTERS ARE REMOVED THE WHEREOBJ SHOULD BE EMPTY
    //this.whereObj = {}
    if (params && Object.keys(params).length) {
      // if (params['Project_Type__c'] && params['Project_Type__c'].length
      //   && params['Project_Type__c'].filter(Boolean).length
      //   && params['Project_Type__c'][0] !== 'RecruitmentManager') {
      //   this.whereObj['Project_Type__c'] = { inq: params['Project_Type__c'] };
      // }

      if (params['Project_Type__c']) {
        if (params['Project_Type__c'].length) {
          this.whereObj['Project_Type__c'] = { inq: params['Project_Type__c'] };
        } else {
          if (this.whereObj['Project_Type__c']) {
            delete this.whereObj['Project_Type__c'];
          }
        }
      }
      console.log("select", params['select_helpdesk'])
      if ('select_helpdesk' in params) {
        this.whereObj['Project_Type__c'] = { inq: ['HelpDesk'] };
      }

      if (params['Account__c']) {
        if (params['Account__c'].length) {
          this.whereObj['Account__c'] = { inq: params['Account__c'] };
        } else {
          if (this.whereObj['Account__c']) {
            delete this.whereObj['Account__c'];
          }
        }
      }
      // if (params['Project_Type__c'][0] === 'RecruitmentManager') {
      //   this.whereObj['RecordTypeId'] = '0121a000000F1IEAA0';
      //   this.whereObj['Sub_Catergory__c'] = 'Recruitment';
      //   this.whereObj['Category__c'] = 'Others';
      // }
    }
    if (Object.keys(this.whereObj).length) {
      // this.getProjects(this.whereObj)
      this.projects = [];
      this.selectedValue = '';
    }
  }
  @Input() set selectedConversatonProj(e) {
    if (e && e.length) {
      this.selectedTaskProject = e;
      this.selectedValue = e;
      this.setSelectedProjects(this.selectedTaskProject);
    } else {
      this.selectedTaskProject = '';
    }
  }

  selectedTaskProject: any

  public searchInput = new EventEmitter<string>();

  constructor(
    private _preloaderService: PreloaderService,
    private _deliveryManagerService: DeliveryManagerService,
    private _project2Api: Project2Api
  ) {
    // TO TRIGGER THE PASTE OF THE TEXT TO THE SEARCHINPUT VARIABLE
    // this.searchInput.subscribe((newTerm) => {
    //   this.getProjects();
    // });
  }

  ngOnInit() {
    const preSelected = localStorage.getItem('filterObj');
    if (this.from && this.from === 'analyticsDashboard') {
      if (this.selectedDefaultProject && this.selectedDefaultProject.length) {
        this.selectedValue = this.selectedDefaultProject;
        this.setSelectedProjects(this.selectedValue);
      } else {
        this.selectedValue = [];
      }
    } else if (preSelected && JSON.parse(preSelected) &&
      JSON.parse(preSelected)['projects'] && JSON.parse(preSelected)['projects'].length) {
      this.selectedValue = JSON.parse(preSelected)['projects'];
      this.setSelectedProjects(this.selectedValue);
      // this.onChange();
    }
    if (this.selectedTaskProject) {
      this.selectedValue = this.selectedTaskProject && this.selectedTaskProject.sfdcId;
      this.selectedInitialProject.emit(this.selectedValue);
    }
    if (this.isProjectByUser) {
      this._deliveryManagerService.getCurrentUserGroupIds({ project: [] }, JSON.parse(preSelected)['projectTypes'][0], this.isProjectByUser).then((projs: Array<string>) => {
        this.whereObj['sfdcId'] = { inq: projs };
      });
    }
    this.getProjects();
    this.onChange();
  }

  setSelectedProjects(selectedProjIds: Array<string>) {
    if (selectedProjIds && selectedProjIds.length) {
      this.isLoading = true;
      this.getTaskList({ where: { sfdcId: { inq: Array.isArray(selectedProjIds) ? selectedProjIds : [selectedProjIds]} } }).subscribe(
        data => {
          this.isLoading = false;
          this.projects = this.modifyData(data);
        },
        err => {
          this.isLoading = false;
          console.error(err);
          this.projects = [];
        }
      );
    }
  }

  onChange() {
    this.selectedProject.emit(this.selectedValue);
  }

  onClear() {
    this.selectedValue = null;
    this.selectedProject.emit([]);
  }

  clearFromParent() {
    this.selectedValue = [];
  }

  getProjects() {
    this.searchInput
      .pipe(
        filter(search => search && search.trim().length > 2),
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(search => this.getProjectsByName(search))
      ).subscribe(
        data => {
          this.isLoading = false;
          if (data && data) {
            const results = this.modifyData(data);
            this.projects = results;
          } else {
            this.projects = [];
            this.noPgmoProject = 'No Record Found'
          }
          // this._preloaderService.hidePreloader();
        },
        err => {
          this.isLoading = false;
          // this._preloaderService.hidePreloader();
        }

      );
  }

  // Method to set query to get project list
  getProjectsByName(search) {
    this.isLoading = true;
    // this._preloaderService.showPreloader();
    var newWhereObj = JSON.parse(JSON.stringify(this.whereObj));;
    if (newWhereObj['Project_Type__c'] && newWhereObj['Project_Type__c'].inq && newWhereObj['Project_Type__c'].inq.length && newWhereObj['Project_Type__c'].inq.includes('RecruitmentManager')) {
      let projects = JSON.parse(JSON.stringify(this.whereObj['Project_Type__c'].inq));
      let index = projects.indexOf('RecruitmentManager');
      projects.splice(index, 1);
      delete newWhereObj['Project_Type__c'];
      newWhereObj['or'] = [
        { 
          Project_Type__c: { inq: projects }
        },
        {
          and: [
            { 
              RecordTypeId: '0121a000000F1IEAA0' 
            },
            { 
              Sub_Catergory__c: 'Recruitment' 
            },
            { 
              Category__c: 'Others' 
            }
          ]
        }
      ];
    }
    let whereObj = {};
    if (Object.keys(newWhereObj).length) {
      whereObj = {
        and: [
          newWhereObj,
          {
            or: [
              { ProjectName__c: { ilike: '%' + search.trim() + '%', options: 'i' } },
              { Project_No__c: { ilike: '%' + search.trim() + '%', options: 'i' } },
            ]
          }
        ]
      };
    } else {
      whereObj = {
        and: [
          {
            or: [
              { ProjectName__c: { ilike: '%' + search.trim() + '%', options: 'i' } },
              { Project_No__c: { ilike: '%' + search.trim() + '%', options: 'i' } },
            ]
          }
        ]
      };
    }
    return this.getTaskList({ where: whereObj });
  }

  getTaskList(data: {
    where: Object,
    fields?: Array<string>,
    orderBy?: string
  }) {
    return this._project2Api.find({
      where: data.where,
      fields: data.fields ? data.fields : ['Name', 'sfdcId', 'Sub_Catergory__c', 'ProjectName__c', 'Project_Type__c', 'Project_No__c'],
      order: data.orderBy ? data.orderBy : 'Name',
    });
  }

  modifyData(data) {
    if (data.length) {
      data.forEach(item => {
        item['sfdcId'] = (item.sfdcId) ? item.sfdcId : '';
        // item['Name'] = (item.Project_No__c)
        //   ? item.Project_No__c + ' - ' + item.ProjectName__c
        //   : ((item.Name) ? item.Name + ' - ' + item.ProjectName__c : '');
        item['Name'] = (item.Project_No__c)
          ? item.ProjectName__c + ' - ' + item.Project_No__c
          : ((item.Name) ? item.ProjectName__c + ' - ' + item.Name : '');
      });
      return data;
    } else {
      return [];
    }
  }
}


