import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { filter, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PurchaseOrderApi } from 'shared/sdk';

@Component({
  selector: 'app-purchase-order-lookup',
  templateUrl: './purchase-order-lookup.component.html',
  styleUrls: ['./purchase-order-lookup.component.css']
})
export class PurchaseOrderLookupComponent implements OnInit {

  public searchInput = new EventEmitter<string>();

  @Input() set resetData(isClear) {
    if(isClear) {
      this.selectedPurchaseOrder = [];
    }
  }
  @Input() isMultiSelect = true;
  @Input() placeholder = 'Purchase Order Number';
  @Output() getpurchaseOrderObj: EventEmitter<any> = new EventEmitter();
  purchaseOrderList: any;  
  itemsPerBatch = 50;
  notFoundText: string;
  orderBy = 'id DESC';
  selectedPurchaseOrder: any[];
  whereFilter: any;

  constructor(private _purchaseOrderApi: PurchaseOrderApi) { }

  ngOnInit() {
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.selectedPurchaseOrder = (preselected && preselected['purchaseOrder']) ?
      preselected['purchaseOrder'] : [];

    if (this.selectedPurchaseOrder && this.selectedPurchaseOrder.length) {
      this.whereFilter = { Work_Order_num__c: { inq: this.selectedPurchaseOrder } };
      this.loadPurchaseOrderNumber().subscribe(
        x => {
          this.purchaseOrderList = x;
          this.notFoundText = (this.purchaseOrderList.length === 0) ? 'No purchase order found.' : '';
        },
        err => {
          this.purchaseOrderList = [];
        }
      )
    }
    this.getpurchaseOrderNumber();
  }

  getpurchaseOrderNumber() {
    this.searchInput
      .pipe(
        filter(search => search && search.length > 5),
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(search => this.loadPurchaseOrderNumber(search))
      ).subscribe(
        x => {
          this.purchaseOrderList = x;
          this.notFoundText = (this.purchaseOrderList.length === 0) ? 'No purchase order found.' : '';
        },
        err => {
          console.log(err);
          this.purchaseOrderList = [];
        }

      );
  }

  loadPurchaseOrderNumber(search?) {
    if (search) {
      const searchPattern = { ilike: '%' + search.trim() + '%', options: 'i' };
      this.whereFilter = { ...this.whereFilter, Work_Order_num__c: searchPattern };
    }

    const reqObj = {
      'fields': ['Work_Order_num__c'],
      'where': this.whereFilter,
      limit: this.itemsPerBatch,
      order: this.orderBy
    };
    return this._purchaseOrderApi.find(reqObj);
  }

  clearPurchaseOrder() {
    this.selectedPurchaseOrder = [];
    this.notFoundText = 'Type to search purchase order.';
    this.getpurchaseOrderObj.emit([]);
  }

  onPurchaseOrderChange() {
    this.getpurchaseOrderObj.emit(this.selectedPurchaseOrder);
  }
}
