import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import * as io from 'socket.io-client';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable()
export class NotificationSocketService {
    socket: any;
    socketUrl = '';
    isStarted: boolean = false;
    private nextNotification = new Subject<any>();

    constructor() {
    }

    initializeSocket() {
        if (!this.isStarted) {
            this.isStarted = true;
          //  this.connectSocketMessage();
        } else {
            console.log('Notification Socket connected already....');
        }
    }

    connectSocketMessage() {
        const token = localStorage.getItem('accessToken');
        const appData = JSON.parse(localStorage.getItem('appData'));
        const sfdcId = appData.user.sfdcId;
        const url = environment.rtUrl + '/default';
        const socket = io(url, {
            transports: ['websocket'], upgrade: false
        });
        socket.on('connect', () => {
            socket.emit('authenticate', {
                token: token
            }).on('authenticated', function () {
                this.socket = socket; 
                console.log('notification socket-authenticated!!', socket.connected);
            }).on('unauthorized', function (msg) {
                console.log("notification socket-unauthorized: " + JSON.stringify(msg.data));
                throw new Error(msg.data.type);
            }).on(sfdcId, data => {
                this.nextNotification.next(data);
            });
        }).on('disconnect', reason => {
            this.isStarted = false;
            if (reason === 'io server disconnect') {
                console.log(reason);
                socket.connect();
            }
        });
    }
    
    getMessage(): Observable<any> {
        return this.nextNotification.asObservable();
    }
}
