import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as StaticData from 'shared/models/static-list-data.service';
import { TimeChangeRequestApi } from 'shared/sdk';
import { debounceTime, distinctUntilChanged, switchMap, filter, tap, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-time-change-request-lookup',
  templateUrl: './time-change-request-lookup.component.html',
  styleUrls: ['./time-change-request-lookup.component.css']
})
export class TimeChangeRequestLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    // this.jobIds = [];
    this.timeChangeRequest = [];
  }
  @Input() isMultiSelect = true;
  @Input() maxSelect = 20;
  @Input() placeholder;
  @Input() selectbindValue: string = 'Name';
  @Input() selectLabel: string = 'Name';
  @Input() from: string = 'Name';
  @Input() set selectJob(job) {
    if (job) {
      this.dataList = [];
      this.dataList.push(job);
      this.timeChangeRequest = job.Name;
    }
  }
  currentUser: any;
  @Output() setTimeChangeRequestLookup: EventEmitter<any> = new EventEmitter();
  dataList: any;
  public searchInput = new EventEmitter<string>();
  itemsPerBatch = 20;
  notFoundText: string;
  orderBy = 'id DESC';
  timeChangeRequest: any;
  whereFilter: any;

  constructor(private _timeChangeRequestApi: TimeChangeRequestApi) { }

  ngOnInit() {
    // to show statge status

    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.timeChangeRequest = (preselected && preselected['Tcr']) ?
      preselected['Tcr'] : [];
    const appData = JSON.parse(localStorage.getItem('appData'));
    if (this.timeChangeRequest && this.timeChangeRequest.length) {
      this.whereFilter = { ...this.whereFilter, Name: { inq: this.timeChangeRequest } };
      this.loadJobIds().subscribe(
        x => {
          this.dataList = x;
          this.notFoundText = (this.dataList.length === 0) ? 'No time change request found.' : '';
        },
        err => {
          console.log(err);
          this.dataList = [];
        }
      );
    }
    this.getTcr();
  }

  getTcr() {
    this.searchInput
      .pipe(
        filter(search => search && search.length > 2),
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(search => this.loadJobIds(search))
      ).subscribe(
        x => {
          this.dataList = x;
          this.notFoundText = (this.dataList.length === 0) ? 'No time change request found.' : '';
        },
        err => {
          console.log(err);
          this.dataList = [];
        }

      );
  }

  loadJobIds(search?) {
    if (search) {
      const searchPattern = { ilike: '%' + search.trim() + '%', options: 'i' };
      this.whereFilter = { ...this.whereFilter, Name: searchPattern };
    }
    const timeChangeReq = {
      where: this.whereFilter,
      fields: ['sfdcId', 'id', 'Name'],
      order: this.orderBy,
      limit: this.itemsPerBatch,
    };
    return this._timeChangeRequestApi.find(timeChangeReq);
  }

  clearTcr() {
    this.timeChangeRequest = [];
    this.notFoundText = 'Type to search job';
    this.setTimeChangeRequestLookup.emit([]);
  }

  onTcrChange() {
    this.setTimeChangeRequestLookup.emit(this.timeChangeRequest);
  }

}
