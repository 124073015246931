import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WorkerApi } from 'shared/sdk';
import { debounceTime, switchMap, filter } from 'rxjs/operators';

@Component({
  selector: 'app-worker-email-lookup',
  templateUrl: './worker-email-lookup.component.html',
  styleUrls: ['./worker-email-lookup.component.scss']
})
export class WorkerEmailLookupComponent implements OnInit {
  @Input() boxedLayout = false;
  @Input() set resetData(isClear) {
    if (isClear) {
      this.workerEmailSelected = [];
    }
  }
  workerEmailhead: EventEmitter<any> = new EventEmitter();
  noWorkerEmail: string;
  workerEmailOptionList: any[] = [];
  workerEmailSelected = [];
  @Input() placeholder = 'Worker Email';
  @Input() isdisabled = false;
  @Input() isMultipleSelected = true;
  @Input() bindValue = 'Email__c';
  @Input() bindLabel = 'Email__c';

  @Output() getWorkerEmailObj: EventEmitter<any> = new EventEmitter;
  @Input() set preselected(e) {
    if (e) {
      this.workerEmailSelected = e;
      let reqobj = {
        fields: ['id', 'sfdcId', 'Name', 'Email__c'],
        where: { sfdcId: this.workerEmailSelected }
      }
      this._workerApi.find(reqobj).subscribe(result => {
        if (result && result.length) {
          this.workerEmailOptionList = result;
          this.noWorkerEmail = (this.workerEmailOptionList.length === 0) ? 'No data found.' : '';
        }
      });
    }
  }
  @Input() inGlobalFilter = true;
  @Input() preselectedKey = 'WorkerEmail';

  

  /**
   * @constructor
   * Represents a TalentTypeLookupComponent.
   */
  constructor(
    private _workerApi: WorkerApi
  ) {
  }

  ngOnInit() {
    // this.getTalentType('');
    if(this.inGlobalFilter && (!this.workerEmailSelected || !this.workerEmailSelected.length)){
      const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
      this.workerEmailSelected = (preselected && preselected[this.preselectedKey]) ? preselected[this.preselectedKey] : [];
    }
    this.getWorkerEmail();
  }

  getWorkerEmail() {
    this.workerEmailhead
      .pipe(
        filter(search => search && search.length > 2),
        debounceTime(200),
        switchMap(search => {
          const searchPattern = { ilike: '%' + search.trim() + '%', options: 'i' };
          return this.findWorkerEmailApi(searchPattern);
        })
      ).subscribe(
        data => {
          if (data && data.length) {
            this.workerEmailOptionList = data;
          } else {
            this.workerEmailOptionList = [];
            this.noWorkerEmail = 'No Worker Email found.';
          }
        },
        err => {
          console.log('Error fetching talent types', err.message);
        }
      );
  }

  findWorkerEmailApi(searchText) {
    let whereObj = { Email__c: searchText };
    return this._workerApi.find({
      where: whereObj,
      fields: ['id', 'sfdcId', 'Name', 'Email__c']
    })
  }

  onWorkerEmailChange() {
    this.getWorkerEmailObj.emit(this.workerEmailSelected);
  }

  clearWorkerEmail() {
    this.workerEmailSelected = [];
    this.getWorkerEmailObj.emit([]);
  }

}
