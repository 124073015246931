import { Users } from './../../../sdk/models/Users';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';
import { UsersApi } from 'shared/sdk';
import { CommonService } from 'shared/services/common.service';
@Component({
  selector: 'app-member-lookup',
  templateUrl: './member-lookup.component.html',
  styleUrls: ['./member-lookup.component.css']
})
export class MemberLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    this.membersList = [];
    this._memberSelected = [];
  }
  @Input()
  set isMultipleSelected(e) {
    this._isMultipleSelected = e;
  }
  @Output() getMemberObj: EventEmitter<any> = new EventEmitter;
  noMemberFound = 'No Member found.';
  usersTypeahead = new EventEmitter<string>();
  membersList = [];
  _isMultipleSelected = true;
  _memberSelected = [];
  constructor(
    private _cd: ChangeDetectorRef,
    private _userApi: UsersApi,
    private _commonService: CommonService,
  ) { }

  ngOnInit() {
    this.getMembers();
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    if (preselected && preselected['member'] && preselected['member'].length) {
      this._memberSelected = preselected['member'];
      this.getSelectedMemberData();
    }
  }

  getSelectedMemberData() {
    if (this._memberSelected && this._memberSelected.length) {
      const whereObj = {'accessType' : 'internal'};
      if (Array.isArray(this._memberSelected)) {
        whereObj['firstname'] = { inq: this._memberSelected };
      } else {
        whereObj['firstname'] = this._memberSelected;
      }
      this.getMembersList(whereObj).subscribe(
        (x: Array<Users>) => {
          this.membersList = x.map(item => item.firstname).filter((value, index, self) => self.indexOf(value) === index);
          this.noMemberFound = (this.membersList.length === 0) ? 'No Member found.' : '';
        },
        err => {
          console.error(err);
          this.membersList = [];
          this.noMemberFound = '';
        }
      );
    }
  }

  // get member list by name
  getMembers() {
    this.membersList = [];
    this.usersTypeahead
      .pipe(
        filter(term => term && term.trim().length > 2),
        distinctUntilChanged(),
        debounceTime(200),
        switchMap(term => {
          const searchPattern = { ilike: '%' + term.trim() + '%', options: 'i' };
          const whereObj = {
            firstname: searchPattern,
            accessType: 'internal'
          };
          return this.getMembersList(whereObj);
        })
      )
      .subscribe(
        (x: Array<Users>) => {
          this._cd.markForCheck();
          this.membersList = x.map(item => item.firstname).filter((value, index, self) => self.indexOf(value) === index);
          this.noMemberFound = (this.membersList.length === 0) ? 'No Member found.' : '';
        },
        err => {
          console.log(err);
          this.membersList = [];
          this.noMemberFound = '';
        }
      );
  }

  onMembersChange() {
    this.getMemberObj.emit(this._memberSelected);
  }

  clearMembers() {
    this._memberSelected = [];
    this.getMemberObj.emit([]);
  }

  getMembersList(whereObj) {
    return this._userApi.find({
      where: whereObj,
      fields: ['sfdcId', 'firstname']
    }).map(res => res)
  }
}
