import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';
import { JobOrderApi } from 'shared/sdk';

@Component({
  selector: 'app-job-order-title-lookup',
  templateUrl: './job-order-title-lookup.component.html',
  styleUrls: ['./job-order-title-lookup.component.css']
})
export class JobOrderTitleLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    if (isClear) {
      this.jobOrderSelectedList = [];
      // this.jobOrderOptionList = [];
    }
  }
  @Input() placeholder = 'Job Order';
  @Input() isdisabled;

  @Input()
  set isMultipleSelected(e) {
    this._multipleSelect = e;
    this.maxSelectedItems = e ? 20 : 1;
  }

  @Output() getJobOrderObj: EventEmitter<any> = new EventEmitter;
  public searchInput = new EventEmitter<string>();
  selectedJobOrder: any;
  whereObj = {};
  _multipleSelect = true;
  maxSelectedItems = 1;

  jobOrderOptionList = [];
  jobOrderSelectedList = [];

  /**
   * @constructor
   */
  constructor(
    private _jobOrderApi: JobOrderApi
  ) {
  }

  ngOnInit() {
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    if (preselected && preselected['jobTitles'] && preselected['jobTitles'].length) {
      this.jobOrderSelectedList = preselected['jobTitles'];
      this.onJobOrderChange();
      const whereObj = this.whereObj;
      whereObj['Job_Number__c'] = { inq: this.jobOrderSelectedList };
      const filterObj = {
        where: whereObj,
        fields: ['id', 'sfdcId', 'Name', 'Job_Number__c','Job_Title__c'],
        order: 'createdAt desc',
        limit: 200
      }
      this._jobOrderApi.find(filterObj).subscribe(data => {
        if (data && data.length) {
          data.forEach((item: any) => {
            item.label = item.Job_Title__c ? item.Job_Title__c + ' - ' : '';
            item.label += item.Job_Number__c;
            item.title = item.Job_Number__c;
          })
          this.jobOrderOptionList = data;
        } else {
          this.jobOrderOptionList = [];
        }
      }, error => {
        this.jobOrderOptionList = [];
        console.log(error);
      })
    }
    this.getJobOrderObj.emit(this.jobOrderSelectedList);
    this.getJobOrderList();
    if (this.selectedJobOrder) {
      this.jobOrderSelectedList = this.selectedJobOrder && this.selectedJobOrder.sfdcId;
      this.formatSelectedJobOrders();
    }
  }

  getJobOrderList() {
    this.searchInput
      .pipe(
        filter(search => search && search.trim().length > 2),
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(search =>
          this.getJobOrders(search)
        )
      ).subscribe(
        data => {
          if (data && data.length) {
            data.forEach((item: any) => {
              item.label = item.Job_Title__c ? item.Job_Title__c + ' - ' : '';
              item.label += item.Job_Number__c;
              item.title = item.Job_Number__c;
            })
            this.jobOrderOptionList = data;

          } else {
            this.jobOrderOptionList = [];
          }
        },
        err => {
          console.log(err);
          this.jobOrderOptionList = [];
        }
      );
  }


  getJobOrders(search) {
    const filterObj = {
      where: { Job_Title__c: { like: '%' + search.trim() + '%', options: 'i' } },
      fields: ['id', 'sfdcId', 'Name', 'Job_Number__c','Job_Title__c'],
      order: 'createdAt desc',
      limit: 200
    }
    return this._jobOrderApi.find(filterObj);
  }

  formatSelectedJobOrders() {
    this.selectedJobOrder.label = this.selectedJobOrder.Job_Number__c ? this.selectedJobOrder.Job_Number__c + ' - ' : '';
    this.selectedJobOrder.label += this.selectedJobOrder.Name;
    this.jobOrderOptionList = [];
    this.jobOrderOptionList.push(this.selectedJobOrder);
    this.getJobOrderObj.emit(this.jobOrderSelectedList);
  }

  onJobOrderChange() {
    this.getJobOrderObj.emit(this.jobOrderSelectedList);
  }

  clearJobOrder() {
    this.jobOrderSelectedList = [];
    this.getJobOrderObj.emit([]);
  }
}