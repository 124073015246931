import { SiteVisitReportApi } from './../../../sdk/services/custom/SiteVisitReport';
import { VendorRatingApi } from './../../../sdk/services/custom/VendorRating';
import { JobWorkerRatingApi } from './../../../sdk/services/custom/JobWorkerRating';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TimecardApi, InvoiceApi, RecordTypeApi, JobScheduleApi, JobApi } from 'shared/sdk';
import { PreloaderService } from 'shared/services/preloader.service';
import { AlertService } from 'shared/services/alert.service';
import { CommonService } from 'shared/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalFilterService } from 'shared/services/global-filter.service';
import { SharedService } from 'shared/services/pms/shared.services';
import { AppStateService } from 'shared/services/app-state.service';
import { ModalService } from 'shared/services/modal.service';
import { BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { MobileOS } from '../../../models/static-list-data.service'



@Component({
  selector: 'app-timecard-detail',
  templateUrl: './timecard-detail.component.html',
  styleUrls: ['./timecard-detail.component.css']
})
export class TimecardDetailComponent implements OnInit {

  mobileOs = [...MobileOS];
  fromcheckInOut = false;
  fromBillingManager = false;
  @Input() timecardId: any;
  @Input() timeSheetDetails: any;
  @Output() apiCallEvent: EventEmitter<any> = new EventEmitter<boolean>();
  activeTabs = ['healthSummary', 'billingHealthSummary', 'timecardInformation',
    'approvalInformation', 'taskDetailInformation', 'timeInOutInfo', 'taskInformation', 'holidayDetails',
    'timeInformation', 'OTcalculation', 'systemInformation', 'timecardHistory', 'skuChangeInfo',
    'workerRatingIcc', 'vendorRatingIcc', 'workerRatingPgmo', 'vendorRatingPgmo',
    'autoInformation', 'Invoices', 'OTUTInformation', 'Files',
    'jobSchedule', 'timesheetHoliday'];
  selectedTimecard: any;
  timeCardData: any = {};
  invoiceData = [];
  ironApprovalStatus = ['In-Progress', 'Submitted', 'Approved', 'Rejected', 'Cancelled', 'Not Submitted']
  customerApprovalStatus = ['Not Submitted', 'Submitted', 'Approved', 'Rejected'];
  actualCoverageHrs = ['9H5D', 'AFTH', 'HLDY', 'WKND'];
  actualEndTimeCoverage = ['9H5D', 'AFTH', 'HLDY', 'WKND'];
  timeFrames = ['0.00AM', '1.00AM', '2.00AM', '3.00AM', '4.00AM', '5.00AM', '6.00AM',
    '7.00AM', '7.30AM', '8.00AM', '8.30AM', '9.00AM', '9.30AM', '10.00AM', '10.30AM',
    '11.00AM', '11.30AM', '12.00PM', '12.30PM', '1.00PM', '1.30PM', '2.00PM', '2.30PM', '3.00PM',
    '3.30PM', '4.00PM', '4.30PM', '5.00PM', '5.30PM', '6.00PM', '6.30PM', '7.00PM',
    '8.00PM', '9.00PM', '10.00PM', '11.00PM'];
  timeInformationData = [
    {
      'Name': 'Monday',
      'actualHours': '',
      'scheduledHours': '',
      'scheduledInHoliday': false,
      'publicHoliday': false,
    },
    {
      'Name': 'Tuesday',
      'actualHours': '',
      'scheduledHours': '',
      'scheduledInHoliday': false,
      'publicHoliday': false,
    },
    {
      'Name': 'Wednesday',
      'actualHours': '',
      'scheduledHours': '',
      'scheduledInHoliday': false,
      'publicHoliday': false,
    },
    {
      'Name': 'Thursday',
      'actualHours': '',
      'scheduledHours': '',
      'scheduledInHoliday': false,
      'publicHoliday': false,
    },
    {
      'Name': 'Friday',
      'actualHours': '',
      'scheduledHours': '',
      'scheduledInHoliday': false,
      'publicHoliday': false,
    },
    {
      'Name': 'Saturday',
      'actualHours': '',
      'scheduledHours': '',
      'scheduledInHoliday': false,
      'publicHoliday': false,
    },
    {
      'Name': 'Sunday',
      'actualHours': '',
      'scheduledHours': '',
      'scheduledInHoliday': false,
      'publicHoliday': false,
    }
  ]
  timesheetYears = ['2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'];
  timesheetMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October',
    'November', 'December'];
  selectedCity = '';
  approvalStatus: any = '';
  selectedCustAppStatus: any = '';
  selectedactualConverage: any = '';
  selectedactualEndTime: any = '';
  timeCardStartDate: any;
  timeCardIncurredDate: any;
  vendorTimeIn: any;
  vendorTimeOut: any;
  Customer_Site_Signoff_Name__c = '';
  Vendor_Time_Card_Notes_Tasks_Performed__c = '';
  Any_Observation_or_Suggestions_Notes__c = '';
  Equipment_Make_Model_Performed_on__c = '';
  Equipment_Serial_Performed_on__c = '';
  Worker_Mobile_OS_Android_iOS__c = '';
  timeZone = '';
  timeFormat = 'L, LT z';
  timecardType: any;
  sub: any;
  sidebarMenuOpened: any;
  submittedTimecard = false;
  visibleMenuOptions = ['filter'];
  filterData: any;
  isFilterApplied = false;
  recordTypeIds: any;
  isTimeSheet: any;
  typeOfTimeLog: string;
  workerArrivalCustRqst: any;
  normalHours: any;
  AFTHhours: any;
  fieldsAndIncludeObj = {
    fields: {
      timecard: ['id', 'sfdcId', 'Name', 'Status__c', 'RecordTypeId', 'Replacement_SKU_for_PO__c', 'Replacement_SKU_for_SO__c',
        'CreatedDate', 'Timesheet_Start_Date__c', 'Incurred_Date__c', 'Customer_Approval_Status__c', 'Timesheet_End_Week__c',
        'Final_Timecard__c', 'Vendor_Time_Card_Notes_Tasks_Performed__c', 'Timecard_Calc_Base_Bk__c', 'Refresh_Flag__c',
        'Timesheet_Hours_Calc__c', 'Timesheet_Hours_Calc_Rounded__c', 'Customer_Site_Signoff_Name__c', 'Timesheet_Start_Week__c',
        'Timesheet_Hours_Calc_Base_Bk__c', 'Timecard_Calc_Base_Final__c', 'Timesheet_Hours_Calc_Base__c',
        'Total_Overtime_Hours_Holiday_Weekend__c', 'Timesheet_Week_Number_ISO_Auto__c', 'Any_Observation_or_Suggestions_Notes__c',
        'Vendor_Time_Card_Time_in_Actual__c', 'Actual_Coverage_Hour__c', 'Actual_End_Time_Coverage__c',
        'Vendor_Time_Card_Time_Out_Actual__c', 'Vendor__c', 'Worker__c', 'Service_Dispatch__c', 'PPE_Hours_on_Dispatch__c'
        , 'Timecard_OT_STD__c', 'Timecard_Normal_Hours__c', 'Timecard_OT_AFTH__c', 'Is_Holiday__c', 'Timecard_OT_Weekend__c',
        'Timecard_OT_Holiday__c', 'Monday_Hours__c', 'Tuesday_Hours_Actual__c', 'Wednesday_Hours_Actual__c',
        'Thursday_Hours_Actual__c', 'Friday_Hours_Actual__c', 'Saturday_Hours_Actual__c', 'Sunday_Hours_Actual__c',
        'Monday_Hours_Job_Schedule__c', 'Tuesday_Hours_Job_Schedule__c', 'Wednesday_Hours_Job_Schedule__c',
        'Thursday_Hours_Job_Schedule__c', 'Friday_Hours_Job_Schedule__c', 'Saturday_Hours_Job_Schedule__c',
        'Sunday_Hours_Job_Schedule__c', 'Is_Monday_Holiday_in_Jobsite__c',
        'Is_Tuesday_Holiday_in_Jobsite__c', 'Is_Wednesday_Holiday_in_Jobsite__c', 'Is_Thursday_Holiday_in_Jobsite__c',
        'Is_Friday_Holiday_in_Jobsite__c', 'Is_Saturday_Holiday_in_Jobsite__c', 'Is_Sunday_Holiday_in_Jobsite__c',
        'Is_Monday_Public_Holiday__c', 'Is_Tuesday_Public_Holiday__c', 'Is_Wednesday_Public_Holiday__c', 'Is_Thursday_Public_Holiday__c',
        'Is_Friday_Public_Holiday__c', 'Is_Saturday_Public_Holiday__c', 'Is_Sunday_Public_Holiday__c', 'Total_Timesheet_Hours__c',
        'Business_Start_Time_Formula__c', 'Absence_Hours__c', 'Total_Paid_Holiday_Hours__c', 'Need_SKU_Change_on_SO_PO__c', 'Purchase_Order_Ref__c',
        'Equipment_Serial_Performed_on__c', 'Equipment_Make_Model_Performed_on__c', 'Worker_Mobile_OS_Android_iOS__c'],
      timesheet: ['id', 'sfdcId', 'Name', 'Timesheet_Start_Date__c', 'RecordTypeId', 'Service_Dispatch__c', 'Timesheet_Year__c',
        'Timesheet_Month__c', 'Customer_Site_Signoff_Name__c', 'Vendor__c', 'Worker__c', 'Any_Observation_or_Suggestions_Notes__c',
        'Timesheet_Week_Number_ISO_Auto__c', 'Timesheet_Start_Week__c', 'Timesheet_End_Week__c', 'Final_Timecard__c',
        'Status__c', 'Customer_Approval_Status__c', 'Refresh_Flag__c', 'CreatedDate', 'createdAt', 'updatedAt', 'Monday_Hours__c', 'Tuesday_Hours_Actual__c',
        'Wednesday_Hours_Actual__c', 'Thursday_Hours_Actual__c', 'Friday_Hours_Actual__c', 'Saturday_Hours_Actual__c',
        'Sunday_Hours_Actual__c', 'Monday_Hours_Job_Schedule__c', 'Tuesday_Hours_Job_Schedule__c', 'Vendor_Daily_Hours__c',
        'Wednesday_Hours_Job_Schedule__c', 'Thursday_Hours_Job_Schedule__c', 'Friday_Hours_Job_Schedule__c',
        'Saturday_Hours_Job_Schedule__c', 'Sunday_Hours_Job_Schedule__c', 'Total_Timesheet_Hours__c', 'Overtime__c',
        'Total_Worked_Hours_Without_Offset__c', 'Total_Overtime_Hours_Standard_Weekday__c', 'Total_Planned_Hours_Job_Schedule__c',
        'Monday_Task_Performed__c', 'Tuesday_Tasks_Performed__c', 'Wednesday_Tasks_Performed__c', 'Thursday_Tasks_Performed__c',
        'Friday_Tasks_Performed__c', 'Saturday_Tasks_Performed__c', 'Sunday_Tasks_Performed__c', 'Is_Monday_Holiday_in_Jobsite__c',
        'Is_Tuesday_Holiday_in_Jobsite__c', 'Is_Wednesday_Holiday_in_Jobsite__c', 'Is_Thursday_Holiday_in_Jobsite__c',
        'Is_Friday_Holiday_in_Jobsite__c', 'Is_Saturday_Holiday_in_Jobsite__c', 'Is_Sunday_Holiday_in_Jobsite__c',
        'Is_Monday_Public_Holiday__c', 'Is_Tuesday_Public_Holiday__c', 'Is_Wednesday_Public_Holiday__c', 'Is_Thursday_Public_Holiday__c',
        'Is_Friday_Public_Holiday__c', 'Is_Saturday_Public_Holiday__c', 'Is_Sunday_Public_Holiday__c', 'Business_Start_Time__c',
        'Total_Overtime_Hours_Holiday_Weekday__c', 'Total_Short_Worked_Hours__c', 'Total_Worked_Hours__c', 'Business_Start_Time_Formula__c',
        'Absence_Hours__c', 'Total_Paid_Holiday_Hours__c', 'Worker_on_Job__c'
      ]
    },
    include: {
      timecard: [
        {
          relation: 'vendor',
          scope: {
            fields: ['id', 'sfdcId', 'Name']
          }
        },
        {
          relation: 'job',
          scope: {
            fields: ['id', 'sfdcId', 'Name', 'Jobsite__c', 'submittedTimecard', 'Worker_Arrival_DateTime_Cust_Requested__c',
            'Assignment_ID__c' , 'Publish_to_Workmarket__c','Submiited_to_WM__c'],
            include: [
              {
                relation: 'jobsite',
                scope: {
                  fields: ['id', 'sfdcId', 'Time_Zone__c']
                }
              }
            ]
          }
        },
        {
          relation: 'worker',
          scope: {
            fields: ['id', 'sfdcId', 'Name', 'Email__c', 'Work_Phone_Number__c',
              'Mobile_Phone_Number__c', 'Account_Name__c', 'Dispatch_Worker_num__c', 'Contact__c']
          }
        },
        {
          relation: 'recordType',
          scope: {
            fields: ['id', 'sfdcId', 'Name']
          }
        }
      ],
      timesheet: [
        {
          relation: 'job',
          scope: {
            fields: ['id', 'sfdcId', 'Name', 'Jobsite__c', 'Project__c','Assignment_ID__c' , 'Publish_to_Workmarket__c','Submiited_to_WM__c'],
            include: [
              {
                relation: 'jobsite',
                scope: {
                  fields: ['id', 'sfdcId', 'Time_Zone__c']
                }
              },
              {
                relation: 'project',
                scope: {
                  fields: ['Name']
                }
              }
            ]
          }
        },
        {
          relation: 'recordType',
          scope: {
            fields: ['id', 'sfdcId', 'Name']
          }
        },
        {
          relation: 'vendor',
          scope: {
            fields: ['id', 'sfdcId', 'Name']
          }
        },
        {
          relation: 'worker',
          scope: {
            fields: ['id', 'sfdcId', 'Name', 'Email__c', 'Work_Phone_Number__c',
              'Mobile_Phone_Number__c', 'Account_Name__c', 'Dispatch_Worker_num__c', 'Contact__c']
          }
        }
      ]
    }
  }
  selectedTimesheetYear = '';
  selectedTimesheetMonth = '';
  userTypeSlug: string;
  accessType: string;
  // isLoading = true;
  isBackToJobdetail = '';
  documentModelId;
  minTimeOutDate: any;
  defaultCheckOutTime: any;
  refreshFiles: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  jobScheduleList = [];
  purchaseOrderTab: any;
  purchaseOrderId: any;
  workerType = '';
  isRefreshList = false;    // to refresh the timecard history table after updating timecard
  getTimeCardData: any = {};
  error = '';
  ratingObject: any;
  viewRating = false;
  jobSfdcid: string;
  jobId: string;
  Assignment_ID__c: string;
  errorMsg = '';
  systemCurrentDate: any;
  siteVisitRatingId: any;
  loggedInContact = '';
  viewAsVendor = false;
  constructor(
    private _timecardApi: TimecardApi,
    private _preloaderService: PreloaderService,
    private _invoiceApi: InvoiceApi,
    private _alertService: AlertService,
    private _commonService: CommonService,
    private _recordTypeApi: RecordTypeApi,
    private _activatedRoute: ActivatedRoute,
    private _globalFilterService: GlobalFilterService,
    private _router: Router,
    private _sharedService: SharedService,
    private _appState: AppStateService,
    private _modelService: ModalService,
    private _jobScheduleApi: JobScheduleApi,
    private _jobWorkerRatingApi: JobWorkerRatingApi,
    private _vendorRatingApi: VendorRatingApi,
    private _siteVisitReportApi: SiteVisitReportApi,
    private _jobApi: JobApi
  ) {  }

  ngOnInit() {

    // this.selectedTimecard = 'TIME15163'
    this._commonService.saveCurrentPageAnalytics('Billing Manager - Dispatch Jobs', 'Timecard-Details');
    const appData = this._appState.getAppState();
    this.loggedInContact = appData && appData.value && appData.value['user'] &&
    appData.value['user'].sfdcId ? appData.value['user'].sfdcId : '';
    if (!this.timecardId) {
      this.sub = this._activatedRoute.params.subscribe(params => {
        this.selectedTimecard = params['id'].toUpperCase();
      });
      this._activatedRoute.queryParams.subscribe(queryParams => {
        this.purchaseOrderTab = this._activatedRoute.snapshot.queryParamMap.get('source');
        this.purchaseOrderId = this._activatedRoute.snapshot.queryParamMap.get('purchaseOrderId');

        this.isTimeSheet = queryParams.isTimeSheet === 'true';
        this.typeOfTimeLog = this.isTimeSheet ? 'Timesheet' : 'Timecard';
        this.fromcheckInOut = queryParams.fromcheckInOut === 'true';
        this.fromBillingManager = queryParams.fromBillingManager === 'true';
        this.isBackToJobdetail = (queryParams.backTo) ? queryParams.backTo : '';
      });
    }
    this.userTypeSlug = this._appState.getUserTypeSlug();
    this.accessType = this._appState.getAccessType();
    this.workerType = this._appState.getWorkerType();
    if (this.selectedTimecard) {
      this.getTimecardInfo();
    } else if (this.timecardId) {
      this.isTimeSheet = this.timeSheetDetails
      this.typeOfTimeLog = this.isTimeSheet ? 'Timesheet' : 'Timecard';
      this.getTimecardInfo();
    }
    const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
    this.viewAsVendor = (viewAsInfo && viewAsInfo.viewAsType === 'Vendor') ? true : false ;
  }

  getTimecardInfo() {
    this._preloaderService.showPreloader();
    const filter = this.timecardId ? { 'id': this.timecardId } : { 'Name': this.selectedTimecard }
    this._timecardApi.find({
      where: filter,
      fields: this.isTimeSheet ? this.fieldsAndIncludeObj.fields.timesheet : this.fieldsAndIncludeObj.fields.timecard,
      include: this.isTimeSheet ? this.fieldsAndIncludeObj.include.timesheet : this.fieldsAndIncludeObj.include.timecard

    }).subscribe(data => {
      this._preloaderService.hidePreloader();
      if (data) {
        this.timeCardData = data[0];
        this.jobSfdcid = data[0]['job']['sfdcId'];
        this.jobId = data[0]['job']['id'];
        this.Assignment_ID__c = data[0]['job'] && data[0]['job']['Assignment_ID__c']
        this.getSiteVisitReportListing();
        this.nullCheckResultForAutoUpdateFields();
        this.timeCardData.feed = { id: this.timeCardData.id, Type: 'Timecard' };
        this.documentModelId = this.timeCardData['id'];
        if (this.documentModelId) {
          this.refreshFiles.next(true);
        }
        this.getInvoices();
        this.timecardType = this.timeCardData && this.timeCardData.recordType;
        this.setTimeCardData();
        this.getJobSchedule();
      }
    }, err => {
      this._preloaderService.hidePreloader();
      // this.isLoading = false;
      console.log(err);
    })
  }

  /*Function returning Site Visit Report Data*/
  getSiteVisitReportListing() {
    if (this._router.url && this._router.url.includes('/pms/service-manager/details')) {
      const filterObj = {
        where: { Job__c: this.jobSfdcid, Timecard__c: this.timeCardData.sfdcId },
        fields: ['id', 'Job__c', 'Timecard__c'],
      };
      this._siteVisitReportApi.find(filterObj).subscribe((res: any) => {
        if (res && res.length) {
          this.siteVisitRatingId = res[0].id;
          this.viewRating = true;
        } else {
          this.viewRating = false;
        }
      }, err => {
        console.log(err);
      });
    }
  }
  /**  This method for check is added as while timecard history fields are **/
  /*  autoupdating and creating history for null value changes */
  private nullCheckResultForAutoUpdateFields() {
    if (this.timeCardData['Is_Monday_Public_Holiday__c'] === null) {
      this.getTimeCardData['Is_Monday_Public_Holiday__c'] = this.timeCardData['Is_Monday_Public_Holiday__c'];
    }
    if (this.timeCardData['Is_Tuesday_Public_Holiday__c'] === null) {
      this.getTimeCardData['Is_Tuesday_Public_Holiday__c'] = this.timeCardData['Is_Tuesday_Public_Holiday__c'];
    }
    if (this.timeCardData['Is_Wednesday_Public_Holiday__c'] === null) {
      this.getTimeCardData['Is_Wednesday_Public_Holiday__c'] = this.timeCardData['Is_Wednesday_Public_Holiday__c'];
    }
    if (this.timeCardData['Is_Thursday_Public_Holiday__c'] === null) {
      this.getTimeCardData['Is_Thursday_Public_Holiday__c'] = this.timeCardData['Is_Thursday_Public_Holiday__c'];
    }
    if (this.timeCardData['Is_Friday_Public_Holiday__c'] === null) {
      this.getTimeCardData['Is_Friday_Public_Holiday__c'] = this.timeCardData['Is_Friday_Public_Holiday__c'];
    }
    if (this.timeCardData['Is_Saturday_Public_Holiday__c'] === null) {
      this.getTimeCardData['Is_Saturday_Public_Holiday__c'] = this.timeCardData['Is_Saturday_Public_Holiday__c'];
    }
    if (this.timeCardData['Is_Sunday_Public_Holiday__c'] === null) {
      this.getTimeCardData['Is_Sunday_Public_Holiday__c'] = this.timeCardData['Is_Sunday_Public_Holiday__c'];
    }
    if (this.timeCardData['Is_Monday_Holiday_in_Jobsite__c'] === null) {
      this.getTimeCardData['Is_Monday_Holiday_in_Jobsite__c'] = this.timeCardData['Is_Monday_Holiday_in_Jobsite__c'];
    }
    if (this.timeCardData['Is_Tuesday_Holiday_in_Jobsite__c'] === null) {
      this.getTimeCardData['Is_Tuesday_Holiday_in_Jobsite__c'] = this.timeCardData['Is_Tuesday_Holiday_in_Jobsite__c'];
    }
    if (this.timeCardData['Is_Wednesday_Holiday_in_Jobsite__c'] === null) {
      this.getTimeCardData['Is_Wednesday_Holiday_in_Jobsite__c'] = this.timeCardData['Is_Wednesday_Holiday_in_Jobsite__c'];
    }
    if (this.timeCardData['Is_Thursday_Holiday_in_Jobsite__c'] === null) {
      this.getTimeCardData['Is_Thursday_Holiday_in_Jobsite__c'] = this.timeCardData['Is_Thursday_Holiday_in_Jobsite__c'];
    }
    if (this.timeCardData['Is_Friday_Holiday_in_Jobsite__c'] === null) {
      this.getTimeCardData['Is_Friday_Holiday_in_Jobsite__c'] = this.timeCardData['Is_Friday_Holiday_in_Jobsite__c'];
    }
    if (this.timeCardData['Is_Saturday_Holiday_in_Jobsite__c'] === null) {
      this.getTimeCardData['Is_Saturday_Holiday_in_Jobsite__c'] = this.timeCardData['Is_Saturday_Holiday_in_Jobsite__c'];
    }
    if (this.timeCardData['Is_Sunday_Holiday_in_Jobsite__c'] === null) {
      this.getTimeCardData['Is_Sunday_Holiday_in_Jobsite__c'] = this.timeCardData['Is_Sunday_Holiday_in_Jobsite__c'];
    }
    if (this.timeCardData['Monday_Hours__c'] === null) {
      this.getTimeCardData['Monday_Hours__c'] = this.timeCardData['Monday_Hours__c'];
    }
    if (this.timeCardData['Tuesday_Hours_Actual__c'] === null) {
      this.getTimeCardData['Tuesday_Hours_Actual__c'] = this.timeCardData['Tuesday_Hours_Actual__c'];
    }
    if (this.timeCardData['Wednesday_Hours_Actual__c'] === null) {
      this.getTimeCardData['Wednesday_Hours_Actual__c'] = this.timeCardData['Wednesday_Hours_Actual__c'];
    }
    if (this.timeCardData['Thursday_Hours_Actual__c'] === null) {
      this.getTimeCardData['Thursday_Hours_Actual__c'] = this.timeCardData['Thursday_Hours_Actual__c'];
    }
    if (this.timeCardData['Friday_Hours_Actual__c'] === null) {
      this.getTimeCardData['Friday_Hours_Actual__c'] = this.timeCardData['Friday_Hours_Actual__c'];
    }
    if (this.timeCardData['Saturday_Hours_Actual__c'] === null) {
      this.getTimeCardData['Saturday_Hours_Actual__c'] = this.timeCardData['Saturday_Hours_Actual__c'];
    }
    if (this.timeCardData['Sunday_Hours_Actual__c'] === null) {
      this.getTimeCardData['Sunday_Hours_Actual__c'] = this.timeCardData['Sunday_Hours_Actual__c'];
    }
  }


  onTabBtnClick(tab) {
    const index = this.activeTabs.indexOf(tab);
    if (index !== -1) {
      this.activeTabs.splice(index, 1);
    } else {
      this.activeTabs.push(tab);
    }
  }

  getInvoices() {
    this._preloaderService.showPreloader();
    this._invoiceApi.find({
      where: {
        'Timecard_Timesheet__c': this.timeCardData.sfdcId
      },
      fields: ['Name', 'Invoice_Date__c', 'Status__c', 'CurrencyIsoCode', 'Amount__c', 'Job_Worker__c', 'RecordTypeId'],
      include: [
        {
          relation: 'recordType',
          scope: {
            fields: ['id', 'sfdcId', 'Name']
          }
        }
      ]
    }).subscribe(data => {
      this._preloaderService.hidePreloader();
      if (data && data.length) {
        data.forEach(invoice => {
          const invoiceData: any = {
            'invoice': '',
            'day': '',
            'Status': '',
            'amount': '',
            'User': '',
            'Type': '',
            'currency': ''
          };
          invoiceData.invoice = invoice['Name'];
          invoiceData.day = invoice['Invoice_Date__c'];
          invoiceData.Status = invoice['Status__c'];
          invoiceData.currency = invoice['CurrencyIsoCode'];
          invoiceData.amount = invoice['Amount__c'];
          invoiceData.User = invoice['Job_Worker__c'];
          invoiceData.Type = invoice['recordType'];
          this.invoiceData.push(invoiceData);
        })
      }
    }, err => {
      this._preloaderService.hidePreloader();
      // this.isLoading = false;
      console.log(err);
    })
  }

  approvalStatusChange(value, name?) {
    switch (name) {
      case 'approvalStatus':
       // this.approvalStatus = value && value.target.value;
        this.validateIronApproval(value)
        break;
      case 'selectedCustAppStatus':
        this.validateCustomerApproval(value)
        break;
      case 'selectedactualConverage':
        this.selectedactualConverage = value && value.target.value;
        break;
      case 'selectedactualEndTime':
        this.selectedactualEndTime = value && value.target.value;
        break;
      case 'selectedTimesheetYear':
        this.selectedTimesheetYear = value && value.target.value;
        break;
      case 'selectedTimesheetMonth':
        this.selectedTimesheetMonth = value && value.target.value;
        break;
    }
  }


  validateIronApproval(value) {
    this.errorMsg = '';
    if((this.timeCardData.Customer_Approval_Status__c == 'Approved' || this.timeCardData.Customer_Approval_Status__c == 'Submitted') && value && value.target.value != 'Approved'){
      this.errorMsg = 'You can not change Iron Status to ' + value.target.value + ' if Customer approval status is ' + this.timeCardData.Customer_Approval_Status__c;
      this.approvalStatus = this.timeCardData.Status__c;
    }
    else{
    if (value && value.target.value == 'Approved' && this.timeCardData.Status__c != 'Approved') {
      if (this.timeCardData.Status__c == 'Submitted') {
        this.approvalStatus = value && value.target.value;
      }else{
        this.errorMsg = 'For Iron Approval Iron Status should be Submitted';
        this.approvalStatus = this.timeCardData.Status__c;
      }
    } else if (value && value.target.value == 'Submitted' && this.timeCardData.Status__c != 'Submitted') {
      if (this.timeCardData.Status__c == 'Not Submitted') {
        this.approvalStatus = value && value.target.value;
      } else {
        this.errorMsg = 'For Iron Submit Iron Status should be Not Submitted';
        this.approvalStatus = this.timeCardData.Customer_Approval_Status__c;
      }
    }
    else {
      this.approvalStatus = value && value.target.value;
    }
  }

  }


  validateCustomerApproval(value) {
    this.errorMsg = '';
    if (value && value.target.value == 'Approved' && this.timeCardData.Customer_Approval_Status__c != 'Approved') {
      if (this.timeCardData.Status__c == 'Approved' && this.timeCardData.Customer_Approval_Status__c == 'Submitted') {
        if (!this.timeCardData.Refresh_Flag__c) {
          if (this.invoiceData.length == 0) {
            if (this.timeCardData['job'] && this.Assignment_ID__c == null || this.Assignment_ID__c == '') {
              if (this.timeCardData['job']['Publish_to_Workmarket__c'] || !this.timeCardData['job']['Submiited_to_WM__c']) {

                this.selectedCustAppStatus = value && value.target.value;

              } else {
                this.errorMsg = 'publish to workmarket not set or VFMS/WM already set';
                this.selectedCustAppStatus = this.timeCardData.Customer_Approval_Status__c;
              }

            } else {
              this.errorMsg = 'VFMS/WM Assignment should not blank';
              this.selectedCustAppStatus = this.timeCardData.Customer_Approval_Status__c;

            }
          } else {
            this.errorMsg = 'Invoice is already created';
            this.selectedCustAppStatus = this.timeCardData.Customer_Approval_Status__c;
          }
        } else {
          this.errorMsg = 'Refresh Flag is set';
          this.selectedCustAppStatus = this.timeCardData.Customer_Approval_Status__c;
        }
      } else {
        this.errorMsg = 'For customer approval Iron Status should be Approved and Customer status should be Submitted';
        // this._alertService.error('For customer approval Iron Status should be Approved and Customer status should be submitted');
        this.selectedCustAppStatus = this.timeCardData.Customer_Approval_Status__c;

      }
    } else if (value && value.target.value == 'Submitted' && this.timeCardData.Customer_Approval_Status__c != 'Submitted') {
      if (this.timeCardData.Status__c == 'Approved' && this.timeCardData.Customer_Approval_Status__c == 'Not Submitted') {
        this.selectedCustAppStatus = value && value.target.value;
      } else {
        this.errorMsg = 'For customer submission Iron Status should be Approved and Customer status should be Not Submitted';
        this.selectedCustAppStatus = this.timeCardData.Customer_Approval_Status__c;
      }
    }
    else {
      this.selectedCustAppStatus = value && value.target.value;
    }

  }

  setTimeCardData = () => {
    if (this.timeCardData) {
      this.submittedTimecard = this.timeCardData.job && this.timeCardData.job.submittedTimecard;
      // const jobSiteData = this.timeCardData.job && this.timeCardData.job.jobsite &&;
      // this._commonService.applyNewTimeZone(item.Vendor_Time_Card_Time_in_Actual__c, this.timeZone) : '';
      this.timeZone = this.timeCardData.job && this.timeCardData.job.jobsite && this.timeCardData.job.jobsite.Time_Zone__c ?
        this.timeCardData.job.jobsite.Time_Zone__c : '';
      this.approvalStatus = this.timeCardData && this.timeCardData.Status__c;
      this.normalHours = this.timeCardData && this.timeCardData.Timecard_Normal_Hours__c;
      this.AFTHhours = this.timeCardData && this.timeCardData.Timecard_OT_AFTH__c;
      this.selectedactualConverage = this.timeCardData && this.timeCardData.Actual_Coverage_Hour__c;
      this.selectedactualEndTime = this.timeCardData && this.timeCardData.Actual_End_Time_Coverage__c;
      this.selectedCustAppStatus = this.timeCardData && this.timeCardData.Customer_Approval_Status__c;
      this.timeCardStartDate = this.timeCardData && this.timeCardData.Timesheet_Start_Date__c;
      // this.vendorTimeIn = this.timeCardData && this.timeCardData.Vendor_Time_Card_Time_in_Actual__c;
      this.vendorTimeIn = this.timeCardData && this.timeCardData.Vendor_Time_Card_Time_in_Actual__c ?
        this._commonService.applyNewTimeZone(this.timeCardData.Vendor_Time_Card_Time_in_Actual__c, this.timeZone) : '';
      // this.vendorTimeOut = this.timeCardData && this.timeCardData.Vendor_Time_Card_Time_Out_Actual__c;
      this.vendorTimeOut = this.timeCardData && this.timeCardData.Vendor_Time_Card_Time_Out_Actual__c ?
        this._commonService.applyNewTimeZone(this.timeCardData.Vendor_Time_Card_Time_Out_Actual__c, this.timeZone) : '';
      this.minTimeOutDate = this._commonService.addTime(this.vendorTimeIn, 1, 'm', true).toISOString();
      this.workerArrivalCustRqst = this.timeCardData && this.timeCardData.job && this.timeCardData.job.Worker_Arrival_DateTime_Cust_Requested__c ?
        this._commonService.applyNewTimeZone(this.timeCardData.job.Worker_Arrival_DateTime_Cust_Requested__c, this.timeZone) : '';
      this.defaultCheckOutTime = this.vendorTimeOut;
      this.timeCardIncurredDate = this.timeCardData && this.timeCardData.Incurred_Date__c;
      this.Customer_Site_Signoff_Name__c = this.timeCardData && this.timeCardData.Customer_Site_Signoff_Name__c;
      this.Vendor_Time_Card_Notes_Tasks_Performed__c = this.timeCardData && this.timeCardData.Vendor_Time_Card_Notes_Tasks_Performed__c;
      this.Any_Observation_or_Suggestions_Notes__c = this.timeCardData && this.timeCardData.Any_Observation_or_Suggestions_Notes__c;
      this.timeInformationData.forEach(day => {
        switch (day.Name) {
          case 'Monday':
            day.actualHours = this.timeCardData.Monday_Hours__c || 0;
            day.scheduledHours = this.timeCardData.Monday_Hours_Job_Schedule__c;
            day.scheduledInHoliday = this.timeCardData.Is_Monday_Holiday_in_Jobsite__c || day.scheduledInHoliday;
            day.publicHoliday = this.timeCardData.Is_Monday_Public_Holiday__c || day.publicHoliday;
            break;
          case 'Tuesday':
            day.actualHours = this.timeCardData.Tuesday_Hours_Actual__c || 0;
            day.scheduledHours = this.timeCardData.Tuesday_Hours_Job_Schedule__c;
            day.scheduledInHoliday = this.timeCardData.Is_Tuesday_Holiday_in_Jobsite__c || day.scheduledInHoliday;
            day.publicHoliday = this.timeCardData.Is_Tuesday_Public_Holiday__c || day.publicHoliday;
            break;
          case 'Wednesday':
            day.actualHours = this.timeCardData.Wednesday_Hours_Actual__c || 0;
            day.scheduledHours = this.timeCardData.Wednesday_Hours_Job_Schedule__c;
            day.scheduledInHoliday = this.timeCardData.Is_Wednesday_Holiday_in_Jobsite__c || day.scheduledInHoliday;
            day.publicHoliday = this.timeCardData.Is_Wednesday_Public_Holiday__c || day.publicHoliday;
            break;
          case 'Thursday':
            day.actualHours = this.timeCardData.Thursday_Hours_Actual__c || 0;
            day.scheduledHours = this.timeCardData.Thursday_Hours_Job_Schedule__c;
            day.scheduledInHoliday = this.timeCardData.Is_Thursday_Holiday_in_Jobsite__c || day.scheduledInHoliday;
            day.publicHoliday = this.timeCardData.Is_Thursday_Public_Holiday__c || day.publicHoliday;
            break;
          case 'Friday':
            day.actualHours = this.timeCardData.Friday_Hours_Actual__c || 0;
            day.scheduledHours = this.timeCardData.Friday_Hours_Job_Schedule__c;
            day.scheduledInHoliday = this.timeCardData.Is_Friday_Holiday_in_Jobsite__c || day.scheduledInHoliday;
            day.publicHoliday = this.timeCardData.Is_Friday_Public_Holiday__c || day.publicHoliday;
            break;
          case 'Saturday':
            day.actualHours = this.timeCardData.Saturday_Hours_Actual__c || 0;
            day.scheduledHours = this.timeCardData.Saturday_Hours_Job_Schedule__c;
            day.scheduledInHoliday = this.timeCardData.Is_Saturday_Holiday_in_Jobsite__c || day.scheduledInHoliday;
            day.publicHoliday = this.timeCardData.Is_Saturday_Public_Holiday__c || day.publicHoliday;
            break;
          case 'Sunday':
            day.actualHours = this.timeCardData.Sunday_Hours_Actual__c || 0;
            day.scheduledHours = this.timeCardData.Sunday_Hours_Job_Schedule__c;
            day.scheduledInHoliday = this.timeCardData.Is_Sunday_Holiday_in_Jobsite__c || day.scheduledInHoliday;
            day.publicHoliday = this.timeCardData.Is_Sunday_Public_Holiday__c || day.publicHoliday;
            break;
        }
      });
      if (this.isTimeSheet) {
        this.timeCardData.CreatedDate = this.timeCardData.CreatedDate ? this._commonService.dateFormate(this.timeCardData.CreatedDate) : '';
        this.timeCardData.updatedAt = this.timeCardData.updatedAt ?
          this._commonService.dateFormate(this.timeCardData.updatedAt) : '';
        this.timeCardData['totalWorkedHoursNormal'] = (this.timeCardData.Total_Timesheet_Hours__c || 0) + '-' +
          (this.timeCardData.Total_Overtime_Hours_Holiday_Weekday__c || 0) + '-' +
          (this.timeCardData.Total_Overtime_Hours_Standard_Weekday__c || 0);
        this.timeCardData.Business_Start_Time__c = this.timeCardData.Business_Start_Time__c ?
          this._commonService.dateFormate(this.timeCardData.Business_Start_Time__c, '', 'hh:mm:ss a') : '';
      }
      if (!this.isTimeSheet) {
        this.Equipment_Make_Model_Performed_on__c = this.timeCardData && this.timeCardData.Equipment_Make_Model_Performed_on__c;
        this.Equipment_Serial_Performed_on__c = this.timeCardData && this.timeCardData.Equipment_Serial_Performed_on__c;
        this.Worker_Mobile_OS_Android_iOS__c = this.timeCardData && this.timeCardData.Worker_Mobile_OS_Android_iOS__c;
      }
    }
    this.selectedTimesheetYear = this.timeCardData.Timesheet_Year__c;
    this.selectedTimesheetMonth = this.timeCardData.Timesheet_Month__c;
    // this.isLoading = false;
  }

  submitForIronApproval() {
    this._preloaderService.showPreloader();
    this._timecardApi.patchAttributes(this.timeCardData['id'], { 'Status__c': 'Submitted' }).subscribe(res => {
      this.approvalStatus = 'Submitted';
      this._preloaderService.hidePreloader();
      this._alertService.success('Timecard updated successfully.')
    }, err => {
      this._preloaderService.hidePreloader();
      this._alertService.error((err && err.msg) ? err.msg : err);
    })
  }

  isItChecked(day) {
    // return day ? true : false;
    return day === 'true' ? true : false;
  }

  checkboxChanges(event, day, name) {
    switch (name) {
      case 'scheduledInHoliday':
        day.scheduledInHoliday = event.target.value;
        break;
      case 'publicHoliday':
        day.publicHoliday = event.target.value;
        break;
    }
  }

  jobDetial(sfdcId) {
    console.log(sfdcId);
    this.timeCardData['Service_Dispatch__c'] = sfdcId;
  }

  setWorker(sfdcId) {
    console.log(sfdcId);
    this.timeCardData['Worker__c'] = sfdcId;
  }
  setVendor(sfdcId) {
    console.log(sfdcId);
    this.timeCardData['Vendor__c'] = sfdcId;
  }

  /* setting purchase order */
  setProductFilter(sfdcId, field) {
    this.timeCardData[field] = sfdcId;
  }

  startSave(modal? , size?) {
    if(this.Customer_Site_Signoff_Name__c){
      if (this.viewRating) {
        this.apiCallEvent.emit(true);
        this.openvendor(modal, size);
      } else {
        this._preloaderService.showPreloader();
        this.setTimeCardDataToSubmit();
      } 
      // this._timecardApi.upsert(this.timeCardData).subscribe(res => {
  
      // }, err => {
  
      // })
    } else {
      this._alertService.error('Customer Site Signoff Name is Required');
    }
  }
  
  openvendor(modal , size){   
    this._modelService.open(modal, size);
  }

  selectDateFilter(event) {
    if (event.value) {
      this.timeCardData['Timesheet_Start_Date__c'] = this._commonService.setNewTimeZone(event.value, this.timeZone);
    } else {
      this.timeCardData['Timesheet_Start_Date__c'] = this.timeCardStartDate;
    }
  }

  approvalSubmitClickHandler(type) { 
    let req = {};
    switch (type) {
      case 'approvalStatus':
        req['Status__c'] = 'Submitted';
        break;
      case 'customerApprovalStatus':
        req['Customer_Approval_Status__c'] = 'Submitted';
        break;
    };
    this._preloaderService.showPreloader();
    this.apiCallEvent.emit(true);
    this._timecardApi.patchAttributes(this.timeCardData['id'], req).subscribe(res => {
      switch (type) {
        case 'approvalStatus':
          this.approvalStatus = 'Submitted';
          this.timeCardData['Status__c'] = 'Submitted';
          break;
        case 'customerApprovalStatus':
          this.selectedCustAppStatus = 'Submitted';
          this.timeCardData['Customer_Approval_Status__c'] = 'Submitted';
          break;
      };
      this._preloaderService.hidePreloader();
      this._alertService.success('Timecard updated successfully.')
    }, err => {
      this._preloaderService.hidePreloader();
      this._alertService.error((err && err.msg) ? err.msg : err);
    })
  }

  setTimeCardDataToSubmit() {
    if (!this.isTimeSheet && this.accessType === 'internal' && this.approvalStatus !== 'Approved' && this.selectedCustAppStatus !== 'Not Submitted') {
      this._preloaderService.hidePreloader();
      this.error = 'Only Iron Approved time cards can be submitted for Customer Approval or Approved.';
      setTimeout(() => {
        this.error = '';
      }, 2000);
      return;
    }
    const req = {};
    const timeCard: any = {};
    this.timeCardData['Status__c'] = this.approvalStatus;
    this.timeCardData['Customer_Approval_Status__c'] = this.selectedCustAppStatus;
    this.timeCardData['Logged_in_Contact__c'] = this.loggedInContact;
    // this.timeCardData['Timesheet_Start_Date__c'] = this.timeCardStartDate &&
    //   this._commonService.setNewTimeZone(this.timeCardStartDate, this.timeZone);
    this.timeCardData.Customer_Site_Signoff_Name__c = this.Customer_Site_Signoff_Name__c;
    if (!this.isTimeSheet) {
      this.timeCardData.Equipment_Make_Model_Performed_on__c = this.Equipment_Make_Model_Performed_on__c;
      this.timeCardData.Equipment_Serial_Performed_on__c = this.Equipment_Serial_Performed_on__c;
      this.timeCardData.Worker_Mobile_OS_Android_iOS__c = this.Worker_Mobile_OS_Android_iOS__c;
    }
    this.timeCardData.Any_Observation_or_Suggestions_Notes__c = this.Any_Observation_or_Suggestions_Notes__c;
    this.timeInformationData.forEach(day => {
      switch (day.Name) {
        case 'Monday':
          this.timeCardData.Monday_Hours__c = day.actualHours;
          this.timeCardData.Is_Monday_Holiday_in_Jobsite__c = day.scheduledInHoliday || false;
          this.timeCardData.Is_Monday_Public_Holiday__c = day.publicHoliday || false;
          break;
        case 'Tuesday':
          this.timeCardData.Tuesday_Hours_Actual__c = day.actualHours;
          this.timeCardData.Is_Tuesday_Holiday_in_Jobsite__c = day.scheduledInHoliday || false;
          this.timeCardData.Is_Tuesday_Public_Holiday__c = day.publicHoliday || false;
          break;
        case 'Wednesday':
          this.timeCardData.Wednesday_Hours_Actual__c = day.actualHours;
          this.timeCardData.Is_Wednesday_Holiday_in_Jobsite__c = day.scheduledInHoliday || false;
          this.timeCardData.Is_Wednesday_Public_Holiday__c = day.publicHoliday || false;
          break;
        case 'Thursday':
          this.timeCardData.Thursday_Hours_Actual__c = day.actualHours;
          this.timeCardData.Is_Thursday_Holiday_in_Jobsite__c = day.scheduledInHoliday || false;
          this.timeCardData.Is_Thursday_Public_Holiday__c = day.publicHoliday || false;
          break;
        case 'Friday':
          this.timeCardData.Friday_Hours_Actual__c = day.actualHours;
          this.timeCardData.Is_Friday_Holiday_in_Jobsite__c = day.scheduledInHoliday || false;
          this.timeCardData.Is_Friday_Public_Holiday__c = day.publicHoliday || false;
          break;
        case 'Saturday':
          this.timeCardData.Saturday_Hours_Actual__c = day.actualHours;
          this.timeCardData.Is_Saturday_Holiday_in_Jobsite__c = day.scheduledInHoliday || false;
          this.timeCardData.Is_Saturday_Public_Holiday__c = day.publicHoliday || false;
          break;
        case 'Sunday':
          this.timeCardData.Sunday_Hours_Actual__c = day.actualHours;
          this.timeCardData.Is_Sunday_Holiday_in_Jobsite__c = day.scheduledInHoliday || false;
          this.timeCardData.Is_Sunday_Public_Holiday__c = day.publicHoliday || false;
          break;
      }
    });

    if (this.isTimeSheet) {
      this.timeCardData['Timesheet_Year__c'] = this.selectedTimesheetYear;
      this.timeCardData['Timesheet_Month__c'] = this.selectedTimesheetMonth;
    } else {
      // this.timeCardData['Actual_Coverage_Hour__c'] = this.actualCoverageHrs;
      // this.timeCardData['Actual_End_Time_Coverage__c'] = this.actualEndTimeCoverage;
      this.timeCardData['Vendor_Time_Card_Time_in_Actual__c'] = this.vendorTimeIn &&
        this._commonService.setNewTimeZone(this.vendorTimeIn, this.timeZone);
      this.timeCardData['Vendor_Time_Card_Time_Out_Actual__c'] = this.vendorTimeOut &&
        this._commonService.setNewTimeZone(this.vendorTimeOut, this.timeZone);
      this.timeCardData['Incurred_Date__c'] = this.timeCardIncurredDate &&
        this._commonService.setNewTimeZone(this.timeCardIncurredDate, this.timeZone);
      // this.timeCardData['Incurred_Date__c'] = this.timeCardIncurredDate;
      this.timeCardData.Vendor_Time_Card_Notes_Tasks_Performed__c = this.Vendor_Time_Card_Notes_Tasks_Performed__c;
      this.timeCardData.Actual_Coverage_Hour__c = this.selectedactualConverage;
      this.timeCardData.Actual_End_Time_Coverage__c = this.selectedactualEndTime;
      // const jobsite: any = {};
      // jobSiteData['id'] = timeCard.job && timeCard.job.jobsite && timeCard.job.jobsite.id;
      // jobSiteData['sfdcId'] = timeCard.job && timeCard.job.jobsite && timeCard.job.jobsite.sfdcId;
      // Object.assign(jobsite, jobSiteData);
      // req['jobsite'] = jobsite;
    }
    Object.assign(timeCard, this.timeCardData);
    req['timecard'] = timeCard;
    req['timecard']['Incurred_Date__c'] = req['timecard']['Vendor_Time_Card_Time_in_Actual__c'];
    const appData = this._appState.getAppState();
    const CreatedById = appData && appData.value && appData.value['user'] &&
      appData.value['user'].sfdcId ? appData.value['user'].sfdcId : '';
    req['timecard']['CreatedById'] = CreatedById;
    delete req['timecard'].job;
    delete req['timecard'].vendor;
    delete req['timecard'].worker;
    delete req['timecard'].Business_Start_Time__c;
    delete req['timecard'].recordType;
    this.saveTimecardData(req);
    if (this.viewRating === true) {
      this.saveTimecardDataWithRating();
    }
  }

  saveTimecardData(req) {
    this.checkNullCondition(req);
    this.apiCallEvent.emit(true);
    this._timecardApi.patchAttributes(req['timecard']['id'], req['timecard']).subscribe(res => {
      // this._timecardApi.updateTimecardData(req).subscribe(res => {
      this.isRefreshList = true;
      this._preloaderService.hidePreloader();
      this._alertService.success('Timecard updated successfully.')
    }, err => {
      this._preloaderService.hidePreloader();
      this._alertService.error((err && err.msg) ? err.msg : err);
    })
    this.isRefreshList = false;
  }

  /**  This method for check is added as while timecard history fields are **/
  /*  autoupdating and creating history for null value changes so we are deleting this fields when we are not updating these fields */
  private checkNullCondition(req) {
    if (this.getTimeCardData['Is_Monday_Public_Holiday__c'] === null && req['timecard'].Is_Monday_Public_Holiday__c === false) {
      delete req['timecard'].Is_Monday_Public_Holiday__c;
    }
    if (this.getTimeCardData['Is_Tuesday_Public_Holiday__c'] === null && req['timecard'].Is_Tuesday_Public_Holiday__c === false) {
      delete req['timecard'].Is_Tuesday_Public_Holiday__c;
    }
    if (this.getTimeCardData['Is_Wednesday_Public_Holiday__c'] === null && req['timecard'].Is_Wednesday_Public_Holiday__c === false) {
      delete req['timecard'].Is_Wednesday_Public_Holiday__c;
    }
    if (this.getTimeCardData['Is_Thursday_Public_Holiday__c'] === null && req['timecard'].Is_Thursday_Public_Holiday__c === false) {
      delete req['timecard'].Is_Thursday_Public_Holiday__c;
    }
    if (this.getTimeCardData['Is_Friday_Public_Holiday__c'] === null && req['timecard'].Is_Friday_Public_Holiday__c === false) {
      delete req['timecard'].Is_Friday_Public_Holiday__c;
    }
    if (this.getTimeCardData['Is_Saturday_Public_Holiday__c'] === null && req['timecard'].Is_Saturday_Public_Holiday__c === false) {
      delete req['timecard'].Is_Saturday_Public_Holiday__c;
    }
    if (this.getTimeCardData['Is_Sunday_Public_Holiday__c'] === null && req['timecard'].Is_Sunday_Public_Holiday__c === false) {
      delete req['timecard'].Is_Sunday_Public_Holiday__c;
    }
    if (this.getTimeCardData['Is_Monday_Holiday_in_Jobsite__c'] === null && req['timecard'].Is_Monday_Holiday_in_Jobsite__c === false) {
      delete req['timecard'].Is_Monday_Holiday_in_Jobsite__c;
    }
    if (this.getTimeCardData['Is_Tuesday_Holiday_in_Jobsite__c'] === null && req['timecard'].Is_Tuesday_Holiday_in_Jobsite__c === false) {
      delete req['timecard'].Is_Tuesday_Holiday_in_Jobsite__c;
    }
    if (this.getTimeCardData['Is_Wednesday_Holiday_in_Jobsite__c'] === null && req['timecard'].Is_Wednesday_Holiday_in_Jobsite__c === false) {
      delete req['timecard'].Is_Wednesday_Holiday_in_Jobsite__c;
    }
    if (this.getTimeCardData['Is_Thursday_Holiday_in_Jobsite__c'] === null && req['timecard'].Is_Thursday_Holiday_in_Jobsite__c === false) {
      delete req['timecard'].Is_Thursday_Holiday_in_Jobsite__c;
    }
    if (this.getTimeCardData['Is_Friday_Holiday_in_Jobsite__c'] === null && req['timecard'].Is_Friday_Holiday_in_Jobsite__c === false) {
      delete req['timecard'].Is_Friday_Holiday_in_Jobsite__c;
    }
    if (this.getTimeCardData['Is_Saturday_Holiday_in_Jobsite__c'] === null && req['timecard'].Is_Saturday_Holiday_in_Jobsite__c === false) {
      delete req['timecard'].Is_Saturday_Holiday_in_Jobsite__c;
    }
    if (this.getTimeCardData['Is_Sunday_Holiday_in_Jobsite__c'] === null && req['timecard'].Is_Sunday_Holiday_in_Jobsite__c === false) {
      delete req['timecard'].Is_Sunday_Holiday_in_Jobsite__c;
    }
    if (this.getTimeCardData['Monday_Hours__c'] === null && req['timecard'].Monday_Hours__c === 0) {
      delete req['timecard'].Monday_Hours__c;
    }
    if (this.getTimeCardData['Tuesday_Hours_Actual__c'] === null && req['timecard'].Tuesday_Hours_Actual__c === 0) {
      delete req['timecard'].Tuesday_Hours_Actual__c;
    }
    if (this.getTimeCardData['Wednesday_Hours_Actual__c'] === null && req['timecard'].Wednesday_Hours_Actual__c === 0) {
      delete req['timecard'].Wednesday_Hours_Actual__c;
    }
    if (this.getTimeCardData['Thursday_Hours_Actual__c'] === null && req['timecard'].Thursday_Hours_Actual__c === 0) {
      delete req['timecard'].Thursday_Hours_Actual__c;
    }
    if (this.getTimeCardData['Friday_Hours_Actual__c'] === null && req['timecard'].Friday_Hours_Actual__c === 0) {
      delete req['timecard'].Friday_Hours_Actual__c;
    }
    if (this.getTimeCardData['Saturday_Hours_Actual__c'] === null && req['timecard'].Saturday_Hours_Actual__c === 0) {
      delete req['timecard'].Saturday_Hours_Actual__c;
    }
    if (this.getTimeCardData['Sunday_Hours_Actual__c'] === null && req['timecard'].Sunday_Hours_Actual__c === 0) {
      delete req['timecard'].Sunday_Hours_Actual__c;
    }
    return req['timecard'];
  }

  disableSave() {
    if(this.errorMsg != ''){
      return true;
    }
    if (!this.isTimeSheet) {
      if (this.Worker_Mobile_OS_Android_iOS__c && this.Equipment_Make_Model_Performed_on__c && this.Equipment_Serial_Performed_on__c) {
        return false;
      }
    }
    if (this.timeCardStartDate && this.timeCardData['Service_Dispatch__c'] &&
      this.timeCardData['Worker__c'] && this.timeCardData['Vendor__c'] &&
      this.approvalStatus && this.selectedCustAppStatus &&
      this.timeCardIncurredDate && this.vendorTimeIn && this.vendorTimeOut) {
      return false;
    }
    return true;
  }

  disableSaveOfTimesheet() {
    if(this.errorMsg != ''){
      return true;
    }
    if (this.timeCardStartDate && this.selectedTimesheetYear && this.selectedTimesheetMonth
      && this.timeCardData['Worker__c'] && this.timeCardData['Vendor__c'] && this.approvalStatus
      && this.customerApprovalStatus) {
      return false;
    }
    return true;
  }

  isRightMenuOpened(isOpened) {
    this.sidebarMenuOpened = isOpened;
    this._globalFilterService.setSidebarState(isOpened);
  }

  appliedFilters(e) {
    this.filterData = e;
    this.isFilterApplied = Object.keys(e).length ? true : false;
  }

  uploadDocument(modal, size) {
    this._modelService.open(modal, size);
  }

  documentUploaded(doc) {
    this.refreshFiles.next(true);
  }

  redirectToList(event) {
    event.preventDefault();
    if (this.fromcheckInOut) {
      const params: any = {
        tabId: 'billingManager'
      }
      this._router.navigate(['/app/workforce-checkin-checkout'], { queryParams: params });
    } else if(this.fromBillingManager) {
      const params: any = {
        activeId: 'timesheetList'
      }
      this._router.navigate(['/vms/billing-manager'], { queryParams: params });
    } else {
      const params: any = {};
      if (this.isTimeSheet) {
        params.activeId = 'contractJobs';
        params.tab = 'timesheetList';
      }
      else if (this.purchaseOrderTab == 'TimeCardListingPO') {
        params.activeId = 'vendorPayment';
        params.subTab = 'timeCard';
      }
      else if (this.purchaseOrderTab == 'TimeSheetListingPO') {
        params.activeId = 'vendorPayment';
        params.subTab = 'timesheet';
      }
      else {
        params.activeId = 'dispatchJobs';
        params.tab = 'timeCardsList';
      }
      const path = this.accessType === 'vendor' ? '/vms' : '/pms';
      if (this.isBackToJobdetail) {
        this._router.navigate([this.isBackToJobdetail], { queryParams: { activeId: 'timesheet' } });
      }
      else if (this.purchaseOrderTab == 'TimeCardListingPO') {
        this._router.navigate([path + '/vms-billing-manager/'], { queryParams: { activeId: 'vendorPayment', subTab: 'timeCard', tab: 'purchaseOrder', purchaseOrderId: this.purchaseOrderId } });
      }
      else if (this.purchaseOrderTab == 'TimeSheetListingPO') {
        this._router.navigate([path + '/vms-billing-manager/'], { queryParams: { activeId: 'vendorPayment', subTab: 'timeSheet', tab: 'purchaseOrder', purchaseOrderId: this.purchaseOrderId } });
      }
      else {
        this._router.navigate([path + '/vms-billing-manager/'], { queryParams: params });
      }

    }
  }

  setDate(date1, date2) {
    const dt1 = date1 ? new Date(date1) : new Date();
    const dt2 = date2 ? new Date(date2) : new Date();
    dt1.setDate(dt2.getDate());
    dt1.setMonth(dt2.getMonth());
    dt1.setFullYear(dt2.getFullYear());
    return dt1;
  }

  onDateChange(e, source) {
    const newValue = e.value;
    const newValueStr = newValue.toISOString();

    if (source === 'vendorTimeIn') {
      this.vendorTimeIn = newValue;
      this.timeCardStartDate = this.setDate(this.timeCardStartDate, newValue);
      this.minTimeOutDate = this._commonService.addTime(newValue, 1, 'm', true).toISOString();
      this.defaultCheckOutTime = (this.defaultCheckOutTime < newValueStr) ?
        this._commonService.addTime(newValueStr, 1, 'm', true).toISOString()
        : this.defaultCheckOutTime;
      if (this.vendorTimeIn >
        new Date(this.vendorTimeOut)) {
        this.vendorTimeOut = null;
      }
    } else if (source === 'vendorTimeOut') {
      this.defaultCheckOutTime = newValueStr;
    }
  }

  getJobSchedule() {
    this._preloaderService.showPreloader();
    this._jobScheduleApi.find({
      where: {
        'Timecard_Timesheet__c': this.timeCardData.sfdcId
      },
      fields: ['id', 'sfdcId', 'Name', 'Status__c', 'RecordTypeId', 'createdAt', 'CreatedById'],
      include: [
        {
          relation: 'recordType',
          scope: {
            fields: ['id', 'sfdcId', 'Name']
          }
        },
        {
          relation: 'createdBy',
          scope: {
            fields: ['id', 'sfdcId', 'firstname', 'lastname']
          }
        }
      ]
    }).subscribe(jobSchedule => {
      this._preloaderService.hidePreloader();
      if (jobSchedule && jobSchedule.length) {
        this.jobScheduleList = jobSchedule;
      }
    }, err => {
      this._preloaderService.hidePreloader();
      // this.isLoading = false;
      console.log(err);
    })
  }

  /**
   * get all object key related to worker and vendor rating
   * @param e icc | pgmo ration object
   */
  onRatingFromControl(e) {
    console.log('RatingObj..>', JSON.stringify(e));
    this.ratingObject = e;
    this._preloaderService.showPreloader();
    this.setTimeCardDataToSubmit();
    this._modelService.closed();
  }

  currentDate() {
    const d = new Date();
    this.systemCurrentDate = d.toISOString();
  }

  /**  This method for saving timecard having vendor and worker rating**/
  saveTimecardDataWithRating() {
    this.currentDate();
    const jobWorkerRatingObj = {
      Quality__c: this.ratingObject['iccRating']['Quality__c'],
      Professionalism__c: this.ratingObject['iccRating']['Professionalism__c'],
      Communication__c: this.ratingObject['iccRating']['Communication__c'],
      Worker_Arrival_On_Time__c: this.ratingObject['iccRating']['Worker_Arrival_On_Time__c'],
      Value__c: this.ratingObject['iccRating']['Value__c'],
      Review__c: this.ratingObject['iccRating']['Review__c'],
      Quality_PGMO__c: this.ratingObject['pgmoRating']['Quality_PGMO__c'],
      Professionalism_PGMO__c: this.ratingObject['pgmoRating']['Professionalism_PGMO__c'],
      Communication_PGMO__c: this.ratingObject['pgmoRating']['Communication_PGMO__c'],
      Worker_Arrival_On_Time_PGMO__c: this.ratingObject['pgmoRating']['Worker_Arrival_On_Time_PGMO__c'],
      Value_PGMO__c: this.ratingObject['pgmoRating']['Value_PGMO__c'],
      Review_PGMO__c: this.ratingObject['pgmoRating']['Review_PGMO__c'],
    }

    const siteVisitReportObj = {
      Quality__c: this.ratingObject['iccRating']['Quality__c'],
      Professionalism__c: this.ratingObject['iccRating']['Professionalism__c'],
      Communication__c: this.ratingObject['iccRating']['Communication__c'],
      Worker_Arrival_On_Time__c: this.ratingObject['iccRating']['Worker_Arrival_On_Time__c'],
      Value__c: this.ratingObject['iccRating']['Value__c'],
      Review__c: this.ratingObject['iccRating']['Review__c'],
      Status__c: 'ICC Submitted',
      ICC_Submitted_DateTime__c: this.systemCurrentDate,
      Vendor_Quality__c: this.ratingObject['iccRating']['v_Quality__c'],
      Vendor_Professionalism__c: this.ratingObject['iccRating']['v_Professionalism__c'],
      Vendor_Communication__c: this.ratingObject['iccRating']['v_Communication__c'],
      Vendor_Value__c: this.ratingObject['iccRating']['v_Value__c'],
      Vendor_Review__c: this.ratingObject['iccRating']['v_Review__c'],
      Quality_Worker_PGMO__c: this.ratingObject['pgmoRating']['Quality_PGMO__c'],
      Professionalism_Worker_PGMO__c: this.ratingObject['pgmoRating']['Professionalism_PGMO__c'],
      Communication_Worker_PGMO__c: this.ratingObject['pgmoRating']['Communication_PGMO__c'],
      Worker_Arrival_On_Time_Worker_PGMO__c: this.ratingObject['pgmoRating']['Worker_Arrival_On_Time_PGMO__c'],
      Value_Worker_PGMO__c: this.ratingObject['pgmoRating']['Value_PGMO__c'],
      Review_Worker_PGMO__c: this.ratingObject['pgmoRating']['Review_PGMO__c'],
      Quality_Vendor_PGMO__c: this.ratingObject['pgmoRating']['v_Quality_PGMO__c'],
      Professionalism_Vendor_PGMO__c: this.ratingObject['pgmoRating']['v_Professionalism_PGMO__c'],
      Communication_Vendor_PGMO__c: this.ratingObject['pgmoRating']['v_Communication_PGMO__c'],
      Value_Vendor_PGMO__c: this.ratingObject['pgmoRating']['v_Value_PGMO__c'],
      Review_Vendor_PGMO__c: this.ratingObject['pgmoRating']['v_Review_PGMO__c']
    }
    const vendorRatingObj = {
      Quality__c: this.ratingObject['iccRating']['v_Quality__c'],
      Professionalism__c: this.ratingObject['iccRating']['v_Professionalism__c'],
      Communication__c: this.ratingObject['iccRating']['v_Communication__c'],
      Value__c: this.ratingObject['iccRating']['v_Value__c'],
      Review__c: this.ratingObject['iccRating']['v_Review__c'],
      Quality_PGMO__c: this.ratingObject['pgmoRating']['v_Quality_PGMO__c'],
      Professionalism_PGMO__c: this.ratingObject['pgmoRating']['v_Professionalism_PGMO__c'],
      Communication_PGMO__c: this.ratingObject['pgmoRating']['v_Communication_PGMO__c'],
      Value_PGMO__c: this.ratingObject['pgmoRating']['v_Value_PGMO__c'],
      Review_PGMO__c: this.ratingObject['pgmoRating']['v_Review_PGMO__c'],
    }
    const jobObj = {
      Quality_Rating__c: this.ratingObject['iccRating']['Quality__c'],
      Professionalism__c: this.ratingObject['iccRating']['Professionalism__c'],
      Communication__c: this.ratingObject['iccRating']['Communication__c'],
      Worker_Arrival_On_Time__c: this.ratingObject['iccRating']['Worker_Arrival_On_Time__c'],
      Overall_Rating__c: this.ratingObject['iccRating']['Value__c'],
      Deliverable_Accuracy__c: this.ratingObject['iccRating']['Review__c'],
    }
    if (this.timeCardData['Status__c'] === 'Approved') {
      siteVisitReportObj['PGMO_Submitted_DateTime__c'] = this.systemCurrentDate;
      siteVisitReportObj['Status__c'] = 'PGMO Submitted';
    }

    if (this.jobId && this.ratingObject['iccRating']['workerRatingId'] && this.ratingObject['pgmoRating']['vendorRatingId'] && this.ratingObject['siteVisitRating']['id']) {
      forkJoin(
        this._jobWorkerRatingApi.patchAttributes(this.ratingObject['iccRating']['workerRatingId'], jobWorkerRatingObj),
        this._vendorRatingApi.patchAttributes(this.ratingObject['pgmoRating']['vendorRatingId'], vendorRatingObj),
        this._siteVisitReportApi.patchAttributes(this.ratingObject['siteVisitRating']['id'], siteVisitReportObj),
        this._jobApi.patchAttributes(this.jobId, jobObj))
        .subscribe(([timeCardRes, jobWorkerRes, vendorRatingRes, siteVisitRes]) => {
          this._preloaderService.hidePreloader();
        }, err => {
          this._preloaderService.hidePreloader();
        });
    } else {
      this._preloaderService.hidePreloader();
    }
  }

  d(event){
    this.setTimeCardDataToSubmit();
  }
}
