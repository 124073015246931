import { OnInit, Component, EventEmitter, Input, Output, ChangeDetectorRef, ElementRef } from '@angular/core';
import { debounceTime, switchMap, filter } from 'rxjs/operators';
import { WorkerApi,MyWorkLeaveApprovalApi } from 'shared/sdk';
import { AppStateService } from 'shared/services/app-state.service';

@Component({
    selector: 'app-worker-lookup',
    templateUrl: './worker-lookup.component.html',
    styleUrls: ['./worker-lookup.component.css']
})

export class WorkerLookupComponent implements OnInit {
    workerList: any;
    workerTypeahead: EventEmitter<any> = new EventEmitter();
    workersLoading: boolean;
    noWorker: string;
    workersSelected: any;
    itemsPerBatch = 50;
    whereFilter: any;
    includeObj: any;
    accessType: any;
    commonWhereFilter: any;
    _userData

    @Input() set resetData(isClear) {
        this.workersSelected = [];
    }

    @Input() placeholder = 'Worker';
    @Input() typeToSearchText = 'Type to Search Worker';
    @Input() noDataFoundText = 'No worker found.';

    @Input() set setWorkerObj(vendorsite) {
        this.workerList = [];
        this.workersLoading = false;
        this.workersSelected = [];
        this.whereFilter = { ...((vendorsite && vendorsite.length) && { Vendorsite__c: vendorsite }) };
    }
    @Input() set setVendorAccountForWorkerObj(vendorAccount) {
        this.workerList = [];
        this.workersLoading = false;
        this.workersSelected = [];
        // this.includeObj = (vendorAccount && vendorAccount.length) ? {
        //     relation: 'user',
        //     scope: {
        //         fields: ['sfdcId', 'AccountId'],
        //         where: { AccountId: vendorAccount }
        //     }
        // } : undefined;
        this.includeObj = (vendorAccount && vendorAccount.length) ? {
            relation: 'contact',
            scope: {
                fields: ['sfdcId', 'AccountId'],
                where: { AccountId: vendorAccount }
            }
        } : undefined;
        if (vendorAccount && vendorAccount.length) {
            this.onWorkerChange();
        }
    }
    @Input() set selectWorker(e) {
        if (e) {
            this.workerList = [];
            this.workerList.push(e);
            this.workersSelected = e && e.sfdcId;
            this.workerList.map((worker: any) => {
                worker.Name = worker.Account_Name__c ?
                    `${worker.Name} (${worker.Account_Name__c}) - ${worker.Dispatch_Worker_num__c}` :
                    `${worker.Name} - ${worker.Dispatch_Worker_num__c}`;

            });

        }
    }
    @Input() from;
    @Input() selectLabel = 'label';
    @Input() selectbindValue = 'sfdcId';
    @Input() isMultiSelect = 'true';
    @Input() maxSelect = 20;
    @Input() isDisabled = false;
    @Output() getWorkerObj: EventEmitter<any> = new EventEmitter();
    @Input() boxedLayout = false;
    @Input() inGlobalFilters = true;
    @Input()
    set preselectedWorker(e) {
        if (e) {
            const whereObj = {
                sfdcId: e
            }
            this.setSelectedWorker(whereObj);
        }
    }
    isLoading: boolean;

    constructor(
        private _workerApi: WorkerApi,
        private _appStateService: AppStateService,
        private _cd: ChangeDetectorRef,
        private _myWorkLeaveApprovalApi : MyWorkLeaveApprovalApi
    ) { }

    // local storage variable
    ngOnInit() {
        if (this.inGlobalFilters) {
            // to show selected workers
            this.workersSelected = [];
            const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : {};
            if (this.selectLabel == 'Dispatch_Worker_num__c' || this.selectLabel == 'Name') {
                this.workersSelected = (preselected && preselected['workerIds']) ? preselected['workerIds'] : [];
            } else {
                this.workersSelected = (preselected && preselected['workerNames']) ? preselected['workerNames'] : [];
            }

            if (this.from === 'timeCardDetails') {
                if (this.selectWorker) {
                    this.workerList = [];
                    this.workerList.push(this.selectWorker);
                    this.workersSelected = this.selectWorker;
                }
            } else if (this.workersSelected.length) {
                this.whereFilter = { ...this.whereFilter, sfdcId: { inq: Array.isArray(this.workersSelected) ? this.workersSelected : [this.workersSelected] } };
                this.findWorkerApi().subscribe(
                    x => {
                        this.workerList = x;
                        this.workerList.map((worker: any) => {
                            worker.label = worker.Account_Name__c ?
                                `${worker.Name} (${worker.Account_Name__c}) - ${worker.Dispatch_Worker_num__c}` :
                                `${worker.Name} - ${worker.Dispatch_Worker_num__c}`;
                        });
                        this.noWorker = (this.workerList.length === 0) ? this.noDataFoundText : '';
                    },
                    err => {
                        console.log(err);
                        this.workerList = [];
                        this.noWorker = '';
                    }
                );
            }
            this.showVendorData();
        }
        this._userData = localStorage.getItem('appData') ? JSON.parse(localStorage.getItem('appData'))['user'] : null;

        this.getWorkers();
    }

    // get workers list on the basis of selected vendorsite
    getWorkers() {
        this.workersLoading = true;
        this.workerTypeahead
            .pipe(
                filter(search => search && search.length > 2),
                debounceTime(200),
                switchMap(search => {
                    const searchPattern = { ilike: '%' + search.trim() + '%', options: 'i' };
                    if (this.selectLabel == 'Dispatch_Worker_num__c' || this.selectLabel == 'Name') {
                        this.whereFilter = {
                            and: [
                                this.commonWhereFilter,
                                {
                                    or:
                                        [
                                            { Dispatch_Worker_num__c: searchPattern },
                                            { Name: searchPattern }
                                        ]
                                }
                            ]
                        };
                    } else {
                        this.whereFilter = { ...this.commonWhereFilter, Name: searchPattern };
                    }
                    this.isLoading = true;
                    if(this.from === 'leave-approval-list'){
                        return this.findApproverWorkerApi(search)
                    }else{
                        return this.findWorkerApi();
                    }
                    
                }
                )
            )
            .subscribe(
                x => {
                    this.isLoading = false;
                    if(this.from === 'leave-approval-list'){
                        this.workerList = x.data;
                        this.noWorker = (this.workerList.length === 0) ? this.noDataFoundText : '';
                    }else{
                        this.workerList = (this.includeObj && Object.keys(this.includeObj).length) ? x.filter((i: any) => i.contact) : x;
                        this.workerList.map((worker: any) => {
                            if (this.from === 'timeCardDetails') {
                                worker.Name = worker.Account_Name__c ?
                                    `${worker.Name} (${worker.Account_Name__c}) - ${worker.Dispatch_Worker_num__c}` :
                                    `${worker.Name} - ${worker.Dispatch_Worker_num__c}`;
                            }
                            worker.label = worker.Account_Name__c ?
                                `${worker.Name} (${worker.Account_Name__c}) - ${worker.Dispatch_Worker_num__c}` :
                                `${worker.Name} - ${worker.Dispatch_Worker_num__c}`;
                        });
    
                       // console.log(this.workerList)
                        this.noWorker = (this.workerList.length === 0) ? this.noDataFoundText : '';
                    }
                   

                },
                err => {
                    this.isLoading = false;
                    console.log(err);
                    this.workerList = [];
                    this.noWorker = '';
                }
            );
    }

    findWorkerApi() {
        return this._workerApi.find({
            where: this.whereFilter,
            include: this.includeObj,
            fields: ['Name', 'id', 'sfdcId', 'Contact__c', 'Account_Name__c', 'Dispatch_Worker_num__c'],
            // limit: this.itemsPerBatch
        })
    }


    findApproverWorkerApi(searchPattern) {
        const Leave_Approver__c = (this._userData && this._userData['workers'][0] &&  this._userData['workers'][0]['sfdcId']) ? this._userData['workers'][0]['sfdcId'] : '';
        const reqRecord = {
            Leave_Approver__c: Leave_Approver__c,
            searchPattern: searchPattern.trim(),
            };
    
       return this._myWorkLeaveApprovalApi.approverWorkerList(reqRecord)

        // this.includeObj =  {
        //     relation: 'approverWorker',
        //     scope: {
        //         fields: ['Name', 'id', 'sfdcId', 'Contact__c', 'Account_Name__c', 'Dispatch_Worker_num__c'],
        //         where: this.whereFilter,
        //     }
        // }
        // return this._myWorkLeaveApprovalApi.find({
        //     where: { Leave_Approver__c: 'a2y1a000006EI0gAAG' },
        //     include: this.includeObj,
        //     fields: ['sfdcId', 'Worker__c'],
        //     // limit: this.itemsPerBatch
        // })
    }

    onWorkerChange() {
        if(this.from==='sendSms'){
            let workOrderObj
            workOrderObj=this.workerList.filter(el=>el.sfdcId===this.workersSelected)
            this.getWorkerObj.emit(workOrderObj[0]);
        }else{
            this.getWorkerObj.emit(this.workersSelected);
        }
    }

    clearWorker() {
        this.getWorkerObj.emit([]);
    }

    /* to check access type and fetch data accordingly */
    showVendorData() {
        this.commonWhereFilter = {};
        this.commonWhereFilter['Available__c'] = 1;
        this.accessType = this._appStateService.getAccessType();
        if (this.accessType === 'vendor') {
            const appData = JSON.parse(localStorage.getItem('appData'));
            if (appData && appData.user && appData.user.account) {
                this.commonWhereFilter['Account_Name__c'] = appData.user.account.Name;
            }
        }
    }

    setSelectedWorker(whereObj) {
        const filterObj = {
            fields: ['Name', 'id', 'sfdcId', 'Contact__c', 'Account_Name__c', 'Dispatch_Worker_num__c'],
            where: whereObj,
            order: 'Name'
        }
        this._workerApi.find(filterObj).subscribe((workers) => {
            this._cd.markForCheck();
            this.workerList = workers;
            if (this.workerList.length && !this.isMultiSelect) {
                this.workersSelected = this.workerList[0]['sfdcId'];
            }
            //   this.noDataFoundText = (this.workerList.length === 0) ? 'No worker found.' : ''
        },
            err => {
                console.log(err);
                this.workerList = [];
                this.noDataFoundText = '';
            }
        );
    }

    ngAfterViewInit() {
        if (this.from === 'leave-approval-list') {
            const workerLookupInput: any = document.querySelector('#worker_lookup .ng-input');
            const workerLookupArrow: any = document.querySelector('#worker_lookup .ng-arrow-wrapper');

            if (workerLookupInput) {
                workerLookupInput.style.top = '0';
                workerLookupInput.style.width = '90%';
            }
            if (workerLookupArrow)
                workerLookupArrow.style.bottom = '3px';

        }
    }

    changeStyleClear() {
        const workerLookupClear: any = document.querySelector('#worker_lookup .ng-clear-wrapper');
        if (workerLookupClear)
            workerLookupClear.style.bottom = '0';
    }

}
