import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-resend-sms-notification-popup',
  templateUrl: './resend-sms-notification-popup.html',
  styleUrls: ['./resend-sms-notification-popup.css'],
})
export class ResendSMSNotificationPopupComponent implements OnInit {
    @Input() set _rowData(e) {
        this.rowdata = e;
    }
    rowdata;
    showMore = false;

    ngOnInit(): void {
    }
    
    changeShowMore(){
        this.showMore = !this.showMore;
    }
}