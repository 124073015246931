import { Component, AfterViewInit, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, filter, tap, catchError } from 'rxjs/operators';
import { JobApi, ServiceContractApi } from 'shared/sdk';


@Component({
    selector: 'app-service-contract-num-lookup',
    templateUrl: './service-contract-num-lookup.component.html',
    styleUrls: ['./service-contract-num-lookup.component.css']
})

export class ServiceContractNumLookupComponent implements OnInit {
    @Input() set resetData(isClear) {
        this.serviceContractNumbersObj = [];
    }
    @Input() isMultiSelect = true;
    @Output() getserviceContractNumbersObj: EventEmitter<any> = new EventEmitter();
    serviceContractNumbers: any;
    public searchInput = new EventEmitter<string>();
    itemsPerBatch = 50;
    notFoundText: string;
    orderBy = 'id DESC';
    serviceContractNumbersObj: any[];
    whereFilter: any;
    placeholder = 'Filter by Service Contract Number...';

    constructor(private _serviceContractApi: ServiceContractApi) { }

    ngOnInit() {
        const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
        this.serviceContractNumbersObj = (preselected && preselected['serviceContractNumbers']) ?
            preselected['serviceContractNumbers'] : [];
        if (this.serviceContractNumbersObj && this.serviceContractNumbersObj.length) {
            this.whereFilter = { Service_Contract__c: { inq: this.serviceContractNumbersObj } };
            this.loadServiceContractNums().subscribe(
                x => {
                    this.serviceContractNumbers = x;
                    this.notFoundText = (this.serviceContractNumbers.length === 0) ? 'No service contract number found.' : '';
                },
                err => {
                    this.serviceContractNumbers = [];
                }
            )
        }
        this.getserviceContractNumbers();
    }

    getserviceContractNumbers() {
        this.searchInput
            .pipe(
                filter(search => search && search.length > 5),
                debounceTime(200),
                distinctUntilChanged(),
                switchMap(search => this.loadServiceContractNums(search))
            ).subscribe(
                x => {
                    this.serviceContractNumbers = x;
                    this.notFoundText = (this.serviceContractNumbers.length === 0) ? 'No service contract number found.' : '';
                },
                err => {
                    console.log(err);
                    this.serviceContractNumbers = [];
                }

            );
    }

    loadServiceContractNums(search?) {
        if (search) {
            const searchPattern = { ilike: '%' + search.trim() + '%', options: 'i' };
            this.whereFilter = { ...this.whereFilter, Service_Contract_Number__c: searchPattern };
        }

        const reqObj = {
            'fields': ['sfdcId', 'Name', 'Service_Contract_Number__c'],
            'where': this.whereFilter,
            limit: this.itemsPerBatch,
            order: this.orderBy
        };
        return this._serviceContractApi.find(reqObj);
    }

    clearServiceContractNumbers() {
        this.serviceContractNumbersObj = [];
        this.notFoundText = 'Type to search service contract';
        this.getserviceContractNumbersObj.emit([]);
    }

    onServiceContractNumChange() {
        this.getserviceContractNumbersObj.emit(this.serviceContractNumbersObj);
    }
}
