import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { divisionList } from 'shared/models/static-list-data.service';

@Component({
  selector: 'app-exclude-magiclink-lookup',
  templateUrl: './exclude-magiclink-lookup.component.html',
  styleUrls: ['./exclude-magiclink-lookup.component.css']
})
export class ExcludeMagiclinkLookupComponent implements OnInit {
  @Input() from;
  @Input() set resetData(isClear) {
    if(isClear){
      this.clearFromParent();
    }
    
  }
  @Output() getMagicLinkObj: EventEmitter<any> = new EventEmitter;
  excludeMagicLinkAccount= false;
  
  constructor() { 
    this.excludeMagicLinkAccount = (this.from == 'cummulative') ? true : false;
  }

  ngOnInit() {
    const preSelected = localStorage.getItem('filterObj');
    if (preSelected && JSON.parse(preSelected) &&
      JSON.parse(preSelected)['excludeMagicLink'] && JSON.parse(preSelected)['excludeMagicLink'].length) {
      this.excludeMagicLinkAccount = JSON.parse(preSelected)['excludeMagicLink'];
    }
    if (preSelected && JSON.parse(preSelected) &&
      JSON.parse(preSelected)['cummulativeReport'] && JSON.parse(preSelected)['cummulativeReport'].length && this.from == 'cummulative') {
      this.excludeMagicLinkAccount = JSON.parse(preSelected)['cummulativeReport'];
    }
  }

  onChange() {
    this.getMagicLinkObj.emit(this.excludeMagicLinkAccount);
  }

  clearFromParent() {
   this.excludeMagicLinkAccount = false;
  }

}
