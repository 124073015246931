import { Component, AfterViewInit, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, filter, tap, catchError } from 'rxjs/operators';
import { JobApi } from 'shared/sdk';

@Component({
  selector: 'app-job-sfdcid-lookup',
  templateUrl: './job-sfdcid-lookup.component.html',
  styleUrls: ['./job-sfdcid-lookup.component.css']
})
export class JobSfdcidLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    // this.jobIds = [];
    this.jobIdObj = [];
  }
  @Input() isMultiSelect = true;
  @Input() maxSelect = 20;
  @Input() placeholder;
  @Input() selectbindValue: string = 'sfdcId';
  @Input() selectLabel: string = 'Iron_Job_num__c';
  @Input() from: string = 'sfdcId';
  @Input() set selectJob(job) {
    if (job) {
      this.jobIds = [];
      this.jobIds.push(job);
      this.jobIdObj = job.sfdcId;
    }
  }
  @Output() getJobIdsObj: EventEmitter<any> = new EventEmitter();
  jobIds: any;
  public searchInput = new EventEmitter<string>();
  itemsPerBatch = 20;
  notFoundText: string;
  orderBy = 'id DESC';
  jobIdObj: any;
  whereFilter: any;

  constructor(private _jobApi: JobApi) { }

  ngOnInit() {
    // to show statge status

    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.jobIdObj = (preselected && preselected['jobsfdcIds']) ?
      preselected['jobsfdcIds'] : [];
    const appData = JSON.parse(localStorage.getItem('appData'));
    if (this.jobIdObj && this.jobIdObj.length) {
      this.whereFilter = { ...this.whereFilter, sfdcId: { inq: this.jobIdObj } };
      this.loadJobIds().subscribe(
        x => {
          this.jobIds = x;
          this.notFoundText = (this.jobIds.length === 0) ? 'No job id found.' : '';
        },
        err => {
          console.log(err);
          this.jobIds = [];
        }
      );
    } else {
      this.placeholder = this.placeholder === undefined ? 'Filter by Job Id...' : this.placeholder;
    }
    this.getJobIds();
  }

  getJobIds() {
    this.searchInput
      .pipe(
        filter(search => search && search.length > 2),
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(search => this.loadJobIds(search))
      ).subscribe(
        x => {
          this.jobIds = x;
          this.notFoundText = (this.jobIds.length === 0) ? 'No job id found.' : '';
        },
        err => {
          console.log(err);
          this.jobIds = [];
        }

      );
  }

  loadJobIds(search?) {
    if (search) {
      const searchPattern = { ilike: '%' + search.trim() + '%', options: 'i' };
      if (this.selectLabel === 'Name') {
        this.whereFilter = { ...this.whereFilter, Name: searchPattern, sfdcId: undefined };
      } else {
        this.whereFilter = { ...this.whereFilter, Iron_Job_num__c: searchPattern, sfdcId: undefined };
      }

    }

    const reqObj = {
      'fields': ['sfdcId', 'id', 'Iron_Job_num__c', 'RecordTypeId', 'Vendor__c', 'Dispatch_Service_Resolution_Status__c',
        'Customer_Service_Type_From_Program__c', 'Service_Dispatch_SLA_Priority__c', 'Technical_Level__c',
        'CKSW_BASE__Account__c', 'Name'],
      'where': this.whereFilter,
      'include': [
        {
          'relation': 'vendor',
          'scope': {
            'fields': ['sfdcId', 'Name']
          }
        },
        {
          'relation': 'partner',
          'scope': {
            'fields': ['sfdcId', 'Name']
          }
        }
      ],
      limit: this.itemsPerBatch,
      order: this.orderBy
    };
    return this._jobApi.find(reqObj);
  }

  clearJobIds() {
    this.jobIdObj = [];
    this.notFoundText = 'Type to search job';
    this.getJobIdsObj.emit([]);
  }

  onJobIdChange() {
    this.getJobIdsObj.emit(this.jobIdObj);
  }

}
