import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';
import { AccountApi } from 'shared/sdk';


@Component({
  selector: 'app-contact-account-lookup',
  templateUrl: './contact-account-lookup.component.html',
  styleUrls: ['./contact-account-lookup.component.css']
})
export class ContactAccountLookupComponent implements OnInit {
  
  @Input() set resetData(isClear) {
      this.accountsSelected = [];
  }
  @Input() from;
  @Input() selectLabel = 'Name';
  @Input() selectbindValue = 'sfdcId';
  @Input() isMultiSelect = 'true';
  @Input() set selectContactAccount(e) {
    if (e) {
        this.accountList = [];
        this.accountList.push(e);
        this.accountsSelected = e && e.sfdcId;
    }
}
@Input() set preSelected(e){
    this.accountsSelected = e;
}

@Input() isDisabled = false;
@Output() getContactAccountObj: EventEmitter<any> = new EventEmitter;

// vendor account

noAccount = 'Searching...';
accountsTypeahead = new EventEmitter<string>();
accounts: Array<any>;
accountList = [];
accountsSelected = [];

@Input() inGlobalFilter = true;

  constructor(
    private _cd: ChangeDetectorRef,
    private _accountApi: AccountApi
  ) { }

  ngOnInit() {
    this.getContactAccount();
    // to show selected vendor account
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    if (this.inGlobalFilter) {
        this.accountsSelected = (preselected && preselected['contactAccounts']) ? preselected['contactAccounts'] : null;
    }
    
    if (this.accountsSelected && this.accountsSelected.length) {
      this.getContactAccountList({ sfdcId: { inq: this.accountsSelected instanceof Array ? this.accountsSelected : [this.accountsSelected] } }).subscribe(
          x => {
              this.accountList = x;
              this.noAccount = (this.accountList.length === 0) ? 'No account found.' : '';
          },
          err => {
              console.log(err);
              this.accountList = [];
              this.noAccount = '';
          }
      );
    }
  }
   // get vendor accounts
   getContactAccount() {
    //  this.accountList = [];
      this.accountsTypeahead.pipe(
          filter(term => term && term.length > 2),
          distinctUntilChanged(),
          debounceTime(200),
          switchMap(term => {
              const searchPattern = { ilike: '%' + term.trim() + '%', options: 'i' };
              const whereObj = {
                  Name: searchPattern
              };
              return this.getContactAccountList(whereObj);
          })
      ).subscribe(
          x => {
              this.accountList = x;
              this.noAccount = (this.accountList.length === 0) ? 'No account found.' : '';
          },
          err => {
              console.log(err);
              this.accountList = [];
              this.noAccount = '';
          }
      );
  }
  onVendorAccountChange() {
      this.getContactAccountObj.emit(this.accountsSelected);
  }

  /**
   * 
   * @param whereObj 
   */

   getContactAccountList(whereObj) {
      return this._accountApi.find({
          where: whereObj,
          fields: ['Name', 'id', 'sfdcId'],
          order: 'CreatedDate DESC',
      }).map(res => res);

  }

  clear() {
    this.accountList = [];
    this.accountsSelected = [];
    this.getContactAccountObj.emit(null);
    }

}
