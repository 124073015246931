import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';
import { CaseApi, JobApi } from 'shared/sdk';
import { CommonService } from 'shared/services/common.service';
@Component({
  selector: 'app-pmc-case-num-lookup',
  templateUrl: './pmc-case-num-lookup.component.html',
  styleUrls: ['./pmc-case-num-lookup.component.css']
})
export class PmcCaseNumLookupComponent implements OnInit {
  @Input() set resetData(isClear) {
    // this.pmcCaseIds = [];
    this.pmcCaseObj = [];
  }
  @Input() placeholder;
  @Output() getPMCCaseIdsObj: EventEmitter<any> = new EventEmitter();

  pmcCaseObj: any
  pmcCaseIds: any

  public searchInput = new EventEmitter<string>();
  itemsPerBatch = 50;
  notFoundText: string;
  orderBy = 'id DESC';
  whereFilter: any;

  constructor(
    private _caseApi: CaseApi,
    private _commonService: CommonService) {
  }

  ngOnInit() {
    this.placeholder = this.placeholder === undefined ? 'Filter by PMC Case Number' : this.placeholder;
    this.notFoundText = 'PMC Case Number Not Found';
    this.getPMCCaseIds();
    const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
    this.pmcCaseObj = (preselected && preselected['pmcCaseNumbers']) ? preselected['pmcCaseNumbers'] : '';
    if (this.pmcCaseObj && this.pmcCaseObj.length) {
      const filters = {
        where: { sfdcId: { inq: this.pmcCaseObj } },
        fields: ['sfdcId', 'Partner_Case_Number__c', 'ParentId', 'CaseNumber', 'Iron_Case_Number__c'],
        order: 'createdAt desc',
        skip: 0,
        limit: 100
      }
      this._caseApi.find(filters).subscribe(
        x => {
          this.pmcCaseIds = x;
          this.notFoundText = (this.pmcCaseIds.length === 0) ? 'PMC Case Number found.' : '';
        });
    }
  }

  getPMCCaseIds() {
    this.searchInput
      .pipe(
        filter(search => search && search.length > 2),
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(search => this.loadPMCCaseIds(search))
      ).subscribe(
        x => {
          this.pmcCaseIds = x;
          this.notFoundText = (this.pmcCaseIds.length === 0) ? 'PMC Case Number not found.' : '';
        },
        err => {
          console.log(err);
          this.pmcCaseIds = [];
        }

      );
  }

  loadPMCCaseIds(search) {
    if (this._commonService.pmcAllRecordType) {
      const filters = {
        where: {
          RecordTypeId: { inq: this._commonService.pmcAllRecordType },
          CaseNumber: { ilike: '%' + search.trim() + '%', options: 'i' }
        },
        fields: ['sfdcId', 'Partner_Case_Number__c', 'ParentId', 'CaseNumber', 'Iron_Case_Number__c'],
        skip: 0,
        limit: 100
      }
      return this._caseApi.find(filters);
    } else {
      return [];
    }
  }

  clearPMCCaseIds() {
    this.pmcCaseObj = [];
    this.notFoundText = 'Type to search PMC Case Number';
    this.getPMCCaseIdsObj.emit([]);
  }

  onPMCCaseIdChange() {
    this.getPMCCaseIdsObj.emit(this.pmcCaseObj);
  }
}
