import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';

import { AccountApi } from '../../../sdk';

@Component({
    selector: 'app-vendor-account-lookup',
    templateUrl: './vendor-account-lookup.component.html',
    styleUrls: ['./vendor-account-lookup.component.scss']
})
export class VendorAccountLookupComponent implements OnInit {
    @Input() boxedLayout = false;
    @Input() set resetData(isClear) {
        this.accountsSelected = [];
    }
    @Input() from;
    @Input() selectLabel = 'Name';
    @Input() selectbindValue = 'sfdcId';
    @Input() isMultiSelect = 'true';
    @Input() set selectVendor(e) {
        if (e) {
            this.accountList = [];
            this.accountList.push(e);
            this.accountsSelected = e && e.sfdcId;
        }
    }
    @Input() set preSelected(e){
        if (e && this.from === 'caseDetailPage') {
            this.accountsSelected = e;
        } else if (e) {
            this.accountsSelected = e
            this.onVendorAccountChange();
            if(this.from == 'editMVVP'){
                this.ngOnInit();
            }
        }
    }
    @Input() isDisabled = false;
    @Output() getVendorAccountObj: EventEmitter<any> = new EventEmitter;

    // vendor account

    noAccount = 'Searching...';
    accountsTypeahead = new EventEmitter<string>();
    accounts: Array<any>;
    accountList = [];
    accountsSelected = [];

    @Input() inGlobalFilter = true;

    @Input() set setValueInEditMode(sfdcId) {
        if(sfdcId) {
            this.accountsSelected = sfdcId;
            //this.loadJobsites({ sfdcId: { inq: [this.accountsSelected] }, Jobsite_Status__c: 'Active' });
            this.getVendorAccountList({ sfdcId: { inq: this.accountsSelected instanceof Array ? this.accountsSelected : [this.accountsSelected] }, RecordTypeId: '0121a000000QaUYAA0' }).subscribe(
                x => {
                    this.accountList = x;
                    this.noAccount = (this.accountList.length === 0) ? 'No account found.' : '';
                },
                err => {
                    console.log(err);
                    this.accountList = [];
                    this.noAccount = '';
                }
            );
        }
    }

    constructor(
        private _cd: ChangeDetectorRef,
        private _accountApi: AccountApi
    ) {

    }

    ngOnInit() {
        this.getVendorAccounts();
        // to show selected vendor account
        const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
        if (this.inGlobalFilter) {
            this.accountsSelected = (preselected && preselected['vendorAccounts']) ? preselected['vendorAccounts'] : null;
        }
        if (this.from === 'timeCardDetails') {
            if (this.selectVendor) {
                this.accountList = [];
                this.accountList.push(this.selectVendor);
                this.accountsSelected = this.selectVendor && this.selectVendor.Name;
            }
        } else if (this.accountsSelected && this.accountsSelected.length) {
            let whereObj = { sfdcId: { inq: this.accountsSelected instanceof Array ? this.accountsSelected : [this.accountsSelected] }, RecordTypeId: '0121a000000QaUYAA0'};
            // if(this.from === 'ApplicationOverview'){
            //     delete whereObj['RecordTypeId']
            // }
            this.getVendorAccountList(whereObj).subscribe(
                x => {
                    this.accountList = x;
                    this.noAccount = (this.accountList.length === 0) ? 'No account found.' : '';
                },
                err => {
                    console.log(err);
                    this.accountList = [];
                    this.noAccount = '';
                }
            );
        }
    }

    // get vendor accounts
    getVendorAccounts() {
      //  this.accountList = [];
        this.accountsTypeahead.pipe(
            filter(term => term && term.length > 2),
            distinctUntilChanged(),
            debounceTime(200),
            switchMap(term => {
                const searchPattern = { ilike: '%' + term.trim() + '%', options: 'i' };
                const whereObj = {
                    Name: searchPattern,
                    RecordTypeId: '0121a000000QaUYAA0'
                };
                return this.getVendorAccountList(whereObj);
            })
        ).subscribe(
            x => {
                this.accountList = x;
                this.noAccount = (this.accountList.length === 0) ? 'No account found.' : '';
            },
            err => {
                console.log(err);
                this.accountList = [];
                this.noAccount = '';
            }
        );
    }
    onVendorAccountChange() {
        this.getVendorAccountObj.emit(this.accountsSelected);
    }

    clearPrograms() {
        this.accountList = [];
        this.accountsSelected = [];
        this.getVendorAccountObj.emit(null);
    }

    /**
     * 
     * @param whereObj 
     */

    getVendorAccountList(whereObj) {
        if(this.from == 'educationHistory'){
            delete whereObj['RecordTypeId'];
        }
        return this._accountApi.find({
            where: whereObj,
            fields: ['Name', 'id', 'sfdcId'],
            order: 'CreatedDate DESC',
        }).map(res => res);

    }
}