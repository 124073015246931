import { ValueList } from "shared/models/common";

export const TalentOverviewApiFieldList = [
    'Dispatch_Worker_num__c',
    'Work_Phone_Number__c',
    'Mobile_Phone_Number__c',
    'Email__c',
    'Contact__c',
    'Job_Rating_Count__c',
    'Satisfaction_Rating_Average__c',
    'Deliverables_Rating_Average__c',
    'Quality_Rating_Average__c',
    'Professionalism_Rating_Average__c',
    'On_Time_Rating_Average__c',
    'Communication_Rating_Average__c',
    'Vendorsite__c',
    'Primary_Worker_Skilling_Profile__c',
    'Account_Name__c',
    'Enable_Send_SMS__c',
    'Worker_Type__c',
    'Worker_Hiring_Type__c',
    'sfdcId',
    'Additional_Coverage_Cities__c',
    'GEO_Code__c',
    'ISG_Email_Address__c',
    'Candidate__c',
    'Worker_Employer_Contract__c',
    'Worker_NDA_Status__c',
    'Payment_Term__c',
    'RecordTypeId',
    'Vaccination_Status__c',
    'id',
    'Blacklisted__c'
];

// account, skilling, source
export const TalentOverviewIncludeList = [
    {
        relation: 'contact',
        scope: {
            fields: ['sfdcId', 'Name', 'AccountId', 'FirstName', 'LastName', 'User_Id__c', 'People_Status__c', 'Contact_Method__c', 'Email', 'Fax', 'MailingStreet', 'MailingCity', 'MailingState', 'MailingPostalCode', 'MailingCountry', 'MyHomeMetro_Code__c', 'Primary_Worker_Skilling_Profile__c','Enable_App_Login__c','Gender__c','User_Type__c','MailingCountry_Code__c'],
            include: [
                {
                    relation: 'geometro',
                    scope: {
                        fields: ['Name']
                    }
                },
                {
                    relation: 'account',
                    scope: {
                        fields: ['sfdcId', 'Name']
                    }
                },
                {
                    relation: 'skillings',
                    scope: {
                        fields: ['Resource__c', 'sfdcId', 'Name'],
                        include: [
                            {
                                relation: 'talentInformations',
                                scope: {
                                    fields: ['Worker_Skilling_Profile__c', 'IsDeleted', 'Talent_Type__c',
                                        'Talent_Level__c', 'SLA__c', 'Coverage_Hours__c', 'sfdcId', 'id','Vaccination_Status__c'],
                                    include: {
                                        relation: 'talentType',
                                        scope: {
                                            fields: ['Name', 'sfdcId']
                                        } 
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    relation: 'account',
                    scope: {
                        fields: ['sfdcId', 'Name', 'RecordTypeId']
                    }
                }
            ]
        }
    },
    {
        relation: 'vendorsite',
        scope: {
            fields: [
                'Name', 'Notes__c', 'Primary_Vendorsite__c', 'GEO_Metro__c'
            ],
            include: [
                {
                    relation: 'GeoMetro',
                    scope: {
                        fields: ['Name']
                    }
                }
            ]
        }
    },
    {
        relation: 'workerVendorsite',
        scope: {
            fields: [
                'Vendorsite__c'
            ],
            include: [
                {
                    relation: 'vendorsite',
                    scope: {
                        fields: [
                            'Name', 'Notes__c', 'Primary_Vendorsite__c', 'GEO_Metro__c'
                        ],
                        include: [
                            {
                                relation: 'GeoMetro',
                                scope: {
                                    fields: ['Name']
                                }
                            }
                        ]
                    }
                },
            ]
        }
    },
    {
        relation: 'skilling',
        scope: {
            fields: [
                'Primary_Talent_Type__c', 'Status__c', 'Worker_BGV_check_Status__c', 'Worker_BGV_Report_date__c', 'Worker_Drug_Test_Status__c', 'Worker_Drug_Test_Report_Date__c', 'Languages__c', 'other_languages__c', 'Iron_Approved__c', 'id','Vaccination_Status__c'
            ],
            include: [
                {
                    relation: 'talentType',
                    scope: {
                        fields: ['Name', 'Talent_Type_Name__c']
                    }
                }
            ]
        }
    },
    {
        relation: 'candidateContact',
        scope:{
            fields: [
                'sfdcId', 'Name', 'FirstName', 'LastName',  'id'
            ],
        }
    }
];

export const ContantMethodList: ValueList[] = [
    { value: 'Phone', label: 'Phone' },
    { value: 'Mobile', label: 'Mobile' },
    { value: 'Email', label: 'Email' },
];

export const WorkerTypeList: ValueList[] = [
    { value: 'Worker - Dispatch FE (Primary)', label: 'Worker - Dispatch FE (Primary)' },
    { value: 'Worker - Dispatch FE (Secondary 1)', label: 'Worker - Dispatch FE (Secondary 1)' },
    { value: 'Worker - Dispatch FE (Secondary 2)', label: 'Worker - Dispatch FE (Secondary 2)' },
    { value: 'Worker-  Dispatch FE (Secondary 3)', label: 'Worker-  Dispatch FE (Secondary 3)' },
    { value: 'Worker - Dispatch FE New', label: 'Worker - Dispatch FE New' },
    { value: 'FTE (With Backfill)', label: 'FTE (With Backfill)' },
    { value: 'FTE (Without Backfill)', label: 'FTE (Without Backfill)' },
    { value: 'FTE (Custom Backfill)', label: 'FTE (Custom Backfill)' },
    { value: 'PMS User', label: 'PMS User' },
    { value: 'MSP User', label: 'MSP User' },
    { value: 'VMS User', label: 'VMS User' }
];

export const workerDispatchTypeList = [
    { value: 'Worker - Dispatch FE (Primary)', label: 'Worker - Dispatch FE (Primary)' },
    { value: 'Worker - Dispatch FE (Secondary 1)', label: 'Worker - Dispatch FE (Secondary 1)' },
    { value: 'Worker - Dispatch FE (Secondary 2)', label: 'Worker - Dispatch FE (Secondary 2)' },
    { value: 'Worker-  Dispatch FE (Secondary 3)', label: 'Worker-  Dispatch FE (Secondary 3)' },
    { value: 'Worker - Dispatch FE New', label: 'Worker - Dispatch FE New' },
    { value: 'VMS User', label: 'VMS User' }
  ];
  
  export const workerDedicatedTypeList = [
    { value: 'FTE (With Backfill)', label: 'FTE (With Backfill)' },
    { value: 'FTE (Without Backfill)', label: 'FTE (Without Backfill)' },
    { value: 'FTE (Custom Backfill)', label: 'FTE (Custom Backfill)' },
    // { value: 'PMS User', label: 'PMS User' }, to fix #22722
    { value: 'VMS User', label: 'VMS User' }
  ];

export const WorkerHiringTypeList: ValueList[] = [
    { value: 'Rehire', label: 'Rehire' },
    { value: 'Rebadged', label: 'Rebadged' },
    { value: 'New Hire', label: 'New Hire' }
];

export const WorkerRecordTypeList: ValueList[] = [
    { value: '0121a0000006Qj7AAE', label: 'Dedicated FTE' },
    { value: '0121a0000006Qj2AAE', label: 'Dispatch FE ' },
];

export const GenderList: ValueList[] = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
];

export const UserTypeList = [
    { value: 'MSP (Limited Access)', label: 'MSP (Limited Access)' },
    { value: 'Standard', label: 'Standard' },
  ];

export const ModalMapObject = {
    worker: {
        Account_Name__c: '',
        Mobile_Phone_Number__c: '',
        Work_Phone_Number__c: '',
        Enable_Send_SMS__c: '',
        Email__c: '',
        Worker_Type__c: '',
        Worker_City__c: '',
        State_Code__c: '',
        Country_Code__c: '',
        Worker_NDA_Status__c:'',
        Worker_Employer_Contract__c: '',
        Payment_Term__c: '',
        Worker_Hiring_Type__c: '',
        RecordTypeId: ''
    },
    contact: {
        AccountId: '',
        FirstName: '',
        LastName: '',
        Email: '',
        Fax: '',
        MailingCity: '',
        MailingStreet: '',
        MailingPostalCode: '',
        Contact_Method__c: '',
        MailingCountry: '',
        MailingState: '',
        Enable_App_Login__c: '',
        Gender__c: '',
        User_Type__c: '',
        MailingCountry_Code__c : ''
    },
    skilling: {
        other_languages__c: '',
        Languages__c: '',
        Iron_Approved__c: '',
        Worker_BGV_check_Status__c: '',
        Worker_SOW_Status__c: '',
        Vendor_SOW_Status__c: '',
        Vendor_MSA_Status__c: '',
        Vaccination_Status__c:''
    }
};

export const statusList = [
    {
      value: 'Signed',
      label: 'Signed'
    },
    {
      value: 'Not Signed',
      label: 'Not Signed'
    }
  ];
 export const bgvApprovalStatusList = [
    {
      value: 'Initiated',
      label: 'Initiated'
    },
    {
      value: 'Pending Verification',
      label: 'Pending Verification'
    },
    {
      value: 'Verified',
      label: 'Verified'
    }
  ];
  export const vaccinationStatusList = [
    {
      value: 'Yes',
      label: 'Yes'
    },
    {
      value: 'No',
      label: 'No'
    },
    {
      value: 'Unknown',
      label: 'Unknown'
    }
  ];

  
