/* tslint:disable */
import {
  Worker,
  CustomerCsat
} from '../index';

declare var Object: any;
export interface SurveyAnalyticsInterface {
  "workerSfdcId"?: string;
  "wlmsSurveySfdcId"?: string;
  "popupDate"?: Date;
  "surveyLinkClickedDate"?: Date;
  "closeButtonClickedDate"?: Date;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  worker?: Worker;
  alert?: CustomerCsat;
}

export class SurveyAnalytics implements SurveyAnalyticsInterface {
  "workerSfdcId": string;
  "wlmsSurveySfdcId": string;
  "popupDate": Date;
  "surveyLinkClickedDate": Date;
  "closeButtonClickedDate": Date;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  worker: Worker;
  alert: CustomerCsat;
  constructor(data?: SurveyAnalyticsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `SurveyAnalytics`.
   */
  public static getModelName() {
    return "SurveyAnalytics";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of SurveyAnalytics for dynamic purposes.
  **/
  public static factory(data: SurveyAnalyticsInterface): SurveyAnalytics{
    return new SurveyAnalytics(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'SurveyAnalytics',
      plural: 'SurveyAnalytics',
      path: 'SurveyAnalytics',
      idName: 'id',
      properties: {
        "workerSfdcId": {
          name: 'workerSfdcId',
          type: 'string'
        },
        "wlmsSurveySfdcId": {
          name: 'wlmsSurveySfdcId',
          type: 'string'
        },
        "popupDate": {
          name: 'popupDate',
          type: 'Date'
        },
        "surveyLinkClickedDate": {
          name: 'surveyLinkClickedDate',
          type: 'Date'
        },
        "closeButtonClickedDate": {
          name: 'closeButtonClickedDate',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        worker: {
          name: 'worker',
          type: 'Worker',
          model: 'Worker',
          relationType: 'belongsTo',
                  keyFrom: 'workerSfdcId',
          keyTo: 'sfdcId'
        },
        alert: {
          name: 'alert',
          type: 'CustomerCsat',
          model: 'CustomerCsat',
          relationType: 'belongsTo',
                  keyFrom: 'wlmsSurveySfdcId',
          keyTo: 'sfdcId'
        },
      }
    }
  }
}
