import { Component, OnInit, Input } from '@angular/core';
import { ContactApi } from 'shared/sdk';

@Component({
  selector: 'app-jobsite-contact',
  templateUrl: './jobsite-contact.component.html',
  styleUrls: ['./jobsite-contact.component.css']
})
export class JobsiteContactComponent implements OnInit {

  jobsiteContactData: any = {};
  @Input()
  set data(e) {
    if (e && Object.keys(e).length) {
      this.jobsiteContactData = e;
    }
  }

  @Input() receiptTabFields = false;
  lastModifiedByContact:any;

  constructor(
    private _contactApi:ContactApi
  ) { }


  ngOnInit() {
    this.getLastModifiedBy();
  }

  getLastModifiedBy(){
   if(this.jobsiteContactData && this.jobsiteContactData.Job && this.jobsiteContactData.Job.LastModifiedBy__c){
    this._contactApi.find({
      where: {
        sfdcId : this.jobsiteContactData.Job.LastModifiedBy__c
      },
      fields: ['sfdcId', 'Name'],
    }).subscribe(contact => {
      let res = contact[0] ? contact[0] : '';
     this.lastModifiedByContact = res['Name']
    });

  } else {
    this.lastModifiedByContact ='';
  }
}

}
