import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LocationApi, TalentTypeApi } from 'shared/sdk';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-parent-location-lookup',
  templateUrl: './parent-location-lookup.component.html',
  styleUrls: ['./parent-location-lookup.component.css']
})
export class ParentLocationLookupComponent implements OnInit {
  @Input() boxedLayout = false;
  @Input() set resetData(isClear) {
    if (isClear) {
      this.parentLocationSelected = [];
    }
  }
  locationTypeAhead: EventEmitter<any> = new EventEmitter();
  noLocation: string;
  parentLocationList: any[] = [];
  parentLocationSelected = [];
  @Input() placeholder = 'Search Parent Location';
  @Input() isdisabled = false;
  @Input() isMultipleSelected = false;
  @Input() bindValue = 'sfdcIfd';
  @Input() bindLabel = 'Name';

  @Output() getParentLocation: EventEmitter<any> = new EventEmitter;
  // locationTypeAhead = new EventEmitter<string>();
  @Input() set preselected(e) {
    if (e) {
      this.parentLocationSelected = e;
      let reqobj = {
        fields: ['sfdcId','id','Name'],
        where: { sfdcId: this.parentLocationSelected }
      }
      this._locationApi.find(reqobj).subscribe(result => {
        if (result && result.length) {
          this.parentLocationList = result;
          console.log('Parent locations', this.parentLocationList)
          this.noLocation = (this.parentLocationList.length === 0) ? 'No Location found.' : '';
        }
      });
    }
  }
  @Input() inGlobalFilter = true;
  // noLocation = 'No Location Found.'
  constructor(
    private _talentTypeApi: TalentTypeApi,
    private _locationApi: LocationApi,
  ) { }

  ngOnInit() {
    if(this.inGlobalFilter && (!this.parentLocationSelected || !this.parentLocationSelected.length)){
      const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
      this.parentLocationSelected = (preselected && preselected['parentLocations']) ? preselected['parentLocations'] : [];
    }
    this.getParentLocations();
  }

  getParentLocations() {
    this.locationTypeAhead
      .pipe(
        filter(search => search && search.length > 2),
        debounceTime(200),
        switchMap(search => {
          const searchPattern = { ilike: '%' + search.trim() + '%', options: 'i' };
          return this.findParentLocation(searchPattern);
        })
      ).subscribe(
        data => {
          if (data && data.length) {
            this.parentLocationList = data;
          } else {
            this.parentLocationList = [];
            this.noLocation = 'No parent location found.';
          }
        },
        err => {
          console.log('Error fetching talent types', err.message);
        }
      );
  }

  findParentLocation(searchText) {
    let whereObj;
      whereObj = { Name: searchText };
    return this._locationApi.find({
      where: whereObj,
      fields: ['sfdcId','id','Name']
    })
  }

  onParentLocationChange() {
    this.getParentLocation.emit(this.parentLocationSelected);
  }

  clearParentLocation() {
    this.parentLocationSelected = [];
    this.getParentLocation.emit([]);
  }

}
