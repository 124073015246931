import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResendSMSNotificationPopupComponent } from './resend-sms-notification-popup.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [ResendSMSNotificationPopupComponent],
  exports: [ResendSMSNotificationPopupComponent],
})
export class ResendSMSNotificationPopupModule { }
