import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ContactApi, JobOrderApi } from './../../sdk';
import { ModalService } from './../../services/modal.service';
import { PreloaderService } from 'shared/services/preloader.service';
import { environment } from 'environments/environment';
import { CommonService } from 'shared/services/common.service';

@Component({
  selector: 'app-candidate-type',
  templateUrl: './candidate-type.component.html',
  styleUrls: ['./candidate-type.component.css']
})
export class CandidateTypeComponent implements OnInit {
  @Input() existingCandidate = [];
  @Input () showExisting = false;
  @Input() isMultiSelect: Boolean = true;
  @Input() viewType: string;
  @Input() modelName: string;
  @Output() setCandidateArr: EventEmitter<Object> = new EventEmitter<Object>();
  whereCondition: any;
  recordTypeId: any;
  txtCandidateName: any;
  selectedCharCode = 'A';
  charCodesObj: any;
  checkedCandidateId = [];
  checkedCandidateName = [];
  checkedAccountCurrency = [];
  loadingIndicator = false;
  tableData: any;
  itemsPerPage = 10;
  isLoadMore = false;
  ngxOffset = 0;
  itemsPerBatch = 200;
  order = 'createdAt DESC';
  noRecords: any;
  fetchleassIds :string='';
  totalCount: number;
  selectedType = 'Name';
  checkedJobBoardName = [];

  constructor(
    private _contactApi: ContactApi,
    private _modalService: ModalService,
    private _preloaderService: PreloaderService,
    private _jobOrderApi: JobOrderApi,
    private _commonService: CommonService
  ) {
    this.whereCondition = { Name: { ilike: this.selectedCharCode + '%', options: 'i' } }
  }

  ngOnInit() {
    this.getAtoZCharCodes();
    this.getCandidateList(0);
  }

  getAtoZCharCodes() {
    this.charCodesObj = [];
    for (let i = 65; i <= 90; i++) {
      this.charCodesObj.push({ name: String.fromCharCode(i) });
    }
  }

  getCandidateList(offset) {
    this._preloaderService.showPreloader();
    this.whereCondition['RecordTypeId'] = environment.candidateRecordTypeId;
    if(this.existingCandidate.length > 0){
      this.whereCondition['sfdcId'] = {nin:this.existingCandidate};
      this.whereCondition['Email'] = { neq: null };
    }
    //32341
    if(this.selectedType && this.txtCandidateName){
      if(this.selectedType==='Name'){
        this.whereCondition['Name'] = { ilike: this.txtCandidateName.trim() + '%', options: 'i' };
        delete this.whereCondition['Email'];
      }else{
        this.whereCondition['Email'] = { ilike: this.txtCandidateName.trim() + '%', options: 'i' };
        delete this.whereCondition['Name'];
      }
    }
    const obj = {
      fields: ['id', 'sfdcId', 'Name','Email', 'User_Id__c', 'MobilePhone', 'Contact_Name__c', 'Contact_Type__c', 'FirstName', 'LastName', 'RecordTypeId', 'Candidate_Source__c','DoNotCall'],
      limit: this.itemsPerBatch,
      skip: offset,
      where: this.whereCondition
    }
    //apply job show esixting candidate logic
    if (this.existingCandidate.length > 0 && this.showExisting) {
      delete this.whereCondition['RecordTypeId'];
      delete this.whereCondition['sfdcId'];
      obj['where']['Agency__c'] = this.existingCandidate[0]['Agency__c'];
      obj['where']['Job__c'] = this.existingCandidate[0]['Job__c'];
      obj['where']['type'] = 'vendorCandidateList';
      if (this.isLoadMore) {
        obj['where']['fetchleassIds'] = this.fetchleassIds;
      }
      this._jobOrderApi.getJobOrderRelatedCandidate(obj).subscribe(res => {
        const results = res && res.data ? res.data : [];
        this.noRecords = (results.length < this.itemsPerBatch) ? true : false;
        this.totalCount = ((res.count) ? res.count : 0);
        this.fetchleassIds = (results.length) ? results[results.length - 1]['id'] : '';
        if (!this.isLoadMore) {
          this.tableData = results;
        } else {
          results.forEach(c => {
            this.tableData.push(c);
          });
          this.tableData = [...this.tableData];
        }
        this.loadingIndicator = false;
        this._preloaderService.hidePreloader();
      },
        error => {
          this.loadingIndicator = false;
          this._preloaderService.hidePreloader();
        }
      );
    } else {
      if (this.modelName && this.modelName === 'jobOrder') {
        obj['order'] = 'User_Id__c DESC';
        obj['include'] = [
          {
            relation: 'jobBoardSource',
            scope: {
              fields: ['sfdcId', 'Name']
            }
          },
          {
            relation: 'resume',
            scope: {
              fields: ['sfdcId', 'createdAt'],
              order:'createdAt DESC'
            }
          }
        ]
      }
      console.log('existingCandidate',obj)
      this._contactApi.find(obj).subscribe(results => {
        this.noRecords = (results.length < this.itemsPerBatch) ? true : false;
        if (!this.isLoadMore) {
          this.tableData = results;
        } else {
          results.forEach(c => {
            this.tableData.push(c);
          });
          this.tableData = [...this.tableData];
        }
        this.loadingIndicator = false;
        this._preloaderService.hidePreloader();
      },
        error => {
          this.loadingIndicator = false;
          this._preloaderService.hidePreloader();
        }
      )
    }
  }

  resetBtn() {
    this.selectedCharCode = 'A';
    this.txtCandidateName = '';
    this.checkedCandidateId = [];
    this.checkedCandidateName = [];
    this.whereCondition = {
      Name: { ilike: this.selectedCharCode + '%', options: 'i' },
    }
    this.isLoadMore = false;
    this.getCandidateList(0);
  }

  onFilterChanged(e) {
    if (e !== 'ddl' && this.txtCandidateName) {
      this.selectedCharCode = this.txtCandidateName.charAt(0).toUpperCase();
      this.whereCondition = { Name: { ilike: this.txtCandidateName + '%', options: 'i' } }
    } else {
      this.whereCondition = { Name: { ilike: this.selectedCharCode + '%', options: 'i' } }
    }
    this.isLoadMore = false;
    this.getCandidateList(0);
  }

  updateChecked(option, event) {
    if(option.DoNotCall && option.DoNotCall === 'ENABLEDNC'){
      return false
    }
    const index = this.checkedCandidateId.indexOf(option.sfdcId);
    if (event.target.checked) {
      if (index === -1) {
        this.checkedCandidateId.push(option.sfdcId);
        this.checkedCandidateName.push(option.Name);
        this.checkedJobBoardName.push(option.sfdcId+'_'+option.jobBoardSource.sfdcId);
      }
    } else {
      if (index !== -1) {
        this.checkedCandidateId.splice(index, 1);
        this.checkedCandidateName.push(index, 1);
        this.checkedJobBoardName.push(index, 1);
      }
    }
  }

  selectCandidateId(val, dontCloseModal?) {
    this.checkedCandidateId = [val.sfdcId];
    this.checkedCandidateName = [val.Name];
    this.checkedJobBoardName = (val.jobBoardSource && val.jobBoardSource.sfdcId) ? [val.jobBoardSource.sfdcId] : [];
    this.notifyCandidateValues(dontCloseModal);
  }

  notifyCandidateValues(dontCloseModal?) {
    if (this.checkedCandidateId) {
      this.setCandidateArr.emit({ sfdcId: this.checkedCandidateId, Name: this.checkedCandidateName,jobBoardSfdcID:this.checkedJobBoardName });
      if (!dontCloseModal) {
        this._modalService.closed();
      }
    }
  }

  /*Data Table funcation start here*/
  loadMoreRecords() {
    this.isLoadMore = true;
    this.getCandidateList(this.tableData.length);
  }
  page(e) {
    if (e) {
      this.ngxOffset = e.offset;
    }
  }

  getRowClass(row){
    return {
      'dnc-disabled-row': (row.DoNotCall && row.DoNotCall === 'ENABLEDNC') ? true : false
    }    
  }
  onTypeChanged(e) {
    if (e.target && e.target.value) {
      this.selectedType = e.target.value;
      this.txtCandidateName = '';
    }
  }

}
