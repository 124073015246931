import { Project2Api } from './../../../sdk/services/custom/Project2';
import { PreloaderService } from './../../../services/preloader.service';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-project-lookup',
  templateUrl: './project-lookup.component.html',
  styleUrls: ['./project-lookup.component.css']
})
export class ProjectLookupComponent implements OnInit {

  @Input()
  set resetData(isClear) {
    this.clearFromParent();
    this.projects = [];
  }
  @Input()
  set source(e) {
    this._source = e;
  }

  @Input()
  set isMultipleSelected(e) {
    this._multipleSelect = e;
    this.maxSelectedItems = e ? 20 : 1;
  }
  @Output() selectedProject: EventEmitter<any> = new EventEmitter;
  @Output() selectedInitialProject: EventEmitter<any> = new EventEmitter;

  _source = '';
  whereObj: any;
  _multipleSelect = true;
  projects: any = [];
  selectedValue: any;
  maxSelectedItems = 20;
  noPgmoProject = 'No Project Available';
  @Input() isdisabled;
  @Input()
  set filterProjectObj(params) {
    this.projects = [];
    this.clearFromParent();
    if (this._source === 'new-conversation') {
      this.whereObj = {}
    } else {
      this.whereObj = this.whereObj && Object.keys(this.whereObj).length ? this.whereObj : {};
    }

    if (params && Object.keys(params).length) {
      if (params['Project_Type__c'] && params['Project_Type__c'].length && params['Project_Type__c'].filter(Boolean).length) {
        this.whereObj['Project_Type__c'] = { inq: params['Project_Type__c'] };
      }
      if (params['Account__c'] && params['Account__c'].length) {
        this.whereObj['Account__c'] = { inq: params['Account__c'] };
      }
    }
    this.getProjects(this.whereObj)
  }
  @Input() set selectedConversatonProj(e) {
    if (e) {
      this.selectedTaskProject = e;
      this.selectedValue = this.selectedTaskProject && this.selectedTaskProject.sfdcId;
      this.getProjects({sfdcId: e.sfdcId});
    } else {
      this.selectedTaskProject = '';
    }
  }

  selectedTaskProject: any

  constructor(
    private _preloaderService: PreloaderService,
    private _project2Api: Project2Api
  ) { }

  ngOnInit() {
    const preSelected = localStorage.getItem('filterObj');
    if (preSelected && JSON.parse(preSelected) &&
      JSON.parse(preSelected)['projects'] && JSON.parse(preSelected)['projects'].length) {
      this.selectedValue = JSON.parse(preSelected)['projects'];
      this.onChange();
      this.getProjects(this.whereObj);
    } else {
      if (!this.whereObj) {
        this.getProjects({});
      } else {
        this.getProjects(this.whereObj);
      }
    }
    if (this.selectedTaskProject) {
      this.selectedValue = this.selectedTaskProject && this.selectedTaskProject.sfdcId;
      this.selectedInitialProject.emit(this.selectedValue);
    }
  }

  onChange() {
    this.selectedProject.emit(this.selectedValue);
  }

  onClear() {
    this.selectedValue = null;
    this.selectedProject.emit([]);
  }

  clearFromParent() {
    this.selectedValue = [];
  }

  getProjects(whereObj) {
    console.log('getProjects',whereObj)
    this._preloaderService.showPreloader();
    const filterObj = {
      where: whereObj,
      fields: ['Name', 'sfdcId', 'ProjectName__c', 'Project_Type__c']
    }
    this._project2Api.find(filterObj).subscribe(
      data => {
        if (data && data.length) {
          const results = this.modifyData(data);
          this.projects = results;
        } else {
          this.projects = [];
          this.noPgmoProject = 'No Record Found'
        }
        this._preloaderService.hidePreloader();
      },
      error => {
        this._preloaderService.hidePreloader();
      });
  }

  modifyData(data) {
    if (data.length) {
      data.forEach(item => {
        item['sfdcId'] = (item.sfdcId) ? item.sfdcId : '';
        if (item.Name && item.ProjectName__c === 'null-null') {
          item['Name'] = item.Name ? item.Name : '';
        } else if (!item.Name && item.ProjectName__c !== 'null-null') {
          item['Name'] = item.ProjectName__c;
        } else if (item.Name && item.ProjectName__c !== 'null-null') {
          item['Name'] = (item.Name) ? item.Name + ' - ' + item.ProjectName__c : '';
        }
      });
      return data;
    } else {
      return [];
    }
  }

}
