import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FilterServiceApi } from './../../sdk';
import { ModalService } from '../../services/modal.service';
import { PreloaderService } from '../../services/preloader.service';
import { ProjectApi } from 'shared/sdk';

@Component({
  selector: 'app-modal-box',
  templateUrl: './modal-box.component.html',
  styleUrls: ['./modal-box.component.css'],
})
export class ModalBoxComponent implements OnInit {
  isSetEnabled = false;
  isSearchEnable = false;
  pageTitle: string;
  filterObj = {};
  reflectKey: string;
  @Input()
  set modalOptions(e) {
    this.pageTitle = e.pageTitle;
    this.filterObj = e.dbConfig;
    this.columns = e.columns;
    this.isSetEnabled = e.isSetEnabled;
    this.reflectKey = e.reflectKey;
    this.isSearchEnable = e.isSearch;
    this.getDataList(0);
  }
  @Output() setInputValue: EventEmitter<any> = new EventEmitter;
  // datatable configuration
  columns: any;
  tableData: any;
  itemsPerPage = 10;
  isLoadMore = false;
  ngxOffset = 0;
  itemsPerBatch = 200
  noRecords: any = false;
  loadingIndicator = false;
  selectedCharCode = '';
  charCodesObj: any;
  txtName: any;

  constructor(
    private _filterServiceApi: FilterServiceApi,
    private _modalService: ModalService,
    private _cd: ChangeDetectorRef,
    private _preloaderService: PreloaderService,
    private _projectApi: ProjectApi
  ) {
    if (!this.filterObj['where']) {
      this.filterObj = { _where: {} }
    }
  }

  ngOnInit() {
    this.getAtoZCharCodes();
  }

  getDataList(offset) {
    this._preloaderService.showPreloader();
    const _paramObj = {};
    if (this.filterObj && this.filterObj['_fields']) {
      _paramObj['fields'] = this.filterObj['_fields'];
    }
    if (this.filterObj && this.filterObj['_include']) {
      _paramObj['include'] = this.filterObj['_include'];
    }
    _paramObj['limit'] = this.itemsPerBatch;
    _paramObj['skip'] = offset;
    _paramObj['where'] = this.filterObj['_where'];

    const filterObj = {
      'models': [this.filterObj['_model']],
      'params': _paramObj
    }
    let responseObjectName = this.filterObj['_model'];

    if (this.filterObj && this.filterObj['_accountId']) {
      filterObj['accountId'] = this.filterObj['_accountId'];
    }
    if (this.filterObj && this.filterObj['_responseObjectName']) {
      responseObjectName = this.filterObj['_responseObjectName'];
    }

    // get data for filters 
    if (responseObjectName === 'programs') {
      this.allProgramRelatedServices(filterObj);
     } else {
        this.allFilterRelatedServices(filterObj, responseObjectName);
     }
  }

  private allProgramRelatedServices(filterObj) {
    this._projectApi.getMasterProjects(filterObj).subscribe(
      data => {
        if (data) {
          const result = (data && data['programs']) ? data['programs'] : 0;
          this.noRecords = (result.length < this.itemsPerBatch) ? true : false;
          if (!this.isLoadMore) {
            this.tableData = result;
          } else {
            result.forEach(c => {
              this.tableData.push(c);
            });
            this.tableData = [...this.tableData];
          }
          this.loadingIndicator = false;
          this._preloaderService.hidePreloader();
        }
      },
      error => {
        this.loadingIndicator = false;
        this._preloaderService.hidePreloader();
      }
    );
  }
  private allFilterRelatedServices(filterObj, responseObjectName) {
    this._filterServiceApi.getAllFiltersData(filterObj).subscribe(
      data => {
        if (data.data) {
          const result = (data.data && data.data[responseObjectName] && data.data[responseObjectName]['list']) ? data.data[responseObjectName]['list'] : 0;
          this.noRecords = (result.length < this.itemsPerBatch) ? true : false;
          if (!this.isLoadMore) {
            this.tableData = result;
          } else {
            result.forEach(c => {
              this.tableData.push(c);
            });
            this.tableData = [...this.tableData];
          }
          this.loadingIndicator = false;
          this._preloaderService.hidePreloader();
        }
      },
      error => {
        this.loadingIndicator = false;
        this._preloaderService.hidePreloader();
      }
    );
  }

  /*Data Table funcation start here*/
  loadMoreRecords() {
    this.isLoadMore = true;
    this.getDataList(this.tableData.length);
  }

  page(e) {
    if (e) {
      this.ngxOffset = e.offset;
    }
  }

  selectData(row) {
    row['Key'] = this.reflectKey;
    this.setInputValue.emit(row);
    this._modalService.closed();
  }

  getAtoZCharCodes() {
    this.charCodesObj = [];
    for (let i = 65; i <= 90; i++) {
      this.charCodesObj.push({ name: String.fromCharCode(i) });
    }
  }

  resetBtn() {
    this.txtName = '';
    this.selectedCharCode = '';
    if (this.filterObj['_model'] == 'userInternal') {
      this.filterObj['_where'] = Object.assign(this.filterObj['_where'],
        { firstname: { ilike: this.selectedCharCode + '%', options: 'i' } });
    } else {
      this.filterObj['_where'] = Object.assign(this.filterObj['_where'], { Name: { ilike: this.selectedCharCode + '%', options: 'i' } });
    }
    this.isLoadMore = false;
    this.getDataList(0);
  }

  onFilterChanged(e) {
    if (!this.filterObj['_where']) {
      this.filterObj['_where'] = {};
    }
    if (this.filterObj['_model'] == 'userInternal') {
      if (e !== 'ddl' && this.txtName) {
        this.selectedCharCode = this.txtName.charAt(0).toUpperCase();
        this.filterObj['_where'] = Object.assign(this.filterObj['_where'], { firstname: { ilike: this.txtName + '%', options: 'i' } });
      } else {
        this.filterObj['_where'] = Object.assign(this.filterObj['_where'],
          { firstname: { ilike: this.selectedCharCode + '%', options: 'i' } });
      }
    } else if (this.filterObj['_model'] === 'vendorsite') {
      if (e !== 'ddl' && this.txtName) {
        this.selectedCharCode = this.txtName.charAt(0).toUpperCase();
        this.filterObj['_where'] = Object.assign(this.filterObj['_where'], { Name: { ilike: '%' + this.txtName + '%', options: 'i' } });
      } else {
        this.filterObj['_where'] = Object.assign(this.filterObj['_where'], { Name: { ilike: this.selectedCharCode + '%', options: 'i' } });
      }
    } else {
      if (e !== 'ddl' && this.txtName) {
        this.selectedCharCode = this.txtName.charAt(0).toUpperCase();
        this.filterObj['_where'] = Object.assign(this.filterObj['_where'], { Name: { ilike: this.txtName + '%', options: 'i' } });
      } else {
        this.filterObj['_where'] = Object.assign(this.filterObj['_where'], { Name: { ilike: this.selectedCharCode + '%', options: 'i' } });
      }
    }
    this.isLoadMore = false;
    this.getDataList(0);
  }

  closeModal() {
    this._modalService.closed();
  }

  detectChanges() {
    if (!this._cd['destroyed']) {
      this._cd.detectChanges();
    }
  }
}
