import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { TaskApi } from 'shared/sdk';

@Component({
  selector: 'app-initiative-lookup',
  templateUrl: './initiative-lookup.component.html',
  styleUrls: ['./initiative-lookup.component.css']
})
export class InitiativeLookupComponent implements OnInit {

  private _initialized = false;
  public searchInput = new EventEmitter<string>();

  @Input() set resetData(isClear) {
    if (isClear) {
      this.selectedRecord = null;
    }
  }

  @Input() set selectedObjective(event) {
    if(this._initialized) {
      if(event && event.length) {
        this.selectedRecord = [];
        this.recordList = [];
        this.getEventObj.emit([]);
        this._selectedObjective = {inq: event};
      } else {
        this._selectedObjective = null;
      }
    }    
  }

  @Input() set selectedKeyResult(event) {
    if(this._initialized) {
      if(event && event.length) {
        this.selectedRecord = [];
        this.recordList = [];
        this.getEventObj.emit([]);
        this._selectedKeyResult = {inq: event};
      } else {
        this._selectedKeyResult = null;
      }
    }    
  }

  @Input() isMultiSelect = true;
  @Input() placeholder = 'Initiative';
  @Output() getEventObj: EventEmitter<any> = new EventEmitter();
  recordList: any;
  itemsPerBatch = 50;
  notFoundText: string;
  orderBy = 'id DESC';
  maxSelectedItems = 1;  
  whereFilter: any = {};
  selectedRecord = null;

  // Conditions for where filter
  private _conditionForInitiative = [{PgMO_Parent_Task__c: {neq: null}}, {PgMO_Parent_Task__c: {neq: ''}}];
  private _selectedObjective = null;
  private _selectedKeyResult = null;
  private _selectedProject = 'a6f2L0000004E3lQAE';

  constructor(private _recordsApi: TaskApi) { }

  ngOnInit() {
    this.preselectedRecord();
    this.getRecordsList();
    this.getSelectedRecordsList();
    this._initialized = true;
  }

  preselectedRecord() {
    const preSelected = localStorage.getItem('filterObj');
    if(!this.selectedRecord) {
      this.selectedRecord = JSON.parse(preSelected)['initiative'];
    }
  }

  getSelectedRecordsList() {
    if (this.selectedRecord) {
      this.loadRecordsList().subscribe(
        x => {
          x.map(ele => { ele['Label'] = ele['Form_Name__c'] + ' (' + ele['Record_Number__c'] + ')' });
          this.recordList = x;
          this.notFoundText = (this.recordList.length === 0) ? 'No initiative found.' : '';
        },
        err => {
          this.recordList = [];
        }
      )
    }
  }

  getRecordsList() {
    this.searchInput
      .pipe(
        filter(search => search && search.length > 3),
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(search => this.loadRecordsList(search))
      ).subscribe(
        x => {
          x.map(ele => { ele['Label'] = ele['Form_Name__c'] + ' (' + ele['Record_Number__c'] + ')' });
          this.recordList = x;
          this.notFoundText = (this.recordList.length === 0) ? 'No initiative found.' : '';
        },
        err => {
          console.log(err);
          this.recordList = [];
        }

      );
  }

  loadRecordsList(search?) {
    if (search) {
      const searchPattern = { ilike: '%' + search.trim() + '%', options: 'i' };
      this.whereFilter = {};
      this.whereFilter = {
        and: [
          { PgMO_Projects__c: this._selectedProject },
          { or: [{Form_Name__c: searchPattern}, {Record_Number__c: searchPattern}] },
        ]
      }
      if(this._selectedObjective) {
        this.whereFilter['and'].push({ PgMO_Milestones__c: this._selectedObjective});
      }
      if(this._selectedKeyResult) {
        this.whereFilter['and'].push({ PgMO_Parent_Task__c: this._selectedKeyResult});
      } else {
        this.whereFilter['and'].push({ or: this._conditionForInitiative});
      }
    }
    if (!search && this.selectedRecord) {
      this.whereFilter['sfdcId'] = this.selectedRecord;
    } else if (this.whereFilter['sfdcId']) {
      delete this.whereFilter['sfdcId'];
    }

    const reqObj = {
      'fields': ['Record_Number__c', 'Form_Name__c', 'sfdcId'],
      'where': this.whereFilter,
      limit: this.itemsPerBatch,
      order: this.orderBy
    };
    return this._recordsApi.find(reqObj);
  }

  clearRecords() {
    this.selectedRecord = null;
    this.notFoundText = 'Type to search initiative.';
    this.getEventObj.emit(null);
  }

  onRecordsChange() {
    this.getEventObj.emit(this.selectedRecord);
  }
}
