import { ContactApi } from './../../../../sdk/services/custom/Contact';
import { GeoMetroApi, AccountApi, RecordTypeApi } from 'shared/sdk';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { PreloaderService } from 'shared/services/preloader.service';
import { AlertService } from 'shared/services/alert.service';
import { Location } from '@angular/common';
import { CommonService } from 'shared/services/common.service';

import {
  coverageHoursList, SLAList, contactMethodList,
  workerDispatchTypeList,workerDedicatedTypeList, workerHiringTypeList, userTypeList,
  supportedLanguagesList, gender,workerRecordTypeList
} from '../../../../models/static-list-data.service';
import { AppStateService } from 'shared/services/app-state.service';
import { ModalService } from 'shared/services/modal.service';
import { vaccinationStatusList } from 'shared/views/vms/talent-detail/talent-overview/talent-overview-constant';


@Component({
  selector: 'app-add-worker',
  templateUrl: './add-worker.component.html',
  styleUrls: ['./add-worker.component.css']
})
export class AddWorkerComponent implements OnInit {

  @Input() fromWorkerList: boolean = false;
  @Input() set accountDetails(e) {
    if (e) {
      this.accountInfo = e;
    }
  }
  @Output() reloadList: EventEmitter<any> = new EventEmitter<any>();
  @Output() closePopup: EventEmitter<any> = new EventEmitter<any>();
  emailPattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;;
 // contactRegex = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{2,7}$/;
  contactRegex = /[+]\d{1,2}\s?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{2,7}$/;
  @Input() hideBackButton: boolean = false;
  @Input() fromJobDetail: boolean = false;
  expandabelMenus = {
    information: true,
    language: true,
    coverage: true,
    skilling: true
  }
  resetData = false;
  mailingCountryObj = null;
  coverageCountryObj = null;
  languageList = [];
  saveTriggered = false;
  userTypeSlug: string;
  accessType: any;
  slaList = [];
  coverageHoursDataList = [];
  contactMethodList = [];
  workerTypeList = [];
  userTypeList = [];
  workerHiringTypeList = [];
  cityList = [];
  additionCoverageCityList = [];
  talentInfromationList = [];
  talentError = false;
  mailingCountrySfdcid = '';
  accountInfo: string;
  selectedCandidateIds = [];
  gender = [];
  workerRecordTypeList = [];
  currentMemberType = '';
  disableSave: boolean = false;
  vaccinationStatusList = [...vaccinationStatusList];
 countrySfdc = false;
 selectedCountry = null;
 selectedState = null;
  statusList = [
    {
      value: 'Signed',
      label: 'Signed'
    },
    {
      value: 'Not Signed',
      label: 'Not Signed'
    }
  ];

  bgvApprovalStatusList = [
    {
      value: 'Initiated',
      label: 'Initiated'
    },
    {
      value: 'Pending Verification',
      label: 'Pending Verification'
    },
    {
      value: 'Verified',
      label: 'Verified'
    }
  ];

  primaryCoverageStateList = [];
  duplicateWorkerIds = [];
  talentInfo : any;

  workerForm: FormGroup = new FormGroup({
    FirstName: new FormControl(null, [Validators.required,Validators.minLength(1), Validators.maxLength(50),this._commonService.removeWhiteSpaces]),
    LastName: new FormControl(null, [Validators.required,Validators.minLength(1), Validators.maxLength(50),this._commonService.removeWhiteSpaces]),
    AccountId: new FormControl(null, [Validators.required]),
    Candidate__c: new FormControl(null),
    Phone: new FormControl(null,[Validators.pattern(this.contactRegex)]),
    MobilePhone: new FormControl(null, [Validators.required,Validators.pattern(this.contactRegex)]),
    Fax: new FormControl(null),
    Enable_Send_SMS__c: new FormControl(false), // worker
    Email: new FormControl(null, [Validators.required, Validators.pattern(this.emailPattern)]),
    Email__c: new FormControl(null, [Validators.pattern(this.emailPattern)]),
    MailingCountry: new FormControl(null, [Validators.required]),
    MailingState: new FormControl(null, [Validators.required]),
    MailingCity: new FormControl(null, [Validators.required,this._commonService.removeWhiteSpaces]),
    MailingStreet: new FormControl(null),
    MailingPostalCode: new FormControl(null),
    ts2__Contact_Method__c: new FormControl('Email', [Validators.required]),
    Worker_Type__c: new FormControl(null, [Validators.required]), // worker
    worker_hiring_type: new FormControl(null, [Validators.required]), // not available
    Languages_Known__c: new FormControl(null),
    other_language: new FormControl(null), // not available
    coverage_country: new FormControl(null, [Validators.required]),
    primary_state: new FormControl(null, [Validators.required]),
    primary_coverage_city: new FormControl(null, [Validators.required]),
    additional_coverage_city: new FormControl(null),
    worker_nda_status: new FormControl(null),
    worker_employer_contract: new FormControl(null),
    worker_payment_terms: new FormControl(null),
    worker_bgv_approval_status: new FormControl(null),
    vendor_msa_status: new FormControl(null),
    worker_sow_status: new FormControl(null),
    vendor_sow_status: new FormControl(null),
   // Iron_Approved__c: new FormControl(false),
    Enable_App_Login__c: new FormControl(false),//contact
    ISG_Email_Address__c: new FormControl(null, [Validators.pattern(this.emailPattern)]), //worker
    Gender__c: new FormControl(null, [Validators.required]),
    User_Type__c: new FormControl(null, [Validators.required]),
    record_Type: new FormControl(null, [Validators.required]),
    Blacklisted__c: new FormControl(false)

  });
  accountName: string;
  accountId: any;

  constructor( 
    private _geoMetroApi: GeoMetroApi, private _location: Location,
    private _contactApi: ContactApi,
    private _cd: ChangeDetectorRef,
    private _preLoaderService: PreloaderService,
    private _alertService: AlertService,
    private _appStateService: AppStateService,
    private _accountApi: AccountApi,
    private _commonService: CommonService,
    private _modalService: ModalService,
    private _recordTypeApi: RecordTypeApi,
    ) {
    this.slaList = [...SLAList];
    this.coverageHoursDataList = [...coverageHoursList];
    this.contactMethodList = [...contactMethodList];
   // this.workerTypeList = [...workerTypeList];
    this.userTypeList = [...userTypeList];
    this.workerHiringTypeList = [...workerHiringTypeList];
    this.gender = [...gender];
    this.workerRecordTypeList = [...workerRecordTypeList];
    this.languageList = [...supportedLanguagesList];
  }

  ngOnInit() {
    this._commonService.saveCurrentPageAnalytics('Talent Manager', 'Add Worker');
    this.accessType = this._appStateService.getAccessType();
    this.userTypeSlug = localStorage.getItem('userTypeSlug');
    if (this.accessType === 'vendor' && this.userTypeSlug === 'admin') {
      this._preLoaderService.showPreloader();
      this.accountId = this._appStateService.getAppState();
      this._accountApi.findOne({ where: { sfdcId: this.accountId.value.user.AccountId } })
        .subscribe(
          res => {
            this._preLoaderService.hidePreloader();
            if (res) {
              this.accountName = res['Name'];
              this.workerForm.get('AccountId').setValue(res['sfdcId']);
              this.workerForm.controls.AccountId.disable();
            }

          }, err => {
            this._preLoaderService.hidePreloader();
            console.log(err);
          })
    }
    if (this.fromWorkerList && this.accessType === 'internal') {
      this.accountName = this.accountInfo['accountName'];	
      this.workerForm.get('AccountId').setValue(this.accountInfo['accountSfdcId']);	
      this.workerForm.controls.AccountId.disable();	
    }	
    if (this.accessType != 'internal') {	
      this.workerForm.controls['Worker_Type__c'].setValidators(null);	
      this.workerForm.controls['worker_hiring_type'].setValidators(null);	
      this.workerForm.controls['Gender__c'].setValidators(null);	
      this.workerForm.controls['User_Type__c'].setValidators(null);	
      this.workerForm.controls['record_Type'].setValidators(null);
    }
  }
  ngAfterViewInit() {	
    if (this.fromJobDetail) {	
      setTimeout(() => {	
        const workerModal: any = document.querySelector('div.modal-dialog');	
        const popBox: any = document.querySelector('div.popBox');	
        if (workerModal)	
          workerModal.style.maxWidth = '98%';	
        if (popBox) {	
          popBox.style.maxWidth = '100%';	
          popBox.style.width = '100%';	
          // popBox.style.left = 'calc(32% - 463px)';	
          popBox.style.position = 'unset';	
          popBox.style.left = '0';	
          popBox.style.border = 'unset';	
        }	
      }, 0);	
    }	
  }
  onLanguageSelectionChange(event, index) {
    this.workerForm.get('Languages_Known__c').setValue(event);
  }

  onAdditionalCoverageSelectionChange(event, index) {
    this.workerForm.get('additional_coverage_city').setValue(event);
  }
 
  getAccountSelected(event) {
    this.workerForm.get('AccountId').setValue(event);
    this._preLoaderService.showPreloader();
    this._accountApi.findOne({ where: { sfdcId: event },
    'fields': ['sfdcId','RecordTypeId']
    })
    .subscribe(
      res => {
       if (res && res['RecordTypeId']) {
        this.setMemberType(res['RecordTypeId'])
        }

      }, err => {
        this._preLoaderService.hidePreloader();
        console.log(err);
      })

  }

  setMemberType(recordTypeId) {
    if (recordTypeId) {
      this._recordTypeApi.find({
            fields: ['sfdcId', 'Name'],
            where: { 'sfdcId': recordTypeId }
        }).subscribe(
            res => {
                this._preLoaderService.hidePreloader();
                if (res && res.length) {
                    this.currentMemberType = res[0]['Name'];
                }

            },
            err => {
                this._preLoaderService.hidePreloader();
                this._alertService.error(err.message);
            }
        );
      }
}

onChangeWorkerRecordType(e) {
  //this.isUploadButtonEnabled = true;
  this.workerTypeList = [];
  this.workerForm.get('Worker_Type__c').setValue(null);
 if (e.value === '0121a0000006Qj7AAE') {
  this.workerTypeList = [...workerDedicatedTypeList];
 }else{
  this.workerTypeList = [...workerDispatchTypeList];
 }
}

  getCandidateArr(event) {
    this.selectedCandidateIds = event['sfdcId'] ? (Array.isArray(event['sfdcId']) ? event['sfdcId'] : [event['sfdcId']]) : [];
    this.workerForm.get('Candidate__c').setValue(event['Name']);
    if(event['sfdcId']){
      this.getCandidate(event['sfdcId'])
    }
    
  }

  getCandidate(candidateSfdcId){
    
    this._contactApi.find({ where: { sfdcId: candidateSfdcId },
              include: [
                {
                  relation: 'skillings',
                  scope: {
                    fields: ['sfdcId','Name'],
                    include: [{
                      relation: 'talentInformations',
                      scope: {
                        fields: ['sfdcId', 'Talent_Type__c', 'Talent_Level__c', 'Coverage_Hours__c', 'SLA__c'],
                      }
                    }]
                  }
                },
               ]
    }).subscribe(
      res => {
          if (res) {
           this.workerForm.get('FirstName').setValue(res[0]['FirstName']);
           this.workerForm.get('LastName').setValue(res[0]['LastName']);
           this.workerForm.get('Email').setValue(res[0]['Email']);
           this.workerForm.get('MobilePhone').setValue(res[0]['MobilePhone']);
           this.workerForm.get('Phone').setValue(res[0]['Phone']);
           this.workerForm.get('Email__c').setValue(res[0]['Email__c']);
           this.workerForm.get('MailingCity').setValue(res[0]['MailingCity']);
           this.countrySfdc = true;
           this.selectedCountry = res[0]['Country_Code__c'];
           this.mailingCountrySfdcid = res[0]['Country_Code__c'];
           this.workerForm.get('MailingCountry').setValue(res[0]['MailingCountry']);
           this.workerForm.get('MailingState').setValue(res[0]['MailingState']);
           this.workerForm.get('MailingStreet').setValue(res[0]['MailingStreet']);
           this.workerForm.get('MailingPostalCode').setValue(res[0]['MailingPostalCode']);
           this.selectedState = res[0]['MailingStateManager__c'];
           this.talentInfo = [];
           this.talentInfromationList = [];
           if (res[0]['skillings'][0]) {
            this.getTalentInfo(res[0]['skillings'][0]);
           }

           
          }
          
      },
      err => {
          this._alertService.warn('Oops! something went wrong.');
         
      }
  )
  
  }

  getTalentInfo(skilling){
    this.talentInfo = skilling;
   
    // if(skilling['talentInformations']){
    //   this.talentInfo = skilling['talentInformations'];
    // }else{
    //   this.talentInfo = [];
    // }
    
  
}

  showCandidateList(content, size) {
    this._modalService.open(content, size);
}
  onSelectingMailingCountry(event) {
    this.mailingCountryObj = [event];
    this.workerForm.get('MailingCountry').setValue(event['Name']);
    this.mailingCountrySfdcid = event['sfdcId'];
    this.countrySfdc = true;
    this.selectedCountry = event['sfdcId']
    this.workerForm.get('MailingState').setValue(null);
   }

  getSelectedState(event) {
    this.workerForm.get('MailingState').setValue(event['stateName']);
    this.selectedState = event['sfdcId']
  }

  onSelectingCoverageCountry(event) {
    this.additionCoverageCityList = [];
    this.coverageCountryObj = [event];
    this.workerForm.get('coverage_country').setValue(event['sfdcId']);
    this.workerForm.get('primary_state').setValue(null);
    this.workerForm.get('primary_coverage_city').setValue(null);
    // if (event) {
    //   this.getStateList();
    // } else {
    //   this.primaryCoverageStateList = [];
    // }
  }

  getSelectedCoverageState(event) {
    this.workerForm.get('primary_state').setValue(event['stateName']);
    this.workerForm.get('primary_coverage_city').setValue(null);
    if (event && event['sfdcId']) {
      this.getCityList(event['sfdcId']);
    }
  }

  getCityList(sfdcId) {
    this._preLoaderService.showPreloader();
    const whereObj = {};
    const selectedState = sfdcId; //this.workerForm.get('primary_state').value;
    if (selectedState) {
      whereObj['StateManager__c'] = selectedState;
    }
    this._geoMetroApi.find({
      where: whereObj,
      fields: ['City__c', 'GEO_Description__c', 'sfdcId', 'Name']
    }).subscribe(reply => {
      this._preLoaderService.hidePreloader();
      this.modifyCityListData(reply);
    }, err => {
      this._preLoaderService.hidePreloader();
      console.log(err);
    });
  }

  modifyCityListData(data) {
    this.cityList = [];
    data.forEach(item => {
      this.cityList.push({
        value: item['sfdcId'],
        label: item['City__c'] + ' (' + item['Name'] + ')'
      });
    });
  }

  modifyAdditionalCoverageListData(data) {
    this.additionCoverageCityList = [];
    data.forEach(item => {
      this.additionCoverageCityList.push({
        value: item['sfdcId'],
        label: item['City__c'] + ' (' + item['Name'] + ')',
        Name:  item['Name']

      });
    });
  }

  getStateList(event) {
    // return console.log(this.coverageCountryObj);
    this._preLoaderService.showPreloader();
    const whereObj = {};
    const selectedCountry = this.workerForm.get('coverage_country').value;
    if (selectedCountry && event && event.value) {
      whereObj['Country_Code__c'] = selectedCountry;
      whereObj['sfdcId'] = { neq: event.value };
    }
    this._geoMetroApi.find({
      where: whereObj,
      fields: ['City__c', 'GEO_Description__c', 'sfdcId', 'Name']
    }).subscribe(reply => {
      if (reply && reply.length) {
        this.modifyAdditionalCoverageListData(reply);
      } else {
        this.additionCoverageCityList = [];
      }
      this._preLoaderService.hidePreloader();
    }, err => {
      this._preLoaderService.hidePreloader();
      console.log(err);
    });
  }

  getTalentInformationList(event) {
    this.talentInfromationList = event;
    this.talentError = (event && event.length > 0) ? false : true;
    this.saveTriggered = false;
  }

  createWorker(modal) {
    this.saveTriggered = true;
    this.markValidatorsFiledTouched();
    this._cd.detectChanges();
    if (this.workerForm.valid && this.talentInfromationList.length) {
      // this.saveAllData();
      this.checkForDeduplication(modal)
    } else {
      this.saveTriggered = false;
      this._cd.detectChanges();
    }
  }

  checkForDeduplication(modal) {
    let filterObj = {
      where: {
        or: []
      }
    }
    const Name = this.workerForm.get('FirstName').value + ' ' + this.workerForm.get('LastName').value;
    const Email = this.workerForm.get('Email').value;
    const personalEmail = this.workerForm.get('Email__c').value;
    const Number = this.workerForm.get('MobilePhone').value;
    if (Name) {
      filterObj.where.or.push({ 'Name': { 'inq': [Name] } });
    }
    if (Email) {
      filterObj.where.or.push({ 'Email__c': { 'inq': [Email] } });
    }
    if (personalEmail) {
      filterObj.where.or.push({ 'Personal_Email_ID__c': { 'inq': [personalEmail] } });
    }
    if (Number) {
      filterObj.where.or.push({ 'Mobile_Phone_Number__c': { 'inq': [Number] } });
    }
    this._commonService.getDuplicateWorker(filterObj, (res) => {
      if (res && res.length) {
        this.duplicateWorkerIds = [];
        this.duplicateWorkerIds = res.map(worker => {
          return {
            Name: worker.Name || '',
            Email__c: worker.Email__c || '',
            Personal_Email_ID__c: worker.Personal_Email_ID__c || '',
            Mobile_Phone_Number__c: worker.Mobile_Phone_Number__c || '',
            Dispatch_Worker_num__c: worker.Dispatch_Worker_num__c || '',
            matchedCriteria: this._commonService.getMatcherCriteria(worker, filterObj),
          }
        });
        this.open(modal, 'lg modal-deduplication');
      } else {
        this.saveAllData();
      }
    })
  }

  saveAllData(close?) {
    if (close) {
      this.closeModal();
    }
    this._commonService.saveCurrentPageAnalytics('Talent Manager', 'All Talent');
    const deleteContactKeys = ['Enable_Send_SMS__c', 'Worker_Type__c', 'worker_hiring_type', 'other_language', 'coverage_country',
      'primary_state', 'worker_nda_status',
      'worker_employer_contract', 'worker_payment_terms', 'worker_bgv_approval_status',
      'vendor_msa_status', 'worker_sow_status', 'vendor_sow_status', 'Email',
      'primary_coverage_city', 'additional_coverage_city', 'Languages_Known__c', 'ISG_Email_Address__c', 'record_Type',
      'Worker_Type__c'];
    const contactData = this.workerForm.value;
    delete contactData['Candidate__c'];
    deleteContactKeys.forEach(key => {
      delete contactData[key];
    });
    contactData['AccountId'] = this.workerForm.get('AccountId').value;
    contactData['Country_Code__c'] = this.mailingCountrySfdcid;
    contactData['MailingCountry_Code__c'] = this.mailingCountrySfdcid;
    contactData['OtherCountry_Code__c'] = this.mailingCountrySfdcid;
    contactData['MailingStateManager__c'] = this.selectedState;
    contactData['State__c'] = this.selectedState;
    contactData['OtherStateManager__c'] = this.selectedState;
    contactData['IsTestUser__c'] = false;

    if(this.accessType != 'internal') {
      if(contactData['Enable_App_Login__c']){
        delete contactData['Enable_App_Login__c'];
      }

      if(contactData['User_Type__c']){
        delete contactData['User_Type__c'];
      }
      
    }

    const talentDataList = this.talentInfromationList;
    talentDataList.forEach(item => {
      item['SLA__c'] = item['SLA__c'].toString().replaceAll(',', ';');
      delete item['isPending'];
      item['Worker_Skilling_Profile__c'] = null;
      item['Commercial__c'] = false;
    });
    const geoMetro = [
      { primary_coverage_city: this.workerForm.get('primary_coverage_city').value },
      { additional_coverage_city: this.workerForm.get('additional_coverage_city').value }
    ];

    const uploadData = {
      contact: contactData,
      worker: {
        'Name': this.workerForm.get('FirstName').value + ' ' + this.workerForm.get('LastName').value,
        'Contact__c': null,
        'Enable_Send_SMS__c': this.workerForm.get('Enable_Send_SMS__c').value,
        //'Worker_Type__c': this.workerForm.get('Worker_Type__c').value,
        'Work_Phone_Number__c': this.workerForm.get('Phone').value,
        'Mobile_Phone_Number__c': this.workerForm.get('MobilePhone').value,
        'Email__c': this.workerForm.get('Email').value,
         'Country_Code__c': this.workerForm.get('MailingCountry').value,
         'State_Code__c': this.workerForm.get('MailingState').value,
        'Worker_City__c': this.workerForm.get('MailingCity').value,
        'Coverage_Country_Code__c': this.workerForm.get('coverage_country').value,
        'Coverage_State_Code__c': this.workerForm.get('primary_state').value,
        'GEO_Code__c': this.workerForm.get('primary_coverage_city').value,
        'Additional_Coverage_Cities__c': this.workerForm.get('additional_coverage_city').value,
        'Blacklisted__c': false
        //"Enable_App_Login__c":0
      },
      
      talent: talentDataList,
       geoMetro: geoMetro
    }
  
    if(this.accessType === 'internal') {
      uploadData['worker']['ISG_Email_Address__c'] = this.workerForm.get('ISG_Email_Address__c').value;
      uploadData['worker']['Worker_Employer_Contract__c'] = this.workerForm.get('worker_employer_contract').value;
      uploadData['worker']['Worker_NDA_Status__c'] = this.workerForm.get('worker_nda_status').value;
      uploadData['worker']['Payment_Term__c'] = this.workerForm.get('worker_payment_terms').value;
      uploadData['worker']['RecordTypeId'] = this.workerForm.get('record_Type').value;
      uploadData['worker']['Worker_Type__c'] = this.workerForm.get('Worker_Type__c').value;
      uploadData['worker']['Worker_Hiring_Type__c'] = this.workerForm.get('worker_hiring_type').value;
      if(this.selectedCandidateIds && this.selectedCandidateIds[0]){
        uploadData['worker']['Candidate__c'] = this.selectedCandidateIds[0];
      }
    }
    if (!this.talentInfo || !this.talentInfo.sfdcId) {
      uploadData['skilling'] = {
        'Name': this.workerForm.get('FirstName').value + ' ' + this.workerForm.get('LastName').value,
        'Worker__c': null,
        'Status__c': 'Initiated',
        'Vendor__c': this.workerForm.get('AccountId').value,
        'Resource__c': null,
        'other_languages__c': this.workerForm.get('other_language').value,
        'Languages__c': this.workerForm.get('Languages_Known__c').value,
        //'Iron_Approved__c': this.workerForm.get('Iron_Approved__c').value
      }
    } else {
      uploadData['skilling'] = {
        'sfdcId': this.talentInfo.sfdcId,
        'Worker__c': null,
        'Resource__c': null
      }
      if(this.accessType === 'internal' ) {
        uploadData['skilling']['Worker_BGV_check_Status__c'] = this.workerForm.get('worker_bgv_approval_status').value;
        uploadData['skilling']['Worker_SOW_Status__c']= this.workerForm.get('worker_sow_status').value;
        uploadData['skilling']['Vendor_MSA_Status__c']= this.workerForm.get('vendor_msa_status').value;
        uploadData['skilling']['Vendor_SOW_Status__c']= this.workerForm.get('vendor_sow_status').value;
       }
      uploadData['worker']['Primary_Worker_Skilling_Profile__c'] = this.talentInfo.sfdcId;
      
    }
	
    if (this.fromJobDetail)	
      document.querySelectorAll('ngb-modal-window')[0].scrollTo(0, 0);
    this._preLoaderService.showPreloader();
    this._contactApi.saveWorkerInformation(uploadData).subscribe(reply => {
      this._preLoaderService.hidePreloader();
      if (reply && reply.Message) {
        if(this.fromWorkerList) {
          this.reloadList.emit(true);
        }
        this._modalService.closed();
        this._alertService.success("Worker has been added successfully.");
        if (this.fromWorkerList || this.fromJobDetail) {	
          if (this.fromWorkerList)	
            this.reloadList.emit(true);	
          setTimeout(() => {	
            this.resetForm();	
            this.disableSave = false;	
            this._modalService.closed();	
          }, 2000);	
        }
      } else {
        
        this._alertService.error("Please try again later.");
      }

    }, err => {
      this.disableSave = false;	
      console.log(err.message);
      this._alertService.error(err.message);
    });
  }

  markValidatorsFiledTouched() {
    this.workerForm.get('FirstName').markAsTouched();
    this.workerForm.get('LastName').markAsTouched();
    this.workerForm.get('AccountId').markAsTouched();
    this.workerForm.get('MobilePhone').markAsTouched();
    this.workerForm.get('Email').markAsTouched();
    this.workerForm.get('Email__c').markAsTouched();
    this.workerForm.get('MailingCountry').markAsTouched();
    this.workerForm.get('MailingState').markAsTouched();
    this.workerForm.get('MailingCity').markAsTouched();
    this.workerForm.get('ts2__Contact_Method__c').markAsTouched();
    this.workerForm.get('Worker_Type__c').markAsTouched();
    this.workerForm.get('worker_hiring_type').markAsTouched();
    this.workerForm.get('coverage_country').markAsTouched();
    this.workerForm.get('primary_state').markAsTouched();
    this.workerForm.get('primary_coverage_city').markAsTouched();
    this.workerForm.get('Gender__c').markAsTouched();
    this.workerForm.get('User_Type__c').markAsTouched();
    this.workerForm.get('record_Type').markAsTouched();
  }

  resetForm() {
    this.workerForm.reset();
    this.talentInfromationList = [];
    this.additionCoverageCityList = [];
    this.languageList = [];
    this.talentError = false;
    this.resetData = true;
    this._cd.detectChanges();
  }

  // isNumber(evt) {
  //   evt = (evt) ? evt : window.event;
  //   const charCode = (evt.which) ? evt.which : evt.keyCode;
  //   if (charCode === 8 || charCode === 9) {
  //     return true;
  //   }
  //   return !((charCode > 31 && charCode < 48) || charCode > 57);
  // }

  onBackClick() {
    // this._commonService.saveCurrentPageAnalytics('Billing-Manager', 'All Talent');
    this._location.back();
  }

    /**
      * close Add/Edit/Delete Modal
      */
  closeModal(name?, fromDeduplicateUser?: boolean) {
    this.closePopup.emit(true);
    if (!fromDeduplicateUser) {
      const ngbWindow: any = document.querySelector(
        "ngb-modal-window .closePop"
      );
      if (ngbWindow)
        ngbWindow.click();
      if (ngbWindow) {
        ngbWindow.style.overflowY = "scroll";
      }
    } else {
      this._modalService.closed();
      if (fromDeduplicateUser) {
        const ngbWindow: any = document.querySelectorAll("ngb-modal-window")[0];
        if (ngbWindow) {
          ngbWindow.style.overflowY = "scroll";
        }
      }
    }
  }
  open(content, size) {
    this._modalService.open(content, size);
  }

}
