import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { GeoMetroApi } from 'shared/sdk';

@Component({
    selector: 'app-geo-metro-lookup',
    templateUrl: './geo-metro-lookup.component.html',
    styleUrls: ['./geo-metro-lookup.component.css']
})

export class GeoMetroLookupComponent implements OnInit {
    @Input() boxedLayout;
    @Input() bindValue = 'sfdcId';
    @Input() set resetData(isClear) {
        // this.geoMetros = [];
        // this.geoMetroList = [];
        this.selectedGeoMetro = [];
        this.jobsiteSfdcIds = [];
    }
    @Input() placeholder = 'Geo Metro';
    @Output() getGeoMetroObj: EventEmitter<any> = new EventEmitter;
    @Input() isMultipleAllowed = true;
    @Input() UseInGlobalFilter = true;
    @Input() disabled = false;
    // if value provided this will be selected by default for dropdown, UseInGlobalFilter key must be false.
    @Input() set selectedGeoMetroId(id) {
        if (id && this.UseInGlobalFilter == false) {
            this.selectedGeoMetro = id;
            let whereObj = {};
            whereObj['sfdcId'] = this.selectedGeoMetro;
            this.getGeoMetro(whereObj, '').subscribe((result: any) => {
                if (result && result.length) {
                    this.geoMetros = result;
                  }
            });
        }
    }
    notFoundText = 'No Data Found';
    geoMetroTypeahead = new EventEmitter<string>();
    geoMetros: any = [];
    geoMetroList: any = [];
    selectedGeoMetro = [];
    jobsiteSfdcIds = [];

    constructor(
        private _geoMetro: GeoMetroApi
    ) { }

    ngOnInit() {
        this.getGeoMetros({});
        // to show selected geometro for globalfilter
        if (this.UseInGlobalFilter) {
            const selectFilter = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
            if (selectFilter && selectFilter['geoMetro'] && Array.isArray(selectFilter['geoMetro']) && selectFilter['geoMetro'].length) {
                this.selectedGeoMetro = selectFilter['geoMetro'];
                const whereObj = { sfdcId: { inq: this.selectedGeoMetro } };
                this.getGeoMetro(whereObj, '').subscribe((result: any) => {
                    if (result && result.length) {
                        this.prepareGeoMetro(result)
                    }
                });
            }
        }

    }

    getGeoMetros(whereObj) {
        this.geoMetros = [];
        this.geoMetroList = [];
        this.geoMetroTypeahead.pipe(
            distinctUntilChanged(),
            debounceTime(200),
            switchMap(term =>
                (term && term.trim().length > 2) ? this.getGeoMetro(whereObj, term) : this.geoMetros = []
            )
        ).subscribe(result => {
            if (result && result.length) {
                this.prepareGeoMetro(result)
            }

        }, err => {
            console.log(err);
            this.geoMetros = [];
            this.notFoundText = '';
        });
    }

    getGeoMetro(whereObj, term) {
        return this._geoMetro.find({
            fields: ['Name', 'id', 'sfdcId', 'City__c', 'Country__c', 'Postal_Zip_Code__c', 'Street__c'],
            where: {
                and: [
                    {
                        or: [{ Name: { ilike: '%' + term.trim() + '%', options: 'i' } },
                        { City__c: { ilike: '%' + term.trim() + '%', options: 'i' } },
                        { Street__c: { ilike: '%' + term.trim() + '%', options: 'i' } }]
                    },
                    whereObj
                ]
            },
            order: 'createdAt DESC',
            include: [
                {
                    relation: 'jobsite',
                    scope: {
                        fields: ['sfdcId'],
                    }
                }
            ],
            limit: 200,
        });
    }

    prepareGeoMetro(result) {
        this.geoMetros = result;
        result.forEach(element => {
            if (element && element['jobsite'] && element['jobsite'].length) {
                // this.geoMetros.push(element);
                //let index = -1;
                //index = this.geoMetroList.findIndex(i => i.sfdcId === element.sfdcId);
                if (this.geoMetroList.indexOf(element.sfdcId) === -1) {
                    this.geoMetroList.push(element);
                }
            }
        });
        this.notFoundText = (this.geoMetros.length === 0) ? 'No data found.' : '';
    }

    onChange() {
        if(this.isMultipleAllowed) {
            this.jobsiteSfdcIds = [];
            this.selectedGeoMetro.forEach(item => {
                for (let index = 0; index < this.geoMetroList.length; index++) {
                    if (item === this.geoMetroList[index].sfdcId) {
                        this.geoMetroList[index].jobsite.forEach(element => {
                            this.jobsiteSfdcIds.push(element.sfdcId);
                        });
                        break;
                    }
                }
            });
            this.getGeoMetroObj.emit({ geoMetro: this.selectedGeoMetro, geoMetroJobSite: this.jobsiteSfdcIds });
        } else if(!this.isMultipleAllowed){
            this.getGeoMetroObj.emit(this.selectedGeoMetro);
        }
       
    }

    clearGeoMetro() {
        this.selectedGeoMetro = [];
        this.jobsiteSfdcIds = [];
        if(this.isMultipleAllowed){
            this.getGeoMetroObj.emit([]);
        } else {
            this.getGeoMetroObj.emit(null);
        }
       
    }
}
