import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';
import { ContactApi } from 'shared/sdk/services/custom/Contact';

@Component({
    selector: 'app-recruitment-contact-lookup',
    templateUrl: './recruitment-contact-lookup.component.html',
    styleUrls: ['./recruitment-contact-lookup.component.scss']
})
export class RecruitmentContactLookupComponent implements OnInit {
    public searchInput = new EventEmitter<string>();
    @Input() fromPage;

    @Input() set resetData(isClear) {
      if(isClear) {
        this.selectedContactList = [];
      }
    }
    @Input() isMultiSelect = true;
    @Input() placeholder = 'Contact List';
    @Output() getContactObj: EventEmitter<any> = new EventEmitter();
    recruitmentContactList: any;  
    itemsPerBatch = 50;
    notFoundText: string;
    orderBy = 'id DESC';
    selectedContactList: any[];
    whereFilter: any;
  
    constructor(private _contactApi: ContactApi) { }
  
    ngOnInit() {
      const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
      this.selectedContactList = (preselected && preselected[this.fromPage]) ?
        preselected[this.fromPage] : [];
  
      if (this.selectedContactList && this.selectedContactList.length) {
        this.whereFilter = { sfdcId: { inq: this.selectedContactList } };
        this.loadContactList().subscribe(
          x => {
            let data = [];
            x.forEach(val=>{
              val['contactName'] = val && val['Contact_Name__c'] ? val['Contact_Name__c'] : '';
              if(val['account'] && val['account']['Name']){
                val['contactName'] += '-'+val['account']['Name'];
              }
              data.push({Name:val['contactName'],sfdcId:val['sfdcId']})
            });
            this.recruitmentContactList = data;
            this.notFoundText = (this.recruitmentContactList.length === 0) ? 'No record found.' : '';
          },
          err => {
            this.recruitmentContactList = [];
          }
        )
      }
      this.getloadContactList();
    }
  
    getloadContactList() {
      this.searchInput
        .pipe(
          filter(search => search && search.length > 2),
          debounceTime(200),
          distinctUntilChanged(),
          switchMap(search => this.loadContactList(search))
        ).subscribe(
          x => {
            let data = [];
            x.forEach(val=>{
              val['contactName'] = val && val['Contact_Name__c'] ? val['Contact_Name__c'] : '';
              if(val['account'] && val['account']['Name']){
                val['contactName'] += '-'+val['account']['Name'];
              }
              data.push({Name:val['contactName'],sfdcId:val['sfdcId']})
            });
            this.recruitmentContactList = data ;
            this.notFoundText = (this.recruitmentContactList.length === 0) ? 'No record found.' : '';
          },
          err => {
            console.log(err);
            this.recruitmentContactList = [];
          }
  
        );
    }
  
    loadContactList(search?) {
      if (search) {
        const searchPattern = { ilike: '%' + search.trim() + '%', options: 'i' };
        this.whereFilter = { Contact_Name__c: searchPattern };
      }
  
      const reqObj = {
        where: this.whereFilter,
        fields: ['Name', 'id', 'sfdcId', 'Contact_Name__c', 'Email','AccountId'] ,
        include:[
            {
                relation:'account',
                scope:{
                    fields:['Name', 'id', 'sfdcId']
                }
            }
        ],
        order: 'Name DESC'
      };
      this.notFoundText = 'Type to search.';
      return this._contactApi.find(reqObj);
    }
  
    clearContactList() {
      this.selectedContactList = [];
      this.notFoundText = 'Type to search.';
      this.getContactObj.emit([]);
    }
  
    onContactListChange() {
      this.getContactObj.emit(this.selectedContactList);
    }
}
