import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TaxonomiesApi } from 'shared/sdk';
import { AppStateService } from 'shared/services/app-state.service';
import { debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';

@Component({
  selector: 'app-taxonomies-lookup',
  templateUrl: './taxonomies-lookup.component.html',
  styleUrls: ['./taxonomies-lookup.component.css']
})
export class TaxonomiesLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    this.taxonomySelected = [];
  }
  @Input() from;
  @Input() selectLabel = 'Name';
  @Input() selectbindValue = 'sfdcId';
  @Input() isMultiSelect = 'false';
  @Input() set selectTaxonomy(e) {
    if (e) {
      this.taxonomyList = [];
      this.taxonomyList.push(e);
      this.taxonomySelected = e && e.sfdcId;
    }
  }
  // @Input() set preSelected(e) {
  //   if (e) {
  //     this.taxonomySelected = e
  //     this.onTaxonomiesChange()
  //   }
  // }
  @Input() isDisabled = false;
  @Output() getTaxonomiesObj: EventEmitter<any> = new EventEmitter;

  // vendor taxonomy

  notaxonomy = 'Searching...';
  taxonomysTypeahead = new EventEmitter<string>();
  taxonomys: Array<any>;
  taxonomyList = [];
  taxonomySelected = [];

  constructor(
    private _taxonomiesApi: TaxonomiesApi
  ) { }

  ngOnInit() {
    this.getTaxonomiess();
  }

  getTaxonomiess() {
    this.taxonomysTypeahead.pipe(
      filter(term => term && term.length > 2),
      distinctUntilChanged(),
      debounceTime(200),
      switchMap(term => {
        const searchPattern = { ilike: '%' + term.trim() + '%', options: 'i' };
        const whereObj = {
          Name: searchPattern,
        };
        return this.getTaxonomiesList(whereObj);
      })
    ).subscribe(
      x => {
        this.taxonomyList = x;
        this.notaxonomy = (this.taxonomyList.length === 0) ? 'No taxonomy found.' : '';
      },
      err => {
        console.log(err);
        this.taxonomyList = [];
        this.notaxonomy = '';
      }
    );
  }

  onTaxonomiesChange() {
    this.getTaxonomiesObj.emit(this.taxonomySelected);
  }

  clearPrograms() {
    this.taxonomySelected = [];
    this.getTaxonomiesObj.emit({});
  }

  getTaxonomiesList(whereObj) {
    return this._taxonomiesApi.find({
      where: whereObj,
      fields: ['Name', 'id', 'sfdcId'],
      order: 'createdAt DESC',
    }).map(res => res);
  }

}
