import { Router } from '@angular/router';
import { AppStateService } from './../../services/app-state.service';
import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AlertService } from 'shared/services/alert.service';
import { ModalService } from 'shared/services/modal.service';
import { CommonService } from 'shared/services/common.service';
import { Subscription } from 'rxjs/Rx';
import { environment } from 'environments/environment' 
import { BaseVendorViewComponent } from 'msp/shared/base-vendor-view/base-vendor-view.component';
@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.css']
})
export class RightSidebarComponent extends BaseVendorViewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() disabledMenuOptions = []; // pass array to disable any visible set of icons, for ex- [disabledMenuOptions]="['filter']"
  @Input() visibleMenuOptions = []; // pass array to show set of icons, for ex- [visibleMenuOptions]="['job-status', 'filter']"
  @Input() selectedJob;
  @Input() serviceDetailData;
  @Input() selectedHelpDeskType;
  @Input() disableProjectType;
  @Input() currentPage;
  @Input() dateLabel;
  @Input() defaultSelectedAccountCommunity;
  @Input() filterOptions;
  @Input() myGlobalTask;
  @Input() enableLocalFilters = false;
  @Input() realtimeTaskConversation;
  selectedTeam: any;
  @Input()
  set selectedTask(e) {
    this._selectedTask = e;
  }
  // @Input() collapseState;
  @Input()
  set collapseState(e) {
    if (e !== undefined) {
      this.isSideBarCollapsed = e;
    }
  }
  @Input() selectedWorkorder;
  @Input() currentMenuOption;
  @Input() fromDashboard = false;
  @Input() enableCustomerFilter = false;
  @Input() isSideBarCollapsed: Boolean = true;
  @Input() jobObj?: any = false;
  @Input() caseData;
  @Input() modelNames;
  @Input() modelIds;
  @Input() timezone;
  @Output() appliedFilter = new EventEmitter();
  @Output() sidebarAction = new EventEmitter();
  @Output() changeTabDetail: EventEmitter<string> = new EventEmitter;
  @Output() currentMenuOptionChange = new EventEmitter();
  @Output() openEditView = new EventEmitter();
  @Output() openParentTaskView = new EventEmitter();
  @Output() openTaskMangerView = new EventEmitter();
  @Output() openMyTeamTaskView = new EventEmitter();

  
  @Input() page: string;
  @Input() pmsView?: any = false;
  @Input() set tripInfo(e) {
    this.selectedTripInfo = e;
    this.selectedJob = e;
  }
  @Input() refreshChild: any;
  @Input() set updateCurrentMenuOptions(e) {
    if (e) {
      this.menuOptionAction(e);
    }
  }
  wikiURL: any;
  title: any;
  @ViewChild('helpPopup') helpPopup: ElementRef;
  // pmsView = false;
  filterData: any;
  isFilterApplied = false;
  filterObj = {};
  accessType: any;
  _selectedTask: any;
  selectedTripInfo: any;
  _enableFilters: any = [];
  _clearAppliedFilters = false;
  pathUrl;
  @Input() set enableFilters(e) {
    this.isFilterApplied = false;
    this._enableFilters = e || [];
    if (this._enableFilters && this._enableFilters.length && !this._enableFilters.includes('useDefaultFilters')) {
      this.clearLocalStorageKeys();
      this._isFilterApplied();
    }
  }
  @Input() dispatchJobIds: any;
  _sub: Subscription;
  @Input() communityFor;
  @Input() fromPage;
  @Input() isEmployeeTypeMultipleSelected;
  @Input() preselectStatusType;
  @Input() isWorkerMultipleSelected;
  
  constructor(private _alertService: AlertService,
    private _appState: AppStateService,
    private _commonService: CommonService,
    private _modalService: ModalService, private _router: Router) {
      super(_appState);
      this.resetLocalFilters();
  }

  ngOnInit() {
    // this.pmsView = this._router.url.split('/')[1] === 'pms';
    const url = new URL(location.href);
    this.pathUrl = url.origin;
    this.accessType = this._appState.getAccessType();
    // To check if view as PMS mode is activated.
    if (localStorage.getItem('viewAsInfo')) {
      const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
      const viewAsPartner = (viewAsInfo && viewAsInfo.viewAsType === 'Partner');
      this.accessType = (viewAsPartner) ? 'partner' : this.accessType;
    }
    this._isFilterApplied();
    if (this.currentMenuOption !== '') {
      this.isSideBarCollapsed = false;
      this.sidebarAction.emit(true);
    }
    if (this.currentMenuOption === 'work-order' || this.currentMenuOption === 'job-status' || this.currentMenuOption === 'sales-order') {
      this.isSideBarCollapsed = false;
    }
    if (this.currentMenuOption === 'tripInfo') {
      this.isSideBarCollapsed = false;
      this.sidebarAction.emit(true);
    }

    // Subscribe value when user click on left side filter from HelpDesk Console
    this._sub = this._commonService.getLeftSideFilters().subscribe(leftFilter => {
      if (leftFilter && leftFilter.destroyAll) {
        setTimeout(() => { this._clearAppliedFilters = leftFilter.destroyAll; }, 0);
        this.collapseAction(false);
      }
    });
    if (this.enableLocalFilters) {
      this.setLocalFilters();
    }
  }
  // THIS CLEARS THE PROJECT2 LOCAL FILTER ON ROUTE CHANGE
  clearLocalStorageKeys(): void {
    var filterObjKey = [];
    var filterConditionKey = [];
    if (this._enableFilters && this._enableFilters.includes('projects')) {
      filterObjKey.push('projects');
      filterConditionKey.push('PgMO_Projects__c');      
    }
    if (this._enableFilters && this._enableFilters.includes('milestones')) {
      filterObjKey.push('milestones');
      filterConditionKey.push('PgMO_Milestones__c');      
    }
    if (this._enableFilters && this._enableFilters.includes('tasks')) {
      filterObjKey.push('tasks');
      filterConditionKey.push('PgMo_Task__c');
    }
    if(filterObjKey.length && filterConditionKey.length){
      this._commonService.removedFromLocalStorage(filterObjKey, filterConditionKey);
    }    
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {

      if (changes.visibleMenuOptions && !changes.visibleMenuOptions.firstChange &&
        changes.visibleMenuOptions.currentValue) {
        this.visibleMenuOptions = changes.visibleMenuOptions.currentValue;
      }
      if (changes.currentMenuOption && changes.currentMenuOption.currentValue) {
        this.currentMenuOption = changes.currentMenuOption.currentValue;
        if (changes.currentMenuOption.currentValue === 'none') {
          this.isSideBarCollapsed = true;
          this.currentMenuOption = '';
        }
      }
      if (changes.currentPage) {
        this.isSideBarCollapsed = true;
      }
    }
    if(environment.hideActivityFeed) {
      const feedsToBeHidden = ['task-activity-feed', 'activity-feed', 'checkin-checkout-feed', 'fleetManagementActivityFeed', 'RHS-Notification']
      this.visibleMenuOptions = this.visibleMenuOptions.filter(item => !feedsToBeHidden.includes(item));
      if(this.currentMenuOption === 'checkin-checkout-feed') {
        this.currentMenuOption = ''
      }
    }
  }

  filterTableData(event, teamSfdcId) {
    this.selectedTeam = teamSfdcId;
    this.openMyTeamTaskView.emit({ event: event, teamSfdcId: teamSfdcId });
  }
 
  menuOptionAction(option) {
    this.currentMenuOption = option;
    this.isSideBarCollapsed = false;
    this.sidebarAction.emit(true);
    this.currentMenuOptionChange.emit(this.currentMenuOption);
    if (option === 'edit-task') {
      this.openEditView.emit(true);
      this.collapseAction(true);
      this.isSideBarCollapsed = true;
    } else if (option === 'my-task-files') {
      this.openTaskMangerView.emit(option);
      this.collapseAction(true);
      this.isSideBarCollapsed = true;
    } else if (option === 'file-manager') {
      this.openTaskMangerView.emit(option);
      this.collapseAction(true);
      this.isSideBarCollapsed = true;
    } else if (option === 'parent-task-detail') {
      this.openParentTaskView.emit(option);
      this.collapseAction(true);
      this.isSideBarCollapsed = true;
    }
  }

  openMenuForms(option, template) {

    switch (option) {
      case 'upload-file':
        this._modalService.open(template, 'lg');
        // need to add emit data
        break;
      case 'message':
        this.changeTab('messages');
        break;
      case 'add-timecard':
        this._modalService.open(template, 'lg');
        // need to add emit data
        break;

      default:
        this._modalService.open(template, 'lg');
    }

  }

  collapseAction(action) {
    // To reset the input which used to clear applied task level filterss
    this._clearAppliedFilters = false;
    // To find visible and active (non-disabled) menu options -> visible - disabled
    const visibleAndActiveMenuOptions = this.visibleMenuOptions.filter((i) => !this.disabledMenuOptions.includes(i));

    // If there are no visible and active menu options keep the sidebar collapsed
    if (visibleAndActiveMenuOptions.length) {
      this.isSideBarCollapsed = action;
      if (!action && this.currentMenuOption === '') {
        this.currentMenuOption = 'filter';
        if (this.visibleMenuOptions.length && this.visibleMenuOptions.indexOf(this.currentMenuOption) === -1) {
          this.currentMenuOption = this.visibleMenuOptions[0];
        }
        this.currentMenuOptionChange.emit(this.currentMenuOption);
      }
      this.sidebarAction.emit(!action);
    }

  }

  searchCaseMessages(e) {
    this.filterData = e;
    // this.isFilterApplied = Object.keys(e).length ? true : false;
    this._isFilterApplied();
    this.appliedFilter.emit(this.filterData);
  }

  onApplyingSavedFilter() {
    // _isFilterApplied created to stop mutiple hits
    this._isFilterApplied();
    this.appliedFilter.emit(this.filterObj);
  }

  _isFilterApplied() {
    let totalFilterCount = 0;
    const savedFilterLookups = JSON.parse(localStorage.getItem('filterObj'));
    if (savedFilterLookups && Object.keys(savedFilterLookups).length) {
      if (this._enableFilters && this._enableFilters.length) {
        this._enableFilters.forEach((f) => {
          if (f === 'workerBillingTypes' && savedFilterLookups[f] && savedFilterLookups[f]['selectedCount']) {
            totalFilterCount += savedFilterLookups[f]['selectedCount'];
          } else {
            totalFilterCount += savedFilterLookups[f] && savedFilterLookups[f].length ? savedFilterLookups[f].length : 0;
          }
        });
      }
    }
    // ** this logic was of helpdesk filter count **

    // tslint:disable-next-line:max-line-length
    // if (this.selectedHelpDeskType && !this.filterObj.projectTypes && this.selectedHelpDeskType.length && this.currentPage != 'teamMembers') {
    //   this.totalFilterCount += this.selectedHelpDeskType.length;
    // }
    // if (this.defaultSelectedAccountCommunity && !this.filterObj.accountCommunities &&
    //   this.defaultSelectedAccountCommunity.length && this.currentPage != 'teamMembers') {
    //   this.totalFilterCount += this.defaultSelectedAccountCommunity.length;
    // }

    this.isFilterApplied = totalFilterCount > 0;
  }

  changeTab(tab) {
    this.changeTabDetail.emit(tab);
  }
  refreshData() {
    this.changeTab('timeCard');
  }
  documentUploaded() {
    this.changeTab('files');
  }

// REVERSING LOCAL FILTERS
  resetLocalFilters() {
      this._commonService.setLocalStorage('backupFilterObj', 'filterObj');
      this._commonService.setLocalStorage('backupfilterCondition', 'filterCondition');
  }
  // SET LOCAL FILTERS
  setLocalFilters() {
    this._commonService.setLocalStorage('filterObj', 'backupFilterObj');
    this._commonService.setLocalStorage('filterCondition', 'backupfilterCondition');
    this._appState.setGlobalFilterBackUpObjectCreated(true);
  }
  
  openModel(type) {
    this.title = type;
    if (this.title === 'Glossary') {
      this.wikiURL = 'https://wiki.serviceglobal.com/wiki/serviceo/view/WebApp/Glossary%3A%20Helpdesks%20and%20Milestones/'
    } else {
      this.wikiURL = 'https://wiki.serviceglobal.com/wiki/serviceo/view/WebApp/Delivery%20Manager%20%28MyWorkplace%29/Help%20Desk%20Manager%20Console/'
    }
    this._modalService.open(this.helpPopup, { size: 'sm modal-clone-milestone', backdrop: 'static', keyboard: false });
  }
  
  ngOnDestroy() {   
    if(this._sub){
      this._sub.unsubscribe();
    }
  }
}
