import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { ModalService } from 'shared/services/modal.service';
import { PreloaderService } from 'shared/services/preloader.service';
import { WorkflowApi, TimecardApi, JobApi } from 'shared/sdk';
import { AlertService } from 'shared/services/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AppStateService } from 'shared/services/app-state.service';
import { WorkflowService } from './../../services/workflow.service';

@Component({
  selector: 'app-workflow-buttons',
  templateUrl: './workflow-buttons.component.html',
  styleUrls: ['./workflow-buttons.component.css']
})
export class WorkflowButtonsComponent implements OnInit {


  @Input()
  set jobData(e) {
    if (e) {
      this.job = e;
      if (this.job && this.job.workflowStatusId) {
        this._appState.getViewAsVendorObject().subscribe((res: any) => {
          this.viewAsVendorId = res && res.userSfdcId ? res.userSfdcId : '';
          this.viewAsVendor = (res && res['accessType'] && res['accessType'] === 'vendor') ? true: false;
          this.fetchWorkflow();
        })        
        this.getAddTimecardPerm();
      }
      if (this.job && this.job.jobsite) {
        this.timeZone = this.job.jobsite.Time_Zone__c ? this.job.jobsite.Time_Zone__c : '';
      }
    }
  }
  @ViewChild('addWorderModal') addWorderModal: ElementRef
  @Output() updateWf: EventEmitter<any> = new EventEmitter;
  @Output() refresh: EventEmitter<any> = new EventEmitter;

  @ViewChild('addTimecardModal') addTimecardModal: ElementRef; // For TimeCard modal
  @ViewChild('updateTimecardModal') updateTimecardModal: ElementRef; // For TimeCard update modal
  @ViewChild('selectWorkerModal') selectWorkerModal: ElementRef; // For Select Worker modal used by workflow
  @ViewChild('updateAppointmentModal') updateAppointmentModal: ElementRef; // For Update Appointmet modal used by workflow
  @ViewChild('confirmBoxModal') confirmBoxModal: ElementRef; // For Update Appointmet modal used by workflow
  @ViewChild('ackInstructionModal') ackInstructionModal: ElementRef; // For Acknowledge Instruction modal used in workflow
  @ViewChild('submitDeliverableModal') submitDeliverableModal: ElementRef; // For Submit Deliverables modal used in workflow

  timeZone = '';
  wfStatus: any;
  currentWfButton: any;
  job: any;
  disabledSubmitBtn: boolean;
  modalRef: any;
  confirmationMessage: string;
  confirmationBoxTitle: string;
  confirmationBoxNoCancel = false;
  roleSub: Subscription;
  allowedAddTimecard: Boolean = false;
  userRole: any;
  lastTimecard: any;
  lastTimecardId = '';
  timecardSource = '';
  allowedAddTimecardRole = ['vms_agency', 'msp', 'vms_fse_assigned', 'vms_fte_assigned']
  viewAsVendor = false;
  viewAsVendorId = '';
  currentUser = JSON.parse(localStorage.getItem('appData'));
  // tabset: any;

  constructor(
    private _ngbModal: NgbModal,
    private _appState: AppStateService,
    private _preloaderService: PreloaderService,
    private _modalService: ModalService,
    private _wfApi: WorkflowApi,
    private _alertService: AlertService,
    private _workFlowService: WorkflowService,
    private _timecardApi: TimecardApi,
    private _jobApi :JobApi
  ) { }

  ngOnInit() {
    this.roleSub = this._appState.getJobDetailUserRole().subscribe(role => {
      if (role && role['userRole']) {
        this.userRole = role['userRole'];
        this.getAddTimecardPerm();
      }
    })

    // this._appState.getViewAsVendorObject().subscribe((res: any) => {
    //   this.viewAsVendorId = res && res.userSfdcId ? res.userSfdcId : '';
    //   console.log(res)
    //   if(res && res['accessType']) {
    //     if(res['accessType'] === 'vendor') {          
    //       // this.viewAsVendorUserType = res.userTypeSlug;
    //       this.viewAsVendor = true;
    //     }          
    //   } else {
    //     this.viewAsVendor = false;
    //   }
    // })
  }

  getAddTimecardPerm() {
    if (this.job && this.job.workflowStatusId && this.job.workflowStatusId >= 16 && (!this.job.submittedTimecard ||
      !this.job.Is_Final_Timecard_Submitted) && this.allowedAddTimecardRole.includes(this.userRole)) {
      this.allowedAddTimecard = true;

    };
  }

  setLastUpdateForJob(){
    this._jobApi.patchAttributes(this.job.id, {LastModifiedBy__c : this.currentUser.user.sfdcId}).subscribe(
      result => {
      },
      err => {
        this._alertService.error(err.message);
      }
    );
  }

  fetchTimecardData(triggerAction) {
    this._preloaderService.showPreloader();
    this._timecardApi.find({
      where: { Service_Dispatch__c: this.job.sfdcId, IsServiceODelete: null },
      fields: ['id', 'sfdcId', 'IsServiceODelete'],
      order: 'id DESC'
    }).subscribe(
      data => {
        if (data) {
          this._preloaderService.hidePreloader();
          // this.lastTimecard = data.length ? data[0] : {};
          const lastTimecard = data.length ? data[0] : {};
          this.lastTimecardId = lastTimecard && lastTimecard['sfdcId'] ? lastTimecard['sfdcId'] : '';
          this._modalService.open(this.updateTimecardModal, 'lg')
          // eval(triggerAction);
        }
      },
      err => {
        if (err) {
          this._preloaderService.hidePreloader();
          this._modalService.open(this.updateTimecardModal, 'lg')
          // eval(triggerAction);
          console.log(err);
        }
      }
    );
  }

  checkTimecardStatus() {
    if (((this.userRole && this.userRole != 'msp') || this.viewAsVendor) && this.job && this.job.Deliverable_Status__c != 'Completed') {
      this.modalRef = this._ngbModal.open(this.confirmBoxModal)
      this.confirmationBoxTitle = ' Warning!!';
      this.confirmationBoxNoCancel = true;
      this.confirmationMessage = 'Before Visit Complete, you have to Submit Deliverable';
    } else {
      const reqObj = {
        mode: 'New',
        jobId: this.job.sfdcId
      }
      this._preloaderService.showPreloader();
      this._timecardApi.getTimecardData(reqObj).subscribe(
        res => {
          if (res) {
            const newTimecardData = res.data;
            this._preloaderService.hidePreloader();
            if (newTimecardData.lastCheckOutTime && newTimecardData.jobDetail && newTimecardData.jobDetail.Is_Final_Timecard_Submitted) {
              this.updateStatus(this.currentWfButton);
            } else {
              if (newTimecardData.lastTimeCard) {
                // this.lastTimecard = newTimecardData.lastTimeCard;
                this.lastTimecardId = newTimecardData.lastTimeCard && newTimecardData.lastTimeCard.id ? newTimecardData.lastTimeCard.id : '';
              }
              this._modalService.open(this.updateTimecardModal, 'lg')
            }
          }
        },
        err => {
          this._preloaderService.hidePreloader();
          console.log(err);
        }
      )
    }
  }

  fetchWorkflow() {
    let workflowId = (this.job && this.job.appointment && this.job.appointment.Worker_Apptmnt_Customer_Approval_Status__c && this.job.appointment.Worker_Apptmnt_Customer_Approval_Status__c === 'Rejected' && this.job.workflowStatusId > 12) ? 12 : this.job.workflowStatusId;
    this._wfApi.getworkflowstatus(workflowId, 'job', this.job.id, this.viewAsVendorId).subscribe(status => {
      this.wfStatus = status;
      if (this.wfStatus['transitions']) {
        this.wfStatus['transitions'] = this.wfStatus['transitions'].filter(btn => {
          // Don't show hidden button
          if (btn) {
            let excludeStage = false;
            if (btn.isActive === false) {
              excludeStage = true;
            } else {
              if (btn.visibilityCondition) {
                const vC = JSON.parse(btn.visibilityCondition);
                for (const key in vC) {
                  if (vC.hasOwnProperty(key)) {
                    excludeStage = (vC[key] == this.job[key]) ? true : false;
                  }
                }
              }
            }
            if (!excludeStage) {
              return btn;
            }
          }
        });
      }
      if (this.wfStatus['transitionsSecond']) {
        this.wfStatus['transitionsSecond'] = this.wfStatus['transitionsSecond'].filter(btn => {
          // Don't show hidden button
          if (btn) {
            let excludeStage = false;
            if (btn.isActive === false) {
              excludeStage = true;
            } else {
              if (btn.visibilityCondition) {
                const vC = JSON.parse(btn.visibilityCondition);
                for (const key in vC) {
                  if (vC.hasOwnProperty(key)) {
                    if (vC[key] == this.job[key]) {
                      excludeStage = true;
                    }
                  }
                }
              }
            }
            if (!excludeStage) {
              return btn;
            }
          }
        });
      }
    }, err => {
      console.log(err);
    });
  }

  onHeaderButtonClick(button, modal, size) {
    this.timecardSource = '';
    if (button === 'Timecard') {
      this.timecardSource = 'timecard-button';
      this._modalService.open(modal, size);
    } else if (button === 'Upload') {
      this._modalService.open(modal, size);
    } else if (button === 'Transitions') {
      this.currentWfButton = modal;
      if (this.currentWfButton.id === 9 && (this.userRole != 'msp' || this.viewAsVendor == true) && this.job && (this.job.pre_visit_checklist != 1 || this.job.acknowledge_instruction !=1)) {
        this.modalRef = this._ngbModal.open(this.confirmBoxModal)
        this.confirmationBoxTitle = ' Warning!!';
        this.confirmationBoxNoCancel = true;
        this.confirmationMessage = 'Before Submit Appointment, you have to check Pre-Visit Checklist and Acknowledge Instructions';
      } else if (modal.triggerAction) {
        const tabset = 'manager';
        var triggerAction = this.isBase64(modal.triggerAction) ? atob(modal.triggerAction) : modal.triggerAction;

        // Fetching timecard data at submit timecard button
        if (this.currentWfButton.id === 23) {
          this.timecardSource = 'submit-timecard';
          this.fetchTimecardData(triggerAction);
        } else {
          if (this.currentWfButton.id === 20) {
            this.timecardSource = 'checkout-btn';
            this.lastTimecardId = this.job.systemTimecard__c ? this.job.systemTimecard__c : '';
          }
          eval(triggerAction);
        }
      } else {
        if (this.currentWfButton.id === 25) {
          this.checkTimecardStatus();
        } else {
          this.updateStatus(modal);
        }

      }
    }
  }

  isBase64(str) {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  }

  updateStatus(wfTransition) {
    this._preloaderService.showPreloader();
    this._wfApi.updateStatus(wfTransition.id, 'job', this.job.id).subscribe(status => {
      if (status) {
        const message = 'Workflow Status has been updated.';
        this._alertService.success(message);
        this.job.workflowStatusId = status;
        this.setLastUpdateForJob()
        this._preloaderService.hidePreloader();
        this.updateWf.emit(status);
        this._workFlowService.setWorkFlowStatusId(status);
      } else {
        this._preloaderService.hidePreloader();
      }
    }, err => {
      console.log(err);
      const errorMessage = 'Some error occured while updating the Workflow Status.';
      this._preloaderService.hidePreloader();
      this._alertService.error(errorMessage);
    });
  }

  timecardSaved(event, action) {
    if (action === 'add') {
      if (this.timecardSource = 'checkout-btn') {
        this.updateStatus(this.currentWfButton);
      } else {
        this.refresh.emit(event);
      }
    } else {
      this.updateStatus(this.currentWfButton);
    }
  }

  refreshData(event) {
    // In case of Assign worker, we need to update workflow 
    if (this.currentWfButton.workflowStatusId === 9) {
      this.updateWf.emit(11);
    } else {
      this.refresh.emit(event);
    }
  }

  wfActionClick(wfTransition) {
    this.updateStatus(wfTransition);
    this.disabledSubmitBtn = false;
    this._modalService.closed();
  }

  changeCheck(event) {
    this.disabledSubmitBtn = event.target.checked;
  }

  updateTimecard() {
    if (this.job && this.job.systemTimecard) {
      this._modalService.open('updateTimecardModal', 'xl');
    } else {
      this._alertService.success('Unable to open Auto Generated Timecard');
    }
  }
  openAddWorkerModal(event) 
  { 
    if(event == true) 
    {setTimeout(() => 
      { this._modalService.open(this.addWorderModal, 'lg'); }, 0); } }
  
  
  confirmDialogPrompt(modal, tabset) {
    const button = modal.btnTitle;
    this.modalRef = this._ngbModal.open(this.confirmBoxModal)
    this.confirmationBoxTitle = ' Warning!!';
    this.confirmationMessage = 'Are you sure, you want to ' + button + '?';
    this.modalRef.result.then((userResponse) => {
      if (userResponse) {
        this.updateStatus(modal);
      }
    });
  }

  checkForWorkerAppointMent(): boolean {
    let status = this.job && this.job.appointment && this.job.appointment.Appointment_Status__c || ''
    if (status === 'Awaiting Customer Approval') {
      this.wfStatus = {
        'titleVMS': 'Awaiting Customer Approval for Worker Appointment',
        'titleMSP': 'Awaiting Customer Approval for Worker Appointment',
      }
      return false;
    }

    return true;
  }

}
