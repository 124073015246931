import { GlobalFilterService } from 'shared/services/global-filter.service';
import { WorkflowService } from './../../../../services/workflow.service';
import { JobMessageEmailPopupComponent } from './../../../global-chat/job-message-console/job-message-email-popup/job-message-email-popup.component';
import { Component, OnInit, OnDestroy, ElementRef, ViewChild, AfterViewInit,AfterViewChecked, Output, Input, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppStateService } from 'shared/services/app-state.service';
import { LoopBackAuth } from 'shared/sdk/services/core';
import { Subscription, Subject } from 'rxjs';
import { JobApi, JobOrderItemApi, DocumentApi, TimecardApi } from 'shared/sdk';
import { AlertService } from 'shared/services/alert.service';
import { PreloaderService } from 'shared/services/preloader.service';
import { BUILD_DATE, APP_VERSION } from '../../../../../community/version';
import { filter } from 'rxjs/operators';
import { ModalService } from 'shared/services/modal.service';
import { Angulartics2 } from 'angulartics2';
import { MessageService } from 'shared/services/message.service';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'shared/services/common.service';
import { environment } from 'environments/environment';
@Component({
    selector: 'app-job-detail',
    templateUrl: './job-detail.component.html',
    styleUrls: ['./job-detail.component.css']
})

export class JobDetailComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {
    @Output() childFilters: EventEmitter<any> = new EventEmitter;
    offsetToScroll: number;
    private jobId: string;
    sub: any;
    isFteJob: Boolean = false;
    job: any;
    selectedTab = 'summary';
    isPublic = false;
    userType: string;
    isLogin: Boolean;
    roleSub: Subscription;
    userRole: any;
    documentsCount = 0;
    timeCardCount = 0;
    msgCount = 0;
    timeZone: any;
    jobTitle: any;
    allowAddFRUItem: Boolean;
    sidebarData: any;
    workflowData: any;
    workerData: any;
    reloadData = true;
    sidebarMenuOpened: Boolean = true;
    refreshChild: Subject<boolean> = new Subject<boolean>();
    refreshFiles: Subject<boolean> = new Subject<boolean>();
    sidebarstate: Subject<any> = new Subject<any>();
    modelNames: any;
    modelIds: any;
    disbaleEmailMessages = true;
    emailData = {};
    selectedJob = {};
    hideActivityFeed : any;
    viewAsVendor = false;
    viewAsVendorSfdcId = '';
    visibleMenuOptions = ['add-billing-task', 'problem-escalation', 'message'];
    @ViewChild('tabset') tabset;
    @ViewChild('userInfoModal') userInfoModal: ElementRef; // in case of modal
    wfChangeSubscription: Subscription;
    sidebarStateSub: Subscription;
    workerSfdcId = '';
    fromJobdetails = true;
    jobTab = 'summary';

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _appState: AppStateService,
        private _auth: LoopBackAuth,
        private _jobApi: JobApi,
        private _jobOrderItemApi: JobOrderItemApi,
        private _documentApi: DocumentApi,
        private _timecardApi: TimecardApi,
        private _alertService: AlertService,
        private _preloaderServer: PreloaderService,
        private _modalService: ModalService,
        private _angulartics2: Angulartics2,
        private _messageService: MessageService,
        private _dialog: MatDialog,
        private _workflowService: WorkflowService,
        private _commonService: CommonService,
        private _el: ElementRef,
        private _globalFilterService: GlobalFilterService,
        private cdRef: ChangeDetectorRef,
    ) {
       
        this.hideActivityFeed = environment.hideActivityFeed;
        this.sub = this._route.params.subscribe(params => {
            this.jobId = params['id'];            
            if (this.job && this.job.id && this.job.id !== this.jobId) {
                // this.refreshData();
            }
        });
        this._route.queryParams.subscribe(params => {
            if (params['activeId']) {
                this.selectedTab = params['activeId'];
            }
        });
        if (this._route.snapshot.data['accessType'] && this._route.snapshot.data['accessType'] === 'public') {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
                if (this.jobId) {
                    window.location.href = "serviceo://app.serviceo.me/job?sfdcid="+this.jobId;
                   
                }
            }
            this.isPublic = true;
            this.visibleMenuOptions = ['job-detail'];
        }
        // time card count on Add time card
        this._commonService.timeCardAdd.subscribe((res) => {
            if (res) {
                this.getTCardCount();
            }
        })
    }

    ngOnInit() {
        this._commonService.getTimecardRecordTypeIds();
        this.getTCardCount();
        this.userType = this._appState.getAccessType();
        if (this._auth.getCurrentUserData() && this._auth.getCurrentUserData()['username']) {
            this.isLogin = true
        } else {
            this.isLogin = false;
        }
        this.roleSub = this._appState.getJobDetailUserRole().subscribe(role => {
            if (role && role['userRole']) {
                this.userRole = role['userRole'];
            }
        })
        this._appState.getViewAsVendorObject().subscribe((res: any) => {
            if(res) {
                this.viewAsVendor = (res['accessType'] && res['accessType'] === 'vendor') ? true : false;
                this.viewAsVendorSfdcId = res['userSfdcId'];
                if (this.jobId) {
                    this.loadData(this.jobId);
                }         
            } else {
              this.viewAsVendor = false;
              if (this.jobId) {
                this.loadData(this.jobId);
                }
                //   this.vendorAccessTypes = null;
            }
          })
        
        
        this.modelNames = 'Job';
        this.modelIds = [];
        this.wfChangeSubscription = this._workflowService.getWorkFlowStatusId().subscribe((statusId: any) => {
            if (statusId) {
                if (this.isPublic) {
                    this.visibleMenuOptions = ['job-detail'];
                } else {
                    this.visibleMenuOptions = ['add-billing-task', 'problem-escalation', 'upload-file', 'message'];
                    if(statusId == 16 || statusId == 17 || statusId == 34) {
                        this.visibleMenuOptions.push('add-timecard');
                    }
                }
                this.refreshData();
            }
        });

        this.sidebarStateSub = this._globalFilterService.getSidebarState().subscribe(isOpened => {
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 0);
        })

        
    }

    ngAfterViewInit() {
        let fileId = setInterval(() => {
            if (this.tabset && this.tabset.activeId && this._el.nativeElement.querySelector('#' + this.tabset.activeId)) {
                clearInterval(fileId);
                this.offsetToScroll = this._el.nativeElement.querySelector('#' + this.tabset.activeId).offsetLeft;
                let el = this._el.nativeElement.querySelector('.nav-tabs');
                el.scrollLeft = this.offsetToScroll;
            }
        }, 1000);
        
    }
    ngAfterViewChecked(): void {
        if(this.tabset && this.selectedTab==='timesheet'){
            this.tabset.select(this.selectedTab);
        }
    } 

    ngOnDestroy() {
        if (this.roleSub) {
            this.roleSub.unsubscribe();
        }
        if (this.wfChangeSubscription) {
            this.wfChangeSubscription.unsubscribe();
        }
        if (this.sidebarStateSub) {
            this.sidebarStateSub.unsubscribe();
        }
    }


    // Refresh workflow
    refreshData() {
        this.reloadData = false;
        this.job = {};
        this.loadData(this.jobId);
        this.refreshChild.next(true);

    }

    msgTab(){
        this.jobTab = 'msgTab';
    }


    loadData(jobId) {
        this.documentsCount = 0;
        this.timeCardCount = 0;
        // this.wfButtons = this.vendorActionBtn = [];
        let reqObj = {
            'fields': [
                'PMC_Case_Number',
                'Max_Hours_Units__c',
                'csum__c',
                'Custom_work_order_instructions__c',
                'Jobsite_Name__c',
                'Partner_Case_Number__c',
                'Customer_Contact_Email__c',
                'Customer_Contact_Phone__c',
                'Case_Number__c',
                'Partner_Case_Error_Description__c',
                'Iron_Case__c',
                'SOW_Work_Order__c',
                'Job_Instruction_Details_Long__c',
                'Jobsite_Contact_TEsc_Name_phone_email__c',
                'Customer_Appointment_Setup_Required__c',
                'Appointment_Schedule_Status_Customer__c',
                'Appointment_Schedule_Status_Customer_vms__c',
                'Phone_Scheduling_1st_Attempt_Unreachable__c',
                'Phone_Scheduling_2nd_Attempt_Unreachable__c',
                'Phone_Scheduling_3rd_Attempt_Unreachable__c',
                'Appointment_Call_Notes__c',
                'Customer_Appointment_DateTime_Scheduled__c',
                'Customer_Appointment_Start_Scheduled__c',
                'Worker_Arrival_DateTime_Scheduled__c',
                'Worker_End_DateTime_Scheduled__c',
                'Special_Instruction_from_PMS_Case_Auto__c',
                'CKSW_BASE__Incomplete_reason__c',
                'CKSW_BASE__Other_Incomplete_Reason__c',
                'Worker_Departure_Date_Time_Actual__c',
                'Worker_Arrival_Date_Time_Actual__c',
                'Worker_InProgress_Start_DateTime_Actual__c',
                'Worker_Finish_Date_Time_Actual__c',
                'Deliverables__c',
                'Does_this_Job_require_Service_Parts__c',
                'Service_Parts_Local_Pickup_Required__c',
                'Does_this_Job_require_New_Equip_Hardware__c',
                'Equipment_Local_Pickup_Required__c',
                'Equipment_Delivery_Date_ETA__c',
                'Equipment_Tracking__c',
                'Equipment_Delivery_Time_ETA_Hours__c',
                'Equipment_Delivery_Time_ETA_Minutes__c',
                'Asset_Model__c',
                'Asset_Name__c',
                'Asset_Serial__c',
                'Row__c',
                'Suite__c',
                'Rack_Sub_Position_Slot__c',
                'Rack_Sub_Position__c',
                'Rack_Side__c',
                'Rack_Serial__c',
                'Rack_Kit_Top_of_Switch__c',
                'Rack__c',
                'SKU__c',
                'Partner_FRU_Sku__c',
                'sfdcId',
                'id',
                'Iron_Job_num__c',
                'Name',
                'Vendorsite__c',
                'workflowId',
                'workflowStatusId',
                'Work_Order__c',
                'CKSW_BASE__Account__c',
                'RecordTypeId',
                'isCancelled',
                'Dispatch_Service_Resolution_Status__c',
                'Vendor__c',
                'Customer_Service_Type_From_Program__c',
                'Technical_Level__c',
                'Service_Dispatch_SLA_Priority_FrmProgram__c',
                'acknowledge_instruction',
                'pre_visit_checklist',
                'Deliverable_Status__c',
                'submittedTimecard',
                'FileCounter',
                'Dispatch_Worker_Name__c',
                'Dispatch_Worker_Name_Text__c',
                'Dispatch_Worker_Phone__c',
                'Case__c',
                'CreatedDate',
                'CKSW_BASE__City__c',
                'CKSW_BASE__Description__c',
                'Project_SOP__c',
                'CKSW_BASE__State__c',
                'Jobsite__c',
                'ICC_Case__c',
                'Service_Dispatch_SLA_Priority__c',
                'Job_Status_Internal__c',
                'systemTimecard',
                'systemTimecard__c',
                'statusTracker',
                'Jobsite_Contact_Technical_Escalation__c',
                'Jobsite_Contact_SDesk_Name_phone_email__c',
                'Project__c',
                'Work_Order_Number', 'Final_Timecard_Id', 'Final_Timecard_Submitted__c', 'Is_Final_Timecard_Submitted',
                'PMS_Case_Number', 'Sales_Order__c','CKSW_BASE__Zip__c','CKSW_BASE__Country__c','CKSW_BASE__Street__c','Type_of_Work__c'
            ],
            'where': { 'sfdcId': jobId },
            'include': [
                {
                    'relation': 'vendor',
                    'scope': {
                        'fields': ['sfdcId', 'Name', 'Service_Global_Ref__c', 'zeitzone__BillingLTFactor__c', 'ShippingStreet',
                            'ShippingCity', 'ShippingState', 'ShippingCountry', 'ShippingPostalCode', 'Shipping_country_Code__c',
                            'Standard_Supported_FSL__c']
                    }
                },
                {

                    'relation': 'worker',
                    'scope': {
                        'fields': ['Contact__c',
                            'RecordTypeId',
                            'Vendorsite__c',
                            'id',
                            'sfdcId',
                            'Name',
                            'Country_Code__c',
                            'Email__c',
                            'Dispatch_Worker_num__c',
                            'Work_Phone_Number__c',
                            'Primary_Worker_Skilling_Profile__c',
                            'Worker_Type__c',
                            'Worker_Rating__c'],
                        'include': [{
                            'relation': 'skilling',
                            'scope': {
                                'fields': ['sfdcId', 'Name', 'Status__c', 'Primary_Talent_Type__c'],
                                'include': {
                                    'relation': 'talentType',
                                    'scope': {
                                        'fields': ['sfdcId', 'Name']
                                    }
                                }
                            }
                        },
                        {
                            'relation': 'user',
                            'scope': {
                                'fields': ['id', 'sfdcId', 'email', 'firstname', 'gender', 'lastname', 'profileImage', 'url', 'accessType']
                            }
                        },
                        {
                            'relation': 'contact',
                            'scope': {
                                'fields': ['id', 'sfdcId', 'Title', 'MailingCountry', 'Phone', 'MailingCity', 'MailingState',
                                'MailingPostalCode',	'MailingStreet', 'Geo_Location__Longitude__s', 'Geo_Location__Latitude__s']
                            }
                        }
                        ]
                    }
                },
                {
                    'relation': 'partner',
                    'scope': {
                        'fields': ['sfdcId', 'Name']
                    }
                },
                {
                    'relation': 'case',
                    'scope': {
                        'fields': [
                            'Partner_Case_Number__c',
                            'Talent_Type_ref__c',
                            'Coverage_Hours__c',
                            'id',
                            'sfdcId',
                            'Job_Order__c',
                            'ParentId',
                            'PPE_Hours__c',
                            'Description'
                        ],
                        'include': [
                            {
                                'relation': 'workOrder',
                                'scope': {
                                    'fields': ['sfdcId', 'Name', 'Customer_Service_Type_WO__c']
                                }
                            },
                            {
                                'relation': 'case',
                                'scope': {
                                    'fields': ['sfdcId', 'CaseNumber','PPE_Hours__c']
                                }
                            },
                            {
                                'relation': 'talentType',
                                'scope': { 'fields': ['Talent_Type_Name__c'] }
                            },
                            {
                                'relation': 'comment',
                                'scope': {
                                    'fields': ['id'],
                                },
                            }
                        ],

                    }
                },
                {
                    'relation': 'jobsite',
                    'scope': {
                        'fields': [
                            'Name',
                            'geolocation__Latitude__s',
                            'geolocation__Longitude__s',
                            'City__c',
                            'Country__c',
                            'Street__c',
                            'Zip__c',
                            'sfdcId',
                            'Time_Zone__c',
                            'Abbreviation__c','Region__c','GEO_Metro__c'
                        ],
                        'include': [
                            {
                                'relation' : 'GeoMetro',
                                'scope': {
                                    'fields': ['sfdcId','Region__c'],
                                    'include': [
                                    {
                                        'relation':'serviceTerritory',
                                        'fields': ['sfdcId','GEO_Metro__c', 'Name', 'ParentTerritoryId', 'Service_Territory_Code__c'],
                                    }
                                    ]
                                
                                }
                            }
                        ]
                    }
                },
                {
                    'relation': 'vendorsite',
                    'scope': {
                        'fields': ['sfdcId', 'Name', 'geolocation__Latitude__s', 'geolocation__Longitude__s', 'Street__c', 'City__c',
                            'State__c', 'Zip_Postal_Code__c', 'Country__c']
                    }
                },
                {
                    'relation': 'appointment',
                    'scope': {
                        'fields': [
                            'Customer_Appointment_Start_HRS_Scheduled__c',
                            'Customer_Apptmnt_Start_Minutes_Scheduled__c',
                            'id',
                            'Customer_Appointment_Setup_Required__c',
                            'Worker_Arrival_Date_Customer_Req_End__c',
                            'Customer_Appointment_Start_Scheduled__c',
                            'Worker_Departure_Date_Time_Actual__c',
                            'Worker_End_DateTime_Scheduled__c',
                            'Worker_InProgress_Start_DateTime_Actual__c',
                            'Worker_Finish_Date_Time_Actual__c',
                            'Worker_Arrival_Date_Time_Actual__c',
                            'Earliest_Start_Date_Permitted__c',
                            'Early_Start__c',
                            'sfdcId',
                            'Worker_Arrival_DateTime_Cust_Requested__c',
                            'Customer_Appointment_DateTime_Scheduled__c',
                            'Worker_Arrival_DateTime_Scheduled__c',
                            'Reason_for_different_from_customer_reqst__c',
                            'Same_as_Customer_Requested__c',
                            'Appointment_Status__c',
                            'Reason_for_different_from_customer_reqst__c',
                            'Same_as_Customer_Requested__c', 
                            'Worker_Apptmnt_Customer_Approval_Status__c'
                        ]
                    }
                },
                {
                    'relation': 'program',
                    'scope': { 'fields': ['Name', 'Program_Requires_Registered_Workers__c'] },
                },
                {
                    'relation': 'purchaseOrder',
                    'scope': {
                        'fields': ['sfdcId', 'Service_Dispatch__c', 'Work_Order_num__c', 'WorkOrderNumber', 'Status']
                    }
                },
                {
                    'relation': 'deliverable',
                    'scope': {
                        'fields': ['Name', 'Deliverable_Type__c', 'File_upload_required__c', 'Description__c',
                            'Instructions__c']
                    }
                },
                {
                    'relation': 'product',
                    'scope': { 'fields': ['sfdcId', 'Name', 'Talent_Type__c', 'Talent_Type_Name__c'] }
                },
                {
                    'relation': 'project',
                    'scope': {
                        'fields': ['sfdcId', 'Project__c', 'Name', 'Project_Profile__c', 'Project_Routing_Profile__c']
                    }
                },
                {
                    'relation': 'pmcCase',
                    'scope': {
                        'fields': ['Case__c'],
                        'include': [{
                            'relation': 'comment',
                            'scope': {
                                'fields': ['id']
                            }
                        }]
                    }
                },
                {
                    'relation': 'order',
                    'scope': { 'fields': ['Order_Status__c', 'OrderNumber', 'sfdcId'] },
                },
                
            ]
        };
        if(this.viewAsVendor && this.viewAsVendorSfdcId){
            reqObj['viewAsVendor'] = true;
            reqObj['viewAsVendorSfdcId'] = this.viewAsVendorSfdcId;
        }
        this._preloaderServer.showPreloader();
        this._jobApi.getJobDetailsById(reqObj).subscribe(
            result => {
                this.workerSfdcId = result && result.worker && result.worker.sfdcId
                if (result && result.id) {
                    result['fromJobDetailPage'] = true;
                    result['caseIds'] = [result.Case__c, result.PMC_Case_Number];
                    this.isFteJob = result.RecordTypeId && result.RecordTypeId === '0121a0000006QniAAE' ? true : false;
                    this.job = result;
                    const msgObject = {};
                    msgObject['modelName'] = 'Job';
                    msgObject['modelId'] = result.id.toString();
                    msgObject['Case__c'] = result.case && result.case.sfdcId;
                    msgObject['count'] = (result.case && result.case.comment && result.case.comment.length) +
                        (result.pmcCase && result.pmcCase.comment && result.pmcCase.comment.length);
                    msgObject['job'] = {
                        Case__c: result.case && result.case.sfdcId,
                        Iron_Case__c: result.Iron_Case__c,
                        Iron_Job_num__c: result.Iron_Job_num__c,
                        PMC_Case_Number: result.PMC_Case_Number,
                        PMS_Case_Number: result.PMS_Case_Number,
                        Sales_Order__c: result.Sales_Order__c,
                        order: result.order,
                        purchaseOrder: result.purchaseOrder,
                        Work_Order__c: result.Work_Order__c,
                        Work_Order_Number: result.Work_Order_Number,
                        id: result.id,
                        sfdcId: result.sfdcId
                    };
                    msgObject['fromSM'] = true;
                    this.selectedJob = msgObject;
                    let jobId = { 'Job': [this.job && this.job.id] };
                    this.modelIds.push(jobId);
                    this.sidebarData = { modelId: result.id, modelName: 'Job' };
                    Object.assign(this.sidebarData, this.job);

                    // Settign data for workflow progress bar
                    this.workflowData = {
                        modelId: result.id,
                        modelName: 'job',
                        workflowId: result.workflowId,
                        workflowStatusId: result.workflowStatusId,
                        isCancelled: result.isCancelled
                    };
                    if (this.job.worker && this.job.worker.id) {
                        this.workerData = this.job.worker;
                    } else {
                        this.workerData = this.job.Dispatch_Worker_Name__c && this.job.Dispatch_Worker_Name__c !== null ?
                            { filter: { sfdcId: this.job.Dispatch_Worker_Name__c } } : {}
                    }

                    if (result.jobsite && result.jobsite.Time_Zone__c) {
                        this.timeZone = result.jobsite.Time_Zone__c;
                    }

                    this.jobTitle = this.job.Iron_Job_num__c + ' | ' + this.job.Dispatch_Service_Resolution_Status__c + ' | ' +
                        this.job.Customer_Service_Type_From_Program__c + ' | ' + this.job.Technical_Level__c + ' | ' +
                        this.job.Service_Dispatch_SLA_Priority_FrmProgram__c + ' | ';
                    this.jobTitle += (this.job.partner && this.job.partner.Name) ? this.job.partner.Name + ' | ' : ' | ';
                    this.jobTitle += (this.job.vendor && this.job.vendor.Name) ? this.job.vendor.Name : '';

                    // Check if user is allowed to add new FRU Item
                    if (this.job.case && this.job.case.workOrder &&
                        this.job.case.workOrder.Customer_Service_Type_WO__c === '3PL-RMA-FRUORDER (Dispatch)') {
                        this.allowAddFRUItem = true;
                    }

                    // set user role for job detail page
                    if (!this.userRole && this.job['userRole']) {
                        this.userRole = this.job['userRole'];
                        this._appState.setJobDetailUserRole({ 'userRole': this.job['userRole'] });
                    }

                    // Setting up right sidebar menus
                    if (result.Job_Status_Internal__c !== 'Decline' && !this.isPublic) {
                        this.visibleMenuOptions.push('upload-file');
                    }

                    if (!this.isFteJob && result.workflowStatusId > 15 && !this.isPublic && (!result.Is_Final_Timecard_Submitted ||
                        !result.submittedTimecard) && (this.userRole === 'vms_fte_assigned' || this.userRole === 'vms_fse_assigned' ||
                            this.userRole === 'vms_agency' || this.userRole === 'msp')) {
                        this.visibleMenuOptions.push('add-timecard')
                    }
                    if (this.isPublic && !this.isLogin) {
                        this.openUserInfoModal();
                    } else if (this.isPublic && this.isLogin) {
                        this._angulartics2.eventTrack.next({
                            action: 'Public-Url-Job',
                            properties: {
                                JobId: this.job.Iron_Job_num__c,
                                username: this._auth.getCurrentUserData()['username'],
                                isLoggedin: true
                            },
                        });
                        this._router.navigateByUrl('/vms/jobs/view/' + jobId, {})
                    }

                    this.getDocumentsCount();
                    this.getTCardCount();


                    // sending a flag fromJobDetailPage so that message post component knows that this message should be posted on case 
                    // until checkbox for vms selected
                    const details = {
                        fromJobDetailPage: true,
                        id: result && result.case && result.case.id
                    };
                    this._messageService.setchatRoomId(details);
                }
                this.reloadData = true;


                this._preloaderServer.hidePreloader();
            }, err => {
                console.log(err.message)
                this._preloaderServer.hidePreloader();
                this._alertService.error(err.message, true);
                this._router.navigate(['/vms/jobs/', { 'error': err.message }]);
            }
        );
    }

    getDocumentsCount() {
        this._jobOrderItemApi.find({
            where: { 'Job__c': this.jobId },
            fields: ['id', 'sfdcId']
        }).subscribe(
            jobOrderItem => {
                const filterObj: any = [{
                    modelName: 'Job',
                    modelId: this.job.id,
                    isDeleted: 0
                }];
                // Add filter only if job order item is present.
                if (jobOrderItem.length > 0) {
                    filterObj.push({
                        modelName: 'JobOrderItem',
                        modelId: jobOrderItem[0]['id'],
                        isDeleted: 0
                    });
                }
                this._documentApi.count({
                    or: filterObj
                }).subscribe(
                    results => {
                        this.documentsCount = results.count;
                    },
                    err => {
                        console.log(err.message);
                    }
                );
            },
            err => {
                console.log(err.message);
            }
        )
    }

    isRightMenuOpened(isOpened) {
        this.sidebarMenuOpened = isOpened;
        this._globalFilterService.setSidebarState(isOpened);
        this._commonService.setRightSidebarStateState(isOpened);
        
    }

    setMessageCount(count) {
        this.msgCount = count;
        if (this.msgCount > 0) {
            this.disbaleEmailMessages = true;
        } else {
            this.disbaleEmailMessages = false;
        }
    }
    changeTab(tab) {
        if (tab === 'refesh-job-detail') {
            this.refreshData();
        } else {
            if (tab === 'files') {
                this.getDocumentsCount();
                if (this.selectedTab === tab) {
                    this.refreshFiles.next(true);
                }
            }
        if(tab === 'messages'){
            this.selectedTab = 'messages';
            this.jobTab = 'msgTab';
        }
            // this.tabset.select(tab);
        }

        this.selectedTab = tab;
        window.scroll(0, 0);
    }
    openUserInfoModal() {
        setTimeout(() => {
            this._modalService.open(this.userInfoModal, 'sm');
        }, 0);
    }

    getTCardCount() {
        const obj = {
            where: {
                and: [
                    { Service_Dispatch__c: this.jobId },
                    { or: [{ 'IsServiceODelete': 0 }, { 'IsServiceODelete': null }] }
                ]
            },
            jobId: this.jobId,
            'order': 'CreatedDate DESC',
            'count': true
        }
        this._timecardApi.getTimeCardList(obj).subscribe(result => {
            if (result.count > 0) {
                this.timeCardCount = result.count
            } else {
                this.timeCardCount = 0
            }
        }, err => {
        })
    }

    removeTimeCard(event) {
        if (event) {
            this.getTCardCount();
            this._commonService.setTimeCardAddSubject(true);
        }
    }

    login() {
        this._router.navigate(['/login'], { queryParams: { returnUrl: '/vms/jobs/view/' + this.jobId } });
    }
    tabChange($event: NgbTabChangeEvent) {
        if ($event.nextId === 'files') {
            this.getDocumentsCount();
        }
        this.selectedTab = $event.nextId;
    }
    createCopy() {
        return (JSON.stringify(this.job));
    }

    refreshTabs(){
        window.location.reload();
    }  
      
    openEmailPopup() {
        if (this.msgCount <= 0) {
            return;
        }
        const dialogRef = this._dialog.open(JobMessageEmailPopupComponent, {
            width: '60%',
            data: {},
            panelClass: 'job-message-email-popup-dialog-container',
            disableClose: true
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.length > 0) {
                let type = 'Message @ Job: ' + this.job.Iron_Job_num__c;
                this.emailData = {
                    recipents: result,
                    subject: type + ' (' + this.msgCount + ')'
                };
            }
        });
    }
}
