import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TalentTypeApi } from 'shared/sdk';
import { debounceTime, switchMap, filter } from 'rxjs/operators';

@Component({
  selector: 'app-service-technical-talent-type-lookup',
  templateUrl: './service-technical-talent-type-lookup.component.html',
  styleUrls: ['./service-technical-talent-type-lookup.component.scss']
})
export class ServiceTechnicalTalentTypeLookupComponent implements OnInit {

  @Input() set resetData(isClear) {
    if (isClear) {
      this.talentTypeSelected = [];
    }
  }
  talentTypeahead: EventEmitter<any> = new EventEmitter();
  noTalentType: string;
  talentTypeOptionList: any[] = [];
  talentTypeSelected = [];
  @Input() placeholder = 'Talent Type';
  @Input() isdisabled = false;
  @Input() isMultipleSelected = true;
  @Output() getTalentTypeObj: EventEmitter<any> = new EventEmitter;
  @Input() bindValue= 'Talent_Type_Name__c'
 
  /**
   * @constructor
   * Represents a TalentTypeLookupComponent.
   */
  constructor(
    private _talentTypeApi: TalentTypeApi
  ) {
  }

  ngOnInit() {
    if ( !this.talentTypeSelected || !this.talentTypeSelected.length) {
      const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
      this.talentTypeSelected = (preselected && preselected['servicetalentTypes']) ? preselected['servicetalentTypes'] : [];
    }
    this.getTalentTypes();
  }

  getTalentTypes() {
    this.talentTypeahead
      .pipe(
        filter(search => search && search.length > 2),
        debounceTime(200),
        switchMap(search => {
          const searchPattern = { ilike: '%' + search.trim() + '%', options: 'i' };
          return this.findTalentTypeApi(searchPattern);
        })
      ).subscribe(
        data => {
          if (data && data.length) {
            this.talentTypeOptionList = data;
          } else {
            this.talentTypeOptionList = [];
            this.noTalentType = 'No service engineer talent type found.';
          }
        },
        err => {
          console.log('Error fetching service engineer talent types', err.message);
        }
      );
  }

  findTalentTypeApi(searchText) {
    const whereObj = { Talent_Type_Name__c: searchText };
    return this._talentTypeApi.find({
      where: whereObj,
      fields: ['id', 'sfdcId', 'Name', 'Talent_Type_Name__c']
    })
  }

  onTalentTypeChange() {
    this.getTalentTypeObj.emit(this.talentTypeSelected);
  }

  clearTalentType() {
    this.talentTypeSelected = [];
    this.getTalentTypeObj.emit([]);
  }

}
