import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, filter, tap, catchError } from 'rxjs/operators';
import { VendorsiteApi } from '../../../sdk';
import { AppStateService } from 'shared/services/app-state.service';


@Component({
  selector: 'app-vendorsite-loopup',
  templateUrl: './vendorsite-loopup.component.html',
  styleUrls: ['./vendorsite-loopup.component.scss']
})
export class VendorsiteLoopupComponent implements OnInit {
  userType: any;
  @Input() set resetData(isClear) {
    this.vendorsiteLoading = false;
    this.vendorsiteSelected = [];
  }
  @Input() isMultiple = true;
  @Output() getVendorsiteObj: EventEmitter<any> = new EventEmitter;
  @Input()
  set setVendorsiteObj(vendorAccounts) {
    this.vendorsiteLoading = false;
    this.vendorsiteLists = [];
    this.vendorsiteSelected = [];
    this.userType = this._appState.getAccessType();
    if (this.userType === 'internal') {
      if (vendorAccounts && vendorAccounts.length) {
        this._vendorAccounts = vendorAccounts;
        this.loadVendorsite({});
      } else {
        this._vendorAccounts = [];
        this.clearVendorsite() // in case of clear 
        this.getVendorsites();
      }
    } else if (this.userType === 'vendor') {
      const accountID = this._appState.getSelectedAccount();
      this._vendorAccounts = [accountID];
      this.loadVendorsite({});
    }
  }

  @Input() preSelected;

  @Input()
  set preselectedVendorsite(e) {
    if (e) {
      const whereObj = {
        sfdcId: e
      }
      this.findVendorsite(whereObj);
    }
  }

  @Input() inGlobalFilter = true;

  public searchInput = new EventEmitter<string>();
  notFoundText: string;
  orderBy = 'Name';

  _vendorAccounts = [];
  vendorsiteLoading = false;
  vendorsiteLists = [];
  vendorsiteSelected = [];

  constructor(
    private _cd: ChangeDetectorRef,
    private _vendorsiteApi: VendorsiteApi,
    private _appState: AppStateService
  ) { }

  ngOnInit() {
    this.notFoundText = 'No Vendorsite found';
    this.getVendorsites();
    // to show selected vendorsite
    if (this.inGlobalFilter) {
      const preselected = localStorage.getItem('filterObj') ? JSON.parse(localStorage.getItem('filterObj')) : '';
      this.vendorsiteSelected = (preselected && preselected['vendorSites']) ? preselected['vendorSites'] : '';
      if (this.vendorsiteSelected && this.vendorsiteSelected.length) {
        const whereObj = {
          sfdcId: { inq: this.vendorsiteSelected }
        }
        this.loadVendorsite(whereObj)
      }
    } else {
      if (this.preSelected) {
        const whereObj = {
          sfdcId: { inq: this.preSelected }
        }
        this.loadVendorsite(whereObj)
      }
    }
  }

  getVendorsites() {
    this.searchInput
      .pipe(
        filter(search => search && search.length > 2),
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(search => this.getVendorsiteList(search))
      ).subscribe(
        x => {
          this.vendorsiteLists = x;
          this.notFoundText = (this.vendorsiteLists.length === 0) ? 'No Vendorsite found' : '';
        },
        err => {
          console.log(err);
          this.vendorsiteLists = [];
        }

      );
  }
  getVendorsiteList(search) {
    const filters = {
      where: { Name: { ilike: '%' + search.trim() + '%', options: 'i' } },
      fields: ['Name', 'id', 'sfdcId', 'City__c', 'Country__c', 'Zip_Postal_Code__c', 'Vendorsite_ID__c', 'Street__c'],
      order: 'Name'
    }
    if (this._vendorAccounts && this._vendorAccounts.length) {
      Object.assign(filters.where, { Vendor__c: { inq: this._vendorAccounts } });
    }
    return this._vendorsiteApi.find(filters);
  }


  loadVendorsite(whereObj) {
    this.vendorsiteLoading = true;
    if (this._vendorAccounts && this._vendorAccounts.length) {
      Object.assign(whereObj, { Vendor__c: { inq: this._vendorAccounts } });
    }
    const filterObj = {
      fields: ['Name', 'id', 'sfdcId', 'City__c', 'Country__c', 'Zip_Postal_Code__c', 'Vendorsite_ID__c', 'Street__c'],
      where: whereObj,
      order: 'Name'
    }
    this.vendorsiteSelected = (this.vendorsiteSelected && this.vendorsiteSelected.length) ? this.vendorsiteSelected : [];
    this.getVendorsiteObj.emit(this.vendorsiteSelected);
    this.vendorsiteLists = [];
    this._vendorsiteApi.find(filterObj).subscribe(
      data => {
        if (data) {
          this.vendorsiteLists = data;
        }
        this.vendorsiteLoading = false;
      },
      err => {
        console.log(err);
        this.vendorsiteLists = [];
        this.vendorsiteLoading = false;
      }
    );
  }

  findVendorsite(whereObj) {
    const filterObj = {
      fields: ['Name', 'id', 'sfdcId', 'City__c', 'Country__c', 'Zip_Postal_Code__c', 'Vendorsite_ID__c', 'Street__c'],
      where: whereObj,
      order: 'Name'
    }
    this._vendorsiteApi.find(filterObj).subscribe(
        x => {
            this._cd.markForCheck();
            this.vendorsiteLists = x;
            if (this.vendorsiteLists.length && !this.isMultiple) {
              this.vendorsiteSelected = this.vendorsiteLists[0]['sfdcId'];
            }
            this.notFoundText = (this.vendorsiteLists.length === 0) ? 'No vendorsite found.' : ''
        },
        err => {
            console.log(err);
            this.vendorsiteLists = [];
            this.notFoundText = '';
        }
    );
}

  clearVendorsite() {
    this.notFoundText = 'Type to search Vendorsite';
    this.vendorsiteSelected = [];
    this.getVendorsiteObj.emit([]);
  }

  onVendorsiteChange() {
    this.getVendorsiteObj.emit(this.vendorsiteSelected);
  }


}
